import React, { Component } from 'react';
import axios from 'axios';

//Custom Components
import { SelectInput } from './SelectInput';

//Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

//moment
import "moment/locale/tr";
import moment from 'moment';

//React-Notification
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { LabeledData } from './LabeledData';

export class Automission extends Component {
    static displayName = Automission.name;

    constructor(props) {
        super(props);
        this.state = {
            automissionInfo: {},
            rejectReasons: {},
            rejectIconClasses: "d-none",
            acceptIconClasses: "d-none",
            rejectSelectionClasses: "mt-1 d-none",
            automissionCancellationReasons: [],
            processing: false
        };
        this.getAutomission = this.getAutomission.bind(this);
        this.handleRejectButton = this.handleRejectButton.bind(this);
        this.getAutomissionCancellationReasons = this.getAutomissionCancellationReasons.bind(this);
        this.rejectAutomission = this.rejectAutomission.bind(this);
        this.acceptAutomission = this.acceptAutomission.bind(this);
    }

    handleRejectButton() {
        this.setState({ rejectSelectionClasses: "mt-1" });
        NotificationManager.info("Red Sebebi Seçiniz");
    }

    async getAutomission() {
        var request =
        {
            ServiceID: parseInt(this.props.match.params.serviceid),
            AutomissionCode: this.props.match.params.automissioncode
        }
        axios.post("api/service/getautomissioninfobyrequest/", request)
            .then(res => {
                if (res.data?.isSuccess) {
                    this.setState({ automissionInfo: res.data.data });
                    if (res.data.data.automissionStatus?.id !== 1) {
                        this.setState({ processing: true });
                    }
                } else {
                    NotificationManager.warning("Otomatik İş Bulunamadı");
                }
            });
    }
    async getAutomissionCancellationReasons() {
        axios.get("api/parameter/getautomissioncancellationreasons")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.automissionCancellationReasons.push({ value: value.id, label: value.descriptionTR });
                }
            });
    }
    async rejectAutomission(selectedOption) {
        this.setState({
            processing: true,
            rejectIconClasses: "fa-spin ml-2"
        });
        var request =
        {
            ServiceID: parseInt(this.props.match.params.serviceid),
            AutomissionCode: this.props.match.params.automissioncode,
            IsAccepted: false,
            RejectReasonID: selectedOption.value
        }
        axios.post("api/service/setautomissionresult/", request)
            .then(res => {
                if (res.data?.isSuccess) {
                    this.setState({rejectIconClasses: "d-none"}, () => {
                        NotificationManager.success("İş Reddedildi");
                        this.getAutomission();
                    });
                } else {
                    NotificationManager.warning("Hatalı İşlem");
                    this.setState({
                        processing: false,
                        rejectIconClasses: "d-none"
                    });
                }
            });
    }
    async acceptAutomission(selectedOption) {
        this.setState({
            processing: true,
            acceptIconClasses: "fa-spin ml-2"
        });
        var request =
        {
            ServiceID: parseInt(this.props.match.params.serviceid),
            AutomissionCode: this.props.match.params.automissioncode,
            IsAccepted: true
        }
        axios.post("api/service/setautomissionresult/", request)
            .then(res => {
                if (res.data?.isSuccess) {
                    this.setState({ acceptIconClasses: "d-none"}, () => {
                        NotificationManager.success("İş Kabul Edildi");
                        this.getAutomission();
                    });
                } else {
                    NotificationManager.warning("Hatalı İşlem");
                    this.setState({
                        processing: false,
                        acceptIconClasses: "d-none"
                    });
                }
            });
    }

    componentDidMount() {
        this.getAutomission();
        this.getAutomissionCancellationReasons();
    }

    render() {
        return (
            <>
                <Card className="mt-3">
                    <Card.Header>
                        <h5>Otomatik İş Bilgileri</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <Table striped bordered hover size="sm">
                                    <tbody>
                                        <tr>
                                            <th>Dosya Numarası</th>
                                            <td>{this.state.automissionInfo.caseNo}</td>
                                        </tr>
                                        <tr>
                                            <th>Plaka</th>
                                            <td>{this.state.automissionInfo.plateNumber}</td>
                                        </tr>
                                        <tr>
                                            <th>Marka</th>
                                            <td>{this.state.automissionInfo.brand}</td>
                                        </tr>
                                        <tr>
                                            <th>Model</th>
                                            <td>{this.state.automissionInfo.model}</td>
                                        </tr>
                                        <tr>
                                            <th>Hizmet</th>
                                            <td>{this.state.automissionInfo.serviceType}</td>
                                        </tr>
                                        <tr>
                                            <th>Hizmet Oluşturulma Zamanı</th>
                                            <td>{moment(this.state.automissionInfo.serviceCreationDate).format("DD.MM.YYYY HH:mm")}</td>
                                        </tr>
                                        <tr>
                                            <th>Son Onay Zamanı</th>
                                            <td>{this.state.automissionInfo.lastApprovalDate ? moment(this.state.automissionInfo.lastApprovalDate).format("DD.MM.YYYY HH:mm") : ""}</td>
                                        </tr>
                                        <tr>
                                            <th>Varılması Gereken Zaman</th>
                                            <td>{moment(this.state.automissionInfo.expectedArrivalDate).format("DD.MM.YYYY HH:mm")}</td>
                                        </tr>
                                        <tr>
                                            <th>Açıklama</th>
                                            <td>{this.state.automissionInfo.note}</td>
                                        </tr>
                                        <tr>
                                            <th>İl</th>
                                            <td>{this.state.automissionInfo.town}</td>
                                        </tr>
                                        <tr>
                                            <th>İlçe</th>
                                            <td>{this.state.automissionInfo.townArea}</td>
                                        </tr>
                                        <tr>
                                            <th>Semt</th>
                                            <td>{this.state.automissionInfo.district}</td>
                                        </tr>
                                        <tr>
                                            <th>Adres</th>
                                            <td>{this.state.automissionInfo.address}</td>
                                        </tr>
                                        <tr>
                                            <th>Kişi</th>
                                            <td>{this.state.automissionInfo.personName}</td>
                                        </tr>
                                        <tr>
                                            <th>Telefon</th>
                                            <td>{this.state.automissionInfo.phoneNumber}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <LabeledData
                                    label="İş Durumu"
                                    data={this.state.automissionInfo.automissionStatus?.description}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs={12} sm={6}>
                                <Row>
                                    <Col xs={12}>
                                        <Button
                                            variant="danger"
                                            className="btn-block"
                                            onClick={this.handleRejectButton}
                                            disabled={this.state.processing}
                                        >
                                            <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                                            <span>Otomatik İşi Reddet</span>
                                            <FontAwesomeIcon icon={faCircleNotch} className={this.state.rejectIconClasses} />
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className={this.state.rejectSelectionClasses}>
                                    <Col xs={12}>
                                        <SelectInput
                                            options={this.state.automissionCancellationReasons}
                                            handleChange={this.rejectAutomission}
                                            placeholder="Red Sebebi Seçiniz"
                                            isMulti={false}
                                            closeMenuOnSelect={true}
                                            value={!this.state.callerTown?.tid ? null : { value: this.state.callerTown.tid, label: this.state.callerTown.tdescr }}
                                            isDisabled={this.state.processing}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={6} >
                                <Button
                                    variant="success"
                                    className="btn-block"
                                    onClick={this.acceptAutomission}
                                    disabled={this.state.processing}
                                >
                                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                    <span>Otomatik İşi Kabul et</span>
                                    <FontAwesomeIcon icon={faCircleNotch} className={this.state.acceptIconClasses} />
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <NotificationContainer />
            </>
        );
    }
}