import React, { Component } from 'react';
import axios from 'axios';
//Custom Components
import { LabeledData } from './LabeledData';
import { TextInput } from './TextInput';
import { SelectInput } from './SelectInput';
import { DateInput } from './DateInput';
//Bootstrap
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import FormControl from 'react-bootstrap/FormControl';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
//React-Datetime
import "react-datetime/css/react-datetime.css";
import "moment/locale/tr";
import moment from 'moment';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { faHashtag } from '@fortawesome/free-solid-svg-icons'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faShare } from '@fortawesome/free-solid-svg-icons'
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'



import { faCar } from '@fortawesome/free-solid-svg-icons'
import { faMapMarked } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
//DataTable
import DataTable from 'react-data-table-component';
//Image Resizer
import Resizer from 'react-image-file-resizer';
//FullCalendar
import FullCalendar, { CalendarApi, formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';
//React-Notification
import { NotificationContainer, NotificationManager } from 'react-notifications';

//Conditional Rendering
function RaxaStatus(props) {
    const providerStaffAssignmentDate = props.providerStaffAssignmentDate;
    const providerRACDeliveryDate = props.providerRACDeliveryDate;
    const providerRACReturnDate = props.providerRACReturnDate;
    if (providerRACReturnDate) {
        return (
            <>
                <Row>
                    <Col>
                        <LabeledData label="Durum:" data="İş Tamamlandı" />
                    </Col>
                </Row>
            </>
        );
    }
    else if (providerRACDeliveryDate) {
        return (
            <>
                <Row>
                    <Col>
                        <LabeledData label="Durum:" data="Araç Teslim Edildi" />
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col>
                        <Button variant="primary" className="btn-block" onClick={props.handleRaxaReturn}>
                            <FontAwesomeIcon icon={faUndoAlt} className="mr-2" />
                            <span>Araç Dönüdü</span>
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
    else if (providerStaffAssignmentDate) {
        return (
            <>
                <Row>
                    <Col>
                        <LabeledData label="Durum:" data="Atama Yapıldı" />
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col>
                        <Button variant="primary" className="btn-block" onClick={props.handleRaxaAssignment}>
                            <FontAwesomeIcon icon={faShare} className="mr-2" />
                            <span>Atama Yap</span>
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
    else {
        return (
            <>
                <Row>
                    <Col>
                        <LabeledData label="Durum:" data="Atama Bekleniyor" />
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col>
                        <Button variant="primary" className="btn-block" onClick={props.handleRaxaAssignment}>
                            <FontAwesomeIcon icon={faShare} className="mr-2" />
                            <span>Atama Yap</span>
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}



export class ServiceDetail extends Component {
    static displayName = ServiceDetail.name;
    calendarRef = React.createRef()

    constructor(props) {
        super(props);
        this.state = {
            fullCalendarSelect: true,
            searchingIconClasses: "fa-spin d-none ml-2",
            serviceData: {},
            columns: [
                {
                    name: 'Not',
                    selector: 'note',
                    sortable: false,
                    wrap: true,
                    width: '60%',
                },
                {
                    cell: row => <span>{row.createUser == null ? row.createIpaEksperUser?.userName : row.createUser?.displayName}, {row.recordDate}</span>,
                    wrap: true
                }
            ],
            conditionalRowStyles: [
                {
                    when: row => row.user == null,
                    style: {
                        backgroundColor: 'rgb(2, 117, 216)',
                        color: 'white'
                    },
                },
                {
                    when: row => row.user != null,
                    style: {
                        backgroundColor: 'rgb(92, 184, 92)',
                        color: 'white'
                    },
                },
            ],
            saveIconClasses: "fa-spin d-none ml-2",
            searchBtnDisabled: false,
            racGroups: [],
            racGearTypes: [],
            racBrands: [],
            racModels: [],
            providerVehicleInventoryList: [],
            providerStaffInventoryList: [],
            photosPreview: [],
        };
        this.toggleIsSuspicious = this.toggleIsSuspicious.bind(this);
        this.toggleIsHumanDamage = this.toggleIsHumanDamage.bind(this);
        this.handleUnitsChange = this.handleUnitsChange.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.handleSuspiciousSituationDescriptionChange = this.handleSuspiciousSituationDescriptionChange.bind(this);
        this.handleNoteChange = this.handleNoteChange.bind(this);
        this.handleRacDeliveryDate = this.handleRacDeliveryDate.bind(this);
        this.handleRacReturnDate = this.handleRacReturnDate.bind(this);
        this.handleRacGroup = this.handleRacGroup.bind(this);
        this.handleRacGearType = this.handleRacGearType.bind(this);
        this.handleRacBrand = this.handleRacBrand.bind(this);
        this.handleRacModel = this.handleRacModel.bind(this);
        this.getVehicleInventory = this.getVehicleInventory.bind(this);
        this.getStaffInventory = this.getStaffInventory.bind(this);
        this.handleRaxaVehicleChange = this.handleRaxaVehicleChange.bind(this);
        this.handleRaxaStaffChange = this.handleRaxaStaffChange.bind(this);
        this.RaxaAssignment = this.RaxaAssignment.bind(this);
        this.getServiceData = this.getServiceData.bind(this);
        this.raxaReturn = this.raxaReturn.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.uploadPhoto = this.uploadPhoto.bind(this);
        this.getPhotos = this.getPhotos.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);

    }

    //Event Handlers
    handleUnitsChange(evt) {
        this.setState({
            serviceData: {
                ...this.state.serviceData,
                providerUnit: evt.target.value
            }
        });
    }
    handlePriceChange(evt) {
        this.setState({
            serviceData: {
                ...this.state.serviceData,
                providerPrice: evt.target.value
            }
        });
    }
    handleSuspiciousSituationDescriptionChange(evt) {
        this.setState({
            serviceData: {
                ...this.state.serviceData,
                providerSuspiciousSituationDescription: evt.target.value
            }
        });
    }
    handleNoteChange(evt) {
        this.setState({
            serviceData: {
                ...this.state.serviceData,
                providerNote: evt.target.value
            }
        });
    }
    handleRacDeliveryDate(moment) {
        this.setState({
            serviceData: {
                ...this.state.serviceData,
                providerRACDeliveryDate: moment
            }
        });
    }
    handleRacReturnDate(moment) {
        this.setState({
            serviceData: {
                ...this.state.serviceData,
                providerRACReturnDate: moment
            }
        });
    }
    handleRacGroup(selectedOption) {
        this.setState({
            serviceData: {
                ...this.state.serviceData,
                providerRACGroup: {
                    id: selectedOption.value,
                    name: selectedOption.label
                }
            }
        });
    }
    handleRacGearType(selectedOption) {
        this.setState({
            serviceData: {
                ...this.state.serviceData,
                providerRACGearType: {
                    ...this.state.serviceData.providerRACGearType,
                    id: selectedOption.value,
                    descr: selectedOption.label
                }
            }
        });
    }
    handleRacBrand(selectOption) {
        this.setState({ racModels: [] });
        this.setState({
            serviceData: {
                ...this.state.serviceData,
                providerRACBrand: {
                    vB_ID: selectOption.value,
                    vB_DESCR: selectOption.label
                }
            }
        }, () => this.getRacModelByBrandID());
    }
    handleRacModel(selectOption) {
        this.setState({
            serviceData: {
                ...this.state.serviceData,
                providerRACModel: {
                    ...this.state.serviceData.providerRACModel,
                    vM_DESCR: selectOption.label,
                    vM_ID: selectOption.value
                }
            }
        });
    }
    handleRaxaVehicleChange(selectOption) {
        const selecteInfo = selectOption.label.split(", ");
        this.setState({
            serviceData: {
                ...this.state.serviceData,
                providerRACVehicle: {
                    ...this.state.serviceData.providerRACVehicle,
                    plateNumber: selecteInfo[0],
                    brand: {
                        ...this.state.serviceData.providerRACVehicle?.brand,
                        vB_DESCR: selecteInfo[1].split(" ")[0]
                    },
                    model: {
                        ...this.state.serviceData.providerRACVehicle?.model,
                        vM_DESCR: selecteInfo[1].split(" ")[1]
                    },
                    gearType: {
                        ...this.state.serviceData.providerRACVehicle?.gearType,
                        descr: selecteInfo[2]
                    },
                    fuelType: {
                        ...this.state.serviceData.providerRACVehicle?.fuelType,
                        descr: selecteInfo[3]
                    },
                    id: selectOption.value
                }
            }
        });
    }
    handleRaxaStaffChange(selectOption) {
        this.setState({
            serviceData: {
                ...this.state.serviceData,
                providerStaff: {
                    ...this.state.serviceData.providerStaff,
                    fullName: selectOption.label,
                    id: selectOption.value
                }
            }
        });
    }
    toggleIsSuspicious() {
        if (this.state.serviceData.providerIsSuspiciousSituation === '')
            this.setState({ serviceData: { ...this.state.serviceData, providerIsSuspiciousSituation: true } });
        else
            this.setState({ serviceData: { ...this.state.serviceData, providerIsSuspiciousSituation: !this.state.serviceData.providerIsSuspiciousSituation } });
    }
    toggleIsHumanDamage() {
        if (this.state.serviceData.providerIsBodilyDamage === '')
            this.setState({ serviceData: { ...this.state.serviceData, providerIsBodilyDamage: true } });
        else
            this.setState({ serviceData: { ...this.state.serviceData, providerIsBodilyDamage: !this.state.serviceData.providerIsBodilyDamage } });
    }
    handleFileUpload(evt) {
        var fileInput = false
        if (evt.target.files[0]) {
            fileInput = true
        }
        let containsExistingFiles = false;
        if (fileInput) {
            Array.prototype.forEach.call(evt.target.files, file => {
                let fileExists = false;
                Resizer.imageFileResizer(
                    file,
                    150,
                    150,
                    'JPEG',
                    100,
                    0,
                    uri => {
                        if (!this.state.photosPreview.some(x => x === uri)) {
                            const newArray = this.state.photosPreview.concat(uri);
                            this.setState({ photosPreview: newArray });
                        }
                        else {
                            containsExistingFiles = true;
                            fileExists = true;
                        }
                    }
                );
                if (!fileExists) {
                    Resizer.imageFileResizer(
                        file,
                        1024,
                        1024,
                        'JPEG',
                        100,
                        0,
                        uri => {
                            if (!this.state.serviceData.photos?.some(x => (x === uri))) {
                                this.uploadPhoto(uri);
                            }
                        }
                    );
                }
            });
        }
        if (containsExistingFiles) {
            NotificationManager.warning("Önceden eklenmiş fotoğraflar tespit edildi.");
        }
        evt.target.value = null;
    }

    handleTabChange(evt) {
        if (evt === "photos") {
            if (this.state.photosPreview.length === 0)
                this.getPhotos();
        }
    }


    //API Calls
    async getServiceData() {
        var request = {
            ServiceID: this.state.serviceData.id,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/service/getservicebyrequest/", request)
            .then(res => {
                if (res.data.isSuccess)
                    this.setState({ serviceData: res.data.data });
                else
                    NotificationManager.error("Servis bilgileri alınamadı");
            });
    }
    async updateService() {
        this.setState({ saveIconClasses: "fa-spin ml-2" });
        this.setState({ searchBtnDisabled: true });
        var request = {
            ServiceID: this.state.serviceData.id,
            ProviderIsSuspiciousSituation: this.state.serviceData.providerIsSuspiciousSituation == null ? false : this.state.serviceData.providerIsSuspiciousSituation,
            ProviderSuspiciousSituationDescription: this.state.serviceData.providerSuspiciousSituationDescription,
            ProviderIsBodilyDamage: this.state.serviceData.providerIsBodilyDamage == null ? false : this.state.serviceData.providerIsBodilyDamage,
            ProviderPrice: parseFloat(this.state.serviceData.providerPrice),
            ProviderUnit: parseInt(this.state.serviceData.providerUnit),
            ProviderNote: this.state.serviceData.providerNote,
            ProviderRACGroupID: this.state.serviceData.providerRACGroup?.id,
            ProviderRACBrandID: this.state.serviceData.providerRACBrand?.vB_ID,
            ProviderRACModelID: this.state.serviceData.providerRACModel?.vM_ID,
            ProviderRACGearTypeID: this.state.serviceData.providerRACGearType?.id,
            ProviderRACDeliveryDate: this.state.serviceData.providerRACDeliveryDate,
            ProviderRACReturnDate: this.state.serviceData.providerRACReturnDate,
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/service/updateservice/", request)
            .then(res => {
                if (res.data.data) {
                    NotificationManager.success("Servis Onaya Gönderilmiştir.")
                    this.props.closeModal();
                }
                else {
                    NotificationManager.error(res.data.message);
                    this.setState({ saveIconClasses: "fa-spin d-none ml-2" })
                    this.setState({ searchBtnDisabled: false });
                }
            });
    }
    async getRacGroups(apiRoot) {
        axios.get("api/parameter/getRacGroups")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.racGroups.push({ value: value.id, label: value.name });
                }
            });
    }
    async getRacGearTypes(apiRoot) {
        axios.get("api/parameter/getRacGearTypes")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.racGearTypes.push({ value: value.id, label: value.descr });
                }
            });
    }
    async getRacBrands(apiRoot) {
        axios.get("api/parameter/getRacBrands")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.racBrands.push({ value: value.vB_ID, label: value.vB_DESCR });
                }
            });
    }
    async getRacModelByBrandID(apiRoot) {
        axios.get("api/parameter/getmodelsbybrandid/" + this.state.serviceData.providerRACBrand.vB_ID)
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.racModels.push({ value: value.vM_ID, label: value.vM_DESCR });
                }
            });
    }
    async getVehicleInventory() {
        var request =
        {
            ShowBussyVehicles: false,
            ShowDeleteVehicles: false,
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/raxa/getvehiclelist/", request)
            .then(res => {
                const temp = []
                if (res.data.data) {
                    for (const [, value] of res.data.data.entries()) {
                        temp.push({
                            value: value.id,
                            label: value.plateNumber + ', ' +
                                value.brand.vB_DESCR + ' ' +
                                value.model.vM_DESCR + ', ' +
                                value.gearType.descr + ', ' +
                                value.fuelType.descr
                        })
                    }
                    this.setState({ providerVehicleInventoryList: temp });
                }
            });
    }
    async getStaffInventory() {
        var request =
        {
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/raxa/getstafflist/", request)
            .then(res => {
                const temp = []
                if (res.data.data) {
                    for (const [, value] of res.data.data.entries()) {
                        temp.push({
                            value: value.id,
                            label: value.fullName
                        })
                    }
                    this.setState({ providerStaffInventoryList: temp });
                }
            });
    }
    async RaxaAssignment() {
        if (!this.state.serviceData.providerRACVehicle) {
            NotificationManager.error("Araç seçimi yapılmadı!");
            return;
        }
        if (!this.state.serviceData.providerStaff) {
            NotificationManager.error("Personel seçimi yapılmadı!");
            return;
        }
        const request = {
            ServiceID: this.state.serviceData.id,
            ProviderRACVehicleID: this.state.serviceData.providerRACVehicle.id,
            ProviderStaffID: this.state.serviceData.providerStaff.id,
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        };

        axios.post("api/raxa/racassignment/", request)
            .then(res => {
                if (res.data.data) {
                    this.getServiceData();
                }
                else
                    NotificationManager.error(res.data.message);
            });
    }
    async raxaReturn() {
        const request = {
            ServiceID: this.state.serviceData.id,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        };

        axios.post("api/raxa/raxareturn/", request)
            .then(res => {
                if (res.data.data) {
                    this.getServiceData();
                }
                else
                    NotificationManager.error(res.data.message);
            });
    }
    async uploadPhoto(Base64String) {
        var request = {
            Base64Photo: Base64String,
            ServiceID: this.state.serviceData.id,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/service/photoupload/", request)
            .then(res => {
                if (!res.data.data) {
                    NotificationManager.error(res.data.message);
                }
            });
    }
    async getPhotos() {
        var request = {
            ServiceID: this.state.serviceData.id,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/service/getservicephotosbyrequest/", request)
            .then(res => {
                if (res.data.data.length > 0) {

                    for (const [, value] of res.data.data.entries()) {
                        var dataURI = "data:image/jpeg;base64," + value.imageData;
                        var byteString = atob(dataURI.split(',')[1]);
                        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
                        var ab = new ArrayBuffer(byteString.length);
                        var ia = new Uint8Array(ab);
                        for (var i = 0; i < byteString.length; i++)
                            ia[i] = byteString.charCodeAt(i);
                        var blob = new Blob([ab], { type: mimeString });
                        Resizer.imageFileResizer(
                            blob,
                            150,
                            150,
                            'JPEG',
                            100,
                            0,
                            uri => {
                                const newArray = this.state.photosPreview.concat(uri);
                                this.setState({ photosPreview: newArray });
                            }
                        );
                    }
                    this.setState({ noPhoto: false });
                    NotificationManager.success("Fotoğraflar yüklendi")
                }
                else {
                    this.setState({ noPhoto: true })
                    NotificationManager.warning("Serviste fotoğraf bulunmamaktadır");
                }
            });
    }

    //Component Events
    componentDidMount() {
        this.props.service.data.data.ipaeKsperProviderApprovalNotes.forEach(note => {
            note.recordDate = moment(note.recordDate).format("DD.MM.YYYY HH:mm");
        });
        this.setState({ serviceData: this.props.service.data.data }, () => {
            if (this.state.serviceData.providerRACBrand?.vB_ID) this.getRacModelByBrandID();

        });
        this.getRacGroups();
        this.getRacGearTypes();
        this.getRacBrands();
        this.getVehicleInventory();
        this.getStaffInventory();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.service !== this.props.service) {
            this.setState({ serviceData: this.props.service.data.data });
        }
    }

    render() {
        return (
            <>
                <div className="noPrint">
                    <Card className="mb-1">
                        <Card.Header>
                            <h6>Genel Bilgileri</h6>
                        </Card.Header>
                        <Card.Body>
                            <h5><Badge variant="primary">Servis Bilgileri</Badge></h5>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className="mb-0">
                                        <Row>
                                            <Col xs={12} sm={12} md={6}>
                                                <LabeledData label="Plaka:" data={this.state.serviceData.serviceCase?.caseProductOwner?.platePolicyNumber} />
                                            </Col>
                                            <Col xs={12} sm={12} md={6}>
                                                <LabeledData label="Muşteri:" data={this.state.serviceData.serviceCase?.caseProductOwner?.name} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={6}>
                                                <LabeledData label="Hizmet:" data={this.state.serviceData.selectedServiceType?.description} />
                                            </Col>
                                            <Col xs={12} sm={12} md={6}>
                                                <LabeledData label="Müşteri Tel:" data={this.state.serviceData.serviceCase?.callerMobilePhoneNumber} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={6}>
                                                <LabeledData label="Tarih:" data={moment(this.state.serviceData.serviceDate).format("DD.MM.YYYY HH:mm")} />
                                            </Col>
                                            <Col xs={12} sm={12} md={6}>
                                                <LabeledData label="Protokol:" data={this.state.serviceData.serviceCase?.caseProthocolNumber} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={12}>
                                                <LabeledData label="İhbar Tarihi:" data={moment(this.state.serviceData.serviceCase?.callDate).format("DD.MM.YYYY HH:mm")} />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div name="racInfo" className={this.state.serviceData.selectedServiceType?.containsRentalVehicleInformation ? "" : "d-none"}>
                                <hr />
                                <h5><Badge variant="primary">Kiralık Araç Bilgileri</Badge></h5>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group className="mb-0">
                                            <Row className="mb-3">
                                                <Col xs={12} sm={12} md={4}>
                                                    <DateInput
                                                        timeFormat={true}
                                                        label="Arac Teslim Tarihi"
                                                        placeholder="Arac Teslim Tarihi"
                                                        handleChange={this.handleRacDeliveryDate}
                                                        //disabled={this.state.serviceData.providerUsesRaxa}
                                                        value={this.state.serviceData.providerRACDeliveryDate ? moment(this.state.serviceData.providerRACDeliveryDate) : ''}
                                                    />
                                                </Col>
                                                <Col xs={12} sm={12} md={4}>
                                                    <DateInput
                                                        timeFormat={true}
                                                        label="Araç Geri Alma Tarihi"
                                                        placeholder="Araç Geri Alma Tarihi"
                                                        handleChange={this.handleRacReturnDate}
                                                        //disabled={this.state.serviceData.providerUsesRaxa}
                                                        value={this.state.serviceData.providerRACReturnDate ? moment(this.state.serviceData.providerRACReturnDate) : ''}
                                                    />
                                                </Col>
                                                <Col xs={12} sm={12} md={4}>
                                                    <SelectInput
                                                        options={this.state.racGroups}
                                                        handleChange={this.handleRacGroup}
                                                        label="Araç Sınıfı"
                                                        placeholder="Araç Sınıfı Seçiniz"
                                                        isMulti={false}
                                                        closeMenuOnSelect={true}
                                                        value={!this.state.serviceData.providerRACGroup?.id ? null : { value: this.state.serviceData.providerRACGroup.id, label: this.state.serviceData.providerRACGroup.name }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} md={4}>
                                                    <SelectInput
                                                        options={this.state.racGearTypes}
                                                        handleChange={this.handleRacGearType}
                                                        label="Vites Tipi"
                                                        placeholder="Vites Tipi Seçiniz"
                                                        isMulti={false}
                                                        closeMenuOnSelect={true}
                                                        value={!this.state.serviceData.providerRACGearType?.id ? null : { value: this.state.serviceData.providerRACGearType.id, label: this.state.serviceData.providerRACGearType.descr }}
                                                        //isDisabled={this.state.serviceData.providerUsesRaxa}
                                                    />
                                                </Col>
                                                <Col xs={12} sm={12} md={4}>
                                                    <SelectInput
                                                        options={this.state.racBrands}
                                                        handleChange={this.handleRacBrand}
                                                        label="Araç Markası"
                                                        placeholder="Araç Markası Seçiniz"
                                                        isMulti={false}
                                                        closeMenuOnSelect={true}
                                                        value={!this.state.serviceData.providerRACBrand?.vB_ID ? null : { value: this.state.serviceData.providerRACBrand.vB_ID, label: this.state.serviceData.providerRACBrand.vB_DESCR }}
                                                        //isDisabled={this.state.serviceData.providerUsesRaxa}
                                                    />
                                                </Col>
                                                <Col xs={12} sm={12} md={4}>
                                                    <SelectInput
                                                        options={this.state.racModels}
                                                        handleChange={this.handleRacModel}
                                                        label="Araç Modeli"
                                                        placeholder="Araç Modeli Seçiniz"
                                                        isMulti={false}
                                                        closeMenuOnSelect={true}
                                                        value={!this.state.serviceData.providerRACModel?.vM_ID ? null : { value: this.state.serviceData.providerRACModel.vM_ID, label: this.state.serviceData.providerRACModel.vM_DESCR }}
                                                        //isDisabled={this.state.serviceData.providerUsesRaxa}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                    <Tabs defaultActiveKey="servis" onSelect={this.handleTabChange} >
                        <Tab eventKey="servis" title="Onay & Fiyatlandırma">
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group className="mb-0">
                                                <Row className="mb-1">
                                                    <Col xs={12} sm={12} md={6}>
                                                        <TextInput faicon={faHashtag} label="Hizmet Miktarı(Km/Gün)" placeholder="Hizmet Miktarı(Km/Gün)" value={this.state.serviceData.providerUnit ? this.state.serviceData.providerUnit : ''} handleChange={this.handleUnitsChange} />
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6}>
                                                        <TextInput faicon={faMoneyBill} label="Toplam Hizmet Tutarı(KDV Hariç)" placeholder="Toplam Hizmet Tutarı(KDV Hariç)" value={this.state.serviceData.providerPrice ? this.state.serviceData.providerPrice : ''} handleChange={this.handlePriceChange} />
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col xs={12} sm={12} md={6}>
                                                        <Row>
                                                            <Col>
                                                                <label>Tedarikçi Notu:</label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col >
                                                                <FormControl
                                                                    as="textarea"
                                                                    className="rounded-0 noresize"
                                                                    placeholder="Yeni Not"
                                                                    rows={3}
                                                                    onChange={this.handleNoteChange}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6}>
                                                        <div className={this.state.serviceData?.selectedServiceType?.containsFraudInformation ? "" : "d-none"}>
                                                            <Row>
                                                                <Col>
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id="isSuspicious"
                                                                        label="Şüpheli Durum Varmı?"
                                                                        checked={this.state.serviceData.providerIsSuspiciousSituation ? this.state.serviceData.providerIsSuspiciousSituation : false}
                                                                        onChange={this.toggleIsSuspicious}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <div>
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            className="rounded-0 noresize mt-2"
                                                                            placeholder="Şüpheli Durum Açıklaması"
                                                                            value={this.state.serviceData.providerSuspiciousSituationDescription ? this.state.serviceData.providerSuspiciousSituationDescription : ''}
                                                                            rows={3}
                                                                            onChange={e => this.handleSuspiciousSituationDescriptionChange(e)}
                                                                            disabled={!this.state.serviceData.providerIsSuspiciousSituation}
                                                                        />
                                                                    </div>
                                                                    <hr />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <Row className={this.state.serviceData?.selectedServiceType?.containsHumanDamageInformation ? "" : "d-none"}>
                                                            <Col>
                                                                <Form.Check
                                                                    type="switch"
                                                                    id="isHumanDamage"
                                                                    label="Bedeni Zarar Varmı?"
                                                                    checked={this.state.serviceData.providerIsBodilyDamage ? this.state.serviceData.providerIsBodilyDamage : false}
                                                                    onChange={this.toggleIsHumanDamage}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Row className="mt-3">
                                <Col>
                                    <Button variant="primary" className="float-right" disabled={this.state.searchBtnDisabled} onClick={() => this.updateService()} >
                                        <FontAwesomeIcon icon={faSave} className="mr-2" />
                                        <span>Kaydet ve Gönder</span>
                                        <FontAwesomeIcon className={this.state.saveIconClasses} icon={faCircleNotch} />
                                    </Button>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="photos" title="Fotoğraflar">
                            <Card className="mb-1">
                                <Card.Body>
                                    <Row className="mb-2">
                                        <Col xs={12}>
                                            <Form>
                                                <Form.File multiple label="Fotoğraf Yükle" custom onChange={this.handleFileUpload} />
                                            </Form>
                                        </Col>
                                    </Row>
                                    <div className="d-flex justify-content-center" >
                                        <FontAwesomeIcon icon={faCircleNotch} spin size="6x" className={this.state.noPhoto || this.state.photosPreview.length > 0 ? "d-none" : ""} />
                                    </div>
                                    <Row>

                                        {this.state.photosPreview.map((item, i) => (
                                            <Col xs={12} sm={6} md={4} lg={3} key={i} className="p-2 border-bottom" align="center">
                                                <img src={item} alt="X" />
                                            </Col>
                                        ))}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Tab>
                        <Tab eventKey="notes" title="Notlar">
                            <Card className="mb-1">
                                {this.state.proto}
                                <Card.Body>
                                    <DataTable
                                        columns={this.state.columns}
                                        data={this.state.serviceData.ipaeKsperProviderApprovalNotes}
                                        defaultSortField="title"
                                        conditionalRowStyles={this.state.conditionalRowStyles}
                                        subHeader={true}
                                        noDataComponent={
                                            <h5>Veri bulunmamaktadır</h5>
                                        }
                                        subHeaderComponent={(
                                            <div style={{ alignItems: 'center' }}>
                                                <FontAwesomeIcon icon={faSquare} className="mr-2 text-primary" /> Tedarikçi Notları
                                                <br />
                                                <FontAwesomeIcon icon={faSquare} className="mr-2 text-success" /> IPA Notları
                                            </div>
                                        )}
                                        highlightOnHover={true}
                                        striped={true}
                                    />
                                </Card.Body>
                            </Card>
                        </Tab>
                        <Tab eventKey="raxa" title="R-AXA" disabled={!this.state.serviceData.selectedServiceType?.isRaxaService}>
                            <Card>
                                <Card.Header>
                                    <h6>Personel ve Araç Ataması</h6>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xs={12} sm={12} md={12}>
                                            <LabeledData label="Otomatik İş Mesajı:" data={this.state.serviceData.autoMissionMessage} />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col xs={12} sm={12} md={4}>
                                            <SelectInput
                                                options={this.state.providerVehicleInventoryList}
                                                handleChange={this.handleRaxaVehicleChange}
                                                label="Araç Seçimi"
                                                placeholder="Araç Seçiniz"
                                                isMulti={false}
                                                closeMenuOnSelect={true}
                                                value={this.state.serviceData.providerRACVehicle ? {
                                                    value: this.state.serviceData.providerRACVehicle.id,
                                                    label: this.state.serviceData.providerRACVehicle.plateNumber + ', ' +
                                                        this.state.serviceData.providerRACVehicle.brand.vB_DESCR + ' ' +
                                                        this.state.serviceData.providerRACVehicle.model.vM_DESCR + ', ' +
                                                        this.state.serviceData.providerRACVehicle.gearType.descr + ', ' +
                                                        this.state.serviceData.providerRACVehicle.fuelType.descr
                                                } : null}
                                                isDisabled={this.state.serviceData.providerRACDeliveryDate ? true : false}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={4}>
                                            <SelectInput
                                                options={this.state.providerStaffInventoryList}
                                                handleChange={this.handleRaxaStaffChange}
                                                label="Personel Seçimi"
                                                placeholder="Personel Seçiniz"
                                                isMulti={false}
                                                closeMenuOnSelect={true}
                                                value={this.state.serviceData.providerStaff ? { value: this.state.serviceData.providerStaff.id, label: this.state.serviceData.providerStaff.fullName } : null}
                                                isDisabled={this.state.serviceData.providerRACDeliveryDate ? true : false}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={4}>
                                            <label>R-AXA Durumu</label>
                                            <RaxaStatus
                                                providerStaffAssignmentDate={this.state.serviceData.providerStaffAssignmentDate}
                                                providerRACDeliveryDate={this.state.serviceData.providerRACDeliveryDate}
                                                providerRACReturnDate={this.state.serviceData.providerRACReturnDate}
                                                handleRaxaAssignment={this.RaxaAssignment}
                                                handleRaxaReturn={this.raxaReturn}
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Tab>
                    </Tabs>
                </div>
                <NotificationContainer />
            </>
        );
    }
}
