import React, { Component } from 'react';
import axios from 'axios';
//Custom Components
import { LabeledData } from './LabeledData';
//Bootstrap
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
//React-Datetime
import "react-datetime/css/react-datetime.css";
import "moment/locale/tr";
import moment from 'moment';
//Image Resizer
import Resizer from 'react-image-file-resizer';

export class InsuranceServiceDetail extends Component {
    static displayName = InsuranceServiceDetail.name;

    constructor(props) {
        super(props);
        this.state = {
            serviceData: {},
            photosPreview: []
        };
    }


    //Component Events
    componentDidMount() {
        this.setState({ serviceData: this.props.service.data.data }, () => {
            for (const [, value] of this.state.serviceData?.photos.entries()) {
                var dataURI = "data:image/jpeg;base64," + value.imageData;
                var byteString = atob(dataURI.split(',')[1]);
                var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
                var ab = new ArrayBuffer(byteString.length);
                var ia = new Uint8Array(ab);
                for (var i = 0; i < byteString.length; i++)
                    ia[i] = byteString.charCodeAt(i);
                var blob = new Blob([ab], { type: mimeString });
                Resizer.imageFileResizer(
                    blob,
                    600,
                    600,
                    'JPEG',
                    100,
                    0,
                    uri => {
                        const newArray = this.state.photosPreview?.concat(uri);
                        this.setState({ photosPreview: newArray });
                    }
                );
            }
        });
    }
    componentDidUpdate(prevProps) {
        if (prevProps.service !== this.props.service) {
            this.setState({ serviceData: this.props.service.data.data });
        }
    }

    render() {

        return (
            <>
                <Card className="mb-1">
                    <Card.Header>
                        <h6>Servis Bilgileri</h6>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="mb-0">
                                    <Row>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Dosya No:" data={this.state.serviceData.serviceCase?.caseReferanceNumber} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Çağrı Tarihi:" data={this.state.serviceData.serviceCase?.callDate == null ? "" : moment(this.state.serviceData.serviceCase?.callDate).format("DD.MM.YYYY HH:mm")} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Poliçe No:" data={this.state.serviceData.serviceCase?.caseProductOwner?.productPolicyNumber} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Servis:" data={this.state.serviceData.selectedServiceType?.description} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Hasar Tip:" data={this.state.serviceData.serviceCase?.damageDescription} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Plaka:" data={this.state.serviceData.serviceCase?.caseProductOwner?.platePolicyNumber} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Sigortalı:" data={this.state.serviceData.serviceCase?.caseProductOwner?.name} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Arayan Kişi Ad/Soyad:" data={this.state.serviceData.serviceCase?.callerName} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Arayan Kişi Telefonu:" data={this.state.serviceData.serviceCase?.callerPhoneNumber} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Olay Yeri Şehir:" data={this.state.serviceData.serviceCase?.town?.description} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Olay Yeri İlçe:" data={this.state.serviceData.serviceCase?.townArea?.description} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Olay Yeri:" data={this.state.serviceData.serviceCase?.sceneArea} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Olay Yeri İlçe:" data={this.state.serviceData.serviceCase?.caseProductOwner?.vehicleBrand} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Olay Yeri İlçe:" data={this.state.serviceData.serviceCase?.caseProductOwner?.vehicleModel} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Şüpheli Durum:" data={this.state.serviceData.providerIsSuspiciousSituation ? "Var" : "Yok"} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                            <LabeledData label="Bedeni Zarar:" data={this.state.serviceData.providerIsBodilyDamage ? "Var":"Yok"} />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className={this.state.serviceData?.photos?.length > 0 ? "" : "d-none"}>
                    <Card.Header>
                        <h6>Fotoğraflar</h6>
                    </Card.Header>
                    <Card.Body>
                        <Carousel>
                            {this.state.photosPreview?.map(item => (
                                <Carousel.Item key={item.imageData}>
                                    <div className="d-flex justify-content-center">
                                        <img src={item} alt="X" />
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Card.Body>
                </Card>
            </>
        );
    }
}
