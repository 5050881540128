import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios'
//Custom Components
import { TextInput } from './TextInput';
import { SelectInput } from './SelectInput';
import { DateInput } from './DateInput';
//Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faIdCard } from '@fortawesome/free-solid-svg-icons'
import { faHashtag } from '@fortawesome/free-solid-svg-icons'
import { faBox } from '@fortawesome/free-solid-svg-icons'
import { faCar } from '@fortawesome/free-solid-svg-icons'
import { faCarSide } from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faBoxes } from '@fortawesome/free-solid-svg-icons'
import { faTint } from '@fortawesome/free-solid-svg-icons'
import { faDirections } from '@fortawesome/free-solid-svg-icons'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
//React-Notification
import { NotificationContainer, NotificationManager } from 'react-notifications';

//Conditional Rendering
function RenderTowingInfo(props) {
    if (!props.selectedServiceTypes?.length > 0)
        return(<></>);
    var render = false;
    for (const [, value] of props.selectedServiceTypes.entries()) {
        if (value.value === 1) {
            render = true;
            break;
        }
    }
    if (render) {
        return (
            <>
                <hr/>
                <Card>
                    <Card.Header>
                        <h5>Çekici Bilgileri</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={12} sm={6}>
                                <label>Servis Adı</label>
                                <FormControl
                                    as="textarea"
                                    name="towingServiceName"
                                    className="rounded-0 noresize"
                                    placeholder="Çekilecek servisin adı"
                                    rows={3}
                                    onChange={props.handleTextInputChange}
                                    value={props.towingServiceName}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <label>Hizmet Adresi</label>
                                <FormControl
                                    as="textarea"
                                    name="towingServiceAddress"
                                    className="rounded-0 noresize"
                                    placeholder="Hizmet verilecek adresin açık tarifi"
                                    rows={3}
                                    onChange={props.handleTextInputChange}
                                    value={props.towingServiceAddress}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>
        )
    }
    return (<></>);
}
function RenderRentalInfo(props) {
    if (!props.selectedServiceTypes?.length > 0)
        return(<></>);
    var render = false;
    for (const [, value] of props.selectedServiceTypes.entries()) {
        if (value.value === 2) {
            render = true;
            break;
        }
    }
    if (render) {
        return (
            <>
                <hr/>
                <Card>
                    <Card.Header>
                        <h5>Ikame Bilgileri</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={12} sm={6}>
                                <DateInput
                                    timeFormat={false}
                                    label="İkame Teslim Tarihi"
                                    placeholder="İkame Teslim Tarihi Seçiniz"
                                    handleChange={props.handleRacDeliveryDateChange}
                                    value={props.racDeliveryDate ? props.racDeliveryDate : ""}
                                />  
                            </Col>
                            <Col xs={12} sm={6}>
                                <SelectInput
                                    options={props.racVehicleSegments}
                                    handleChange={props.handleRacVehicleSegmentChange}
                                    label="Araç Grubu"
                                    placeholder="Araç Grubu Seçiniz"
                                    faicon={faCar}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={!props.racVehicleSegment.id ? null : { value: props.racVehicleSegment.id, label: props.racVehicleSegment.vehicleSegmentName }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6}>
                                <SelectInput
                                    options={props.racVehicleGearTypes}
                                    handleChange={props.handleRacVehicleGearTypeChange}
                                    label="Şanzıman Tipi"
                                    placeholder="Şanzıman Tipi Seçiniz"
                                    faicon={faCarSide}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={!props.racVehicleGearType.id ? null : { value: props.racVehicleGearType.id, label: props.racVehicleGearType.GearTypeName }}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <SelectInput
                                    options={props.racVehicleFuelTypes}
                                    handleChange={props.handleRacVehicleFuelTypeChange}
                                    label="Yakıt Tipi"
                                    placeholder="Yakıt Tipi Seçiniz"
                                    faicon={faTint}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={!props.racVehicleFuelType.id ? null : { value: props.racVehicleFuelType.id, label: props.racVehicleFuelType.FuelTypeName }}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>
        )
    }
    return (<></>);
}
function RenderValetInfo(props) {
    if (!props.selectedServiceTypes?.length > 0)
        return(<></>);
    var render = false;
    for (const [, value] of props.selectedServiceTypes.entries()) {
        if (value.value === 3) {
            render = true;
            break;
        }
    }
    if (render) {
        return (
            <>
                <hr/>
                <Card>
                    <Card.Header>
                        <h5>Vale Bilgileri</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={12} sm={6}>
                                <DateInput
                                    timeFormat={false}
                                    label="Vale Talep Tarihi"
                                    placeholder="Vale Talep Tarihi Seçiniz"
                                    handleChange={props.handleValetRequestDateChange}
                                    value={props.valetRequestDate ? props.valetRequestDate : ""}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <SelectInput
                                    options={props.valetDirections}
                                    handleChange={props.handleValetDirectionChange}
                                    label="Vale Yönü"
                                    placeholder="Vale Yönü Seçiniz"
                                    faicon={faDirections}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={!props.valetDirection.id ? null : { value: props.valetDirection.id, label: props.valetDirection.valetDirection }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6}>
                                <label>Servis Adı</label>
                                <FormControl
                                    as="textarea"
                                    name="valetServiceName"
                                    className="rounded-0 noresize"
                                    placeholder="Hizmet verilecek servisin adı"
                                    rows={3}
                                    onChange={props.handleTextInputChange}
                                    value={props.valetServiceName}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <label>Hizmet Adresi</label>
                                <FormControl
                                    as="textarea"
                                    name="valetServiceAddress"
                                    className="rounded-0 noresize"
                                    placeholder="Hizmet verilecek adresin açık tarifi"
                                    rows={3}
                                    onChange={props.handleTextInputChange}
                                    value={props.valetServiceAddress}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>
        )
    }
    return (<></>);
}

export class NewCaseRequest extends Component {
    static displayName = NewCaseRequest.name;

    constructor(props) {
        super(props);
        this.state = {
            policyNumber: "",
            policyHolderName: "",
            policyHoderPlate: "",
            policyHolderProduct: "",
            policyHolderVehicleBrand: "",
            policyHolderVehicleModel: "",
            policyHolderVehicleModelYear: "",
            callerName: "",
            callerPhone: "",
            callerTown: {},
            callerTownArea: {},
            callerDistrict: "",
            callerReasonOfCall: {},
            callerDescription: "",
            callerServiceTypes: [],
            towingServiceName: "",
            towingServiceAddress: "",
            racDeliveryDate: "",
            racVehicleSegment: {},
            racVehicleGearType: {},
            racVehicleFuelType: {},
            valetDirection: {},
            valetRequestDate: "",
            valetServiceName: "",
            valetServiceAddress: "",
            towns: [],
            townAreas: [],
            callReasons: [],
            serviceTypes: [],
            racVehicleSegments: [],
            racVehicleGearTypes: [],
            racVehicleFuelTypes: [],
            valetDirections: [],
            policyManuelEntry: false,
            saveIconClasses: "fa-spin d-none ml-2",
            saveBtnDisabled: false,
            searchIsLoading: false,
            redirect: null
        };
        this.handleTextInputChange = this.handleTextInputChange.bind(this);
        this.handleIntegerTextInputChange = this.handleIntegerTextInputChange.bind(this);
        this.handlePhoneTextInputChange = this.handlePhoneTextInputChange.bind(this);
        this.handleTownChange = this.handleTownChange.bind(this);
        this.handleTownAreaChange = this.handleTownAreaChange.bind(this);
        this.handleRequestCallReasonChange = this.handleRequestCallReasonChange.bind(this);
        this.handleRequestServiceTypeChange = this.handleRequestServiceTypeChange.bind(this);
        this.handleRacDeliveryDateChange = this.handleRacDeliveryDateChange.bind(this);
        this.handleRacVehicleSegmentChange = this.handleRacVehicleSegmentChange.bind(this);
        this.handleRacVehicleGearTypeChange = this.handleRacVehicleGearTypeChange.bind(this);
        this.handleRacVehicleFuelTypeChange = this.handleRacVehicleFuelTypeChange.bind(this);
        this.handleValetDirectionChange = this.handleValetDirectionChange.bind(this);
        this.handleValetRequestDateChange = this.handleValetRequestDateChange.bind(this);
        this.getTowns = this.getTowns.bind(this);
        this.getTownAreasByTownID = this.getTownAreasByTownID.bind(this);
        this.getCaseRequestCallReasonList = this.getCaseRequestCallReasonList.bind(this);
        this.getCaseRequestServiceTypeList = this.getCaseRequestServiceTypeList.bind(this);
        this.getVehicleSegmentList = this.getVehicleSegmentList.bind(this);
        this.getGearTypeList = this.getGearTypeList.bind(this);
        this.getFuelTypeList = this.getFuelTypeList.bind(this);
        this.getValetDirectionsList = this.getValetDirectionsList.bind(this);
        this.getCustomerByPolicyNumber = this.getCustomerByPolicyNumber.bind(this);
        this.saveCaseRequest = this.saveCaseRequest.bind(this);
    }

    handleTextInputChange(evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    handleIntegerTextInputChange(evt) {
        if ((String(parseInt(evt.target.value)) === evt.target.value && parseInt(evt.target.value) > 0) || evt.target.value === "")
            this.setState({ [evt.target.name]: evt.target.value });
    }
    handlePhoneTextInputChange(evt) {
        if (evt.target.value === "") {
            this.setState({ [evt.target.name]: evt.target.value });

        } else {
            var validNumber = true;
            if (evt.target.value.split("")[0] !== "0") {
                evt.target.value = "0" + evt.target.value
            }
            if (evt.target.value.length > 11) {
                validNumber = false;
            }
            evt.target.value.split("").forEach((v) => {
                if (isNaN(v)) {
                    validNumber = false;
                }
            });
            if (!validNumber) {
                return;
            }
            this.setState({ [evt.target.name]: evt.target.value });
        }
    }
    handleTownChange(selectedOption) {
        this.setState({ townAreas: [] }, () =>
            this.setState({
                callerTown: {
                    tid: selectedOption.value,
                    tdescr: selectedOption.label
                }
            }, () => this.getTownAreasByTownID(this.state.callerTown.tid))
        );
    }
    handleTownAreaChange(selectedOption) {
        this.setState({
            callerTownArea: {
                taid: selectedOption.value,
                tadescr: selectedOption.label
            }
        });
    }
    handleRequestCallReasonChange(selectedOption) {
        this.setState({
            callerReasonOfCall: {
                id: selectedOption.value,
                callReason: selectedOption.label
            }
        });
    }
    handleRequestServiceTypeChange(selectedOption) {
        var newState = Object.assign({}, this.state);
        if (selectedOption?.length > 0) {
            var blacklistedServiceIDs = [];
            for (const [, value] of selectedOption.entries()) {
                for (const [, blacklistedValue] of value.blackList.entries()) {
                    if (!blacklistedServiceIDs.includes(blacklistedValue.blacklistedCaseRequestServiceTypeID)) {
                        blacklistedServiceIDs.push(blacklistedValue.blacklistedCaseRequestServiceTypeID);
                    }
                }
            }
            for (const [, serviceType] of newState.serviceTypes.entries()) {
                if (blacklistedServiceIDs.includes(serviceType.value)) {
                    serviceType.isDisabled = true;
                } else {
                    serviceType.isDisabled = false;
                }
            }
        }
        else {
            for (const [, serviceType] of newState.serviceTypes.entries()) {
                serviceType.isDisabled = false;
            }
        }
        newState.callerServiceTypes = selectedOption;
        this.setState(newState);
    }
    handleRacDeliveryDateChange(moment) {
        this.setState({ racDeliveryDate: moment });
    }
    handleRacVehicleSegmentChange(selectedOption) {
        this.setState({
            racVehicleSegment: {
                id: selectedOption.value,
                vehicleSegmentName: selectedOption.label
            }
        });
    }
    handleRacVehicleGearTypeChange(selectedOption) {
        this.setState({
            racVehicleGearType: {
                id: selectedOption.value,
                GearTypeName: selectedOption.label
            }
        });
    }
    handleRacVehicleFuelTypeChange(selectedOption) {
        this.setState({
            racVehicleFuelType: {
                id: selectedOption.value,
                FuelTypeName: selectedOption.label
            }
        });
    }
    handleValetDirectionChange(selectedOption) {
        this.setState({
            valetDirection: {
                id: selectedOption.value,
                valetDirection: selectedOption.label
            }
        });
    }
    handleValetRequestDateChange(moment) {
        this.setState({ valetRequestDate: moment });
    }

    async getTowns() {
        axios.get("api/parameter/gettowns")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.towns.push({ value: value.tid, label: value.tdescr });
                }
            });
    }
    async getTownAreasByTownID(townid) {
        axios.get("api/parameter/gettownareasbytownid/" + townid)
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.townAreas.push({ value: value.taid, label: value.tadescr });
                }
            });
    }
    async getCaseRequestCallReasonList() {
        axios.get("api/parameter/getcaserequestcallreasonlist")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.callReasons.push({ value: value.id, label: value.callReason });
                }
            });
    }
    async getCaseRequestServiceTypeList() {
        axios.get("api/parameter/getcaserequestservicetypelist")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.serviceTypes.push({ value: value.id, label: value.serviceType, blackList: value.blackListedServiceType, isDisabled:false });
                }
            });
    }
    async getVehicleSegmentList() {
        axios.get("api/parameter/getvehiclesegments")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.racVehicleSegments.push({ value: value.id, label: value.vehicleSegmentName });
                }
            });
    }
    async getGearTypeList() {
        axios.get("api/parameter/getgeartypes")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.racVehicleGearTypes.push({ value: value.id, label: value.gearTypeName });
                }
            });
    }
    async getFuelTypeList() {
        axios.get("api/parameter/getfueltypes")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.racVehicleFuelTypes.push({ value: value.id, label: value.fuelTypeName });
                }
            });
    }
    async getValetDirectionsList() {
        axios.get("api/parameter/getvaletdirections")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.valetDirections.push({ value: value.id, label: value.valetDirection });
                }
            });
    }
    async getCustomerByPolicyNumber() {
        if (!this.state.policyNumber) {
            NotificationManager.error("Poliçe Numarası Girilmedi");
            return;
        }
        this.setState({ searchIsLoading: true})
        var request =
        {
            PolicyNumber: this.state.policyNumber,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/caserequest/getpolicyholderbyrequest/", request)
            .then(res => {
                if (res.data?.isSuccess) {
                    if (res.data.data?.policyFound) {
                        this.setState({
                            ...this.state,
                            policyHolderName: res.data.data.customerFullName,
                            policyHoderPlate: res.data.data.customerPlateNumber,
                            policyHolderProduct: res.data.data.productName,
                            policyHolderVehicleBrand: res.data.data.vehicleBrand,
                            policyHolderVehicleModel: res.data.data.vehicleModel,
                            policyHolderVehicleModelYear: res.data.data.vehicleModelYear,
                            policyManuelEntry: false,
                            searchIsLoading: false
                        });
                        NotificationManager.success("Sigortalı Bulundu");
                    } else {
                        this.setState({
                            ...this.state,
                            policyManuelEntry: true,
                            searchIsLoading: false
                        });
                        NotificationManager.warning("Sigortalı Bulunamadı. Bilgileri Manuel Girerek İşme Yapabilirsiniz");
                    }
                } else {
                    this.setState({
                        ...this.state,
                        policyManuelEntry: true,
                        searchIsLoading: false
                    });
                    NotificationManager.warning("Sigortalı Bulunamadı. Bilgileri Manuel Girerek İşme Yapabilirsiniz");
                }
            });
    }
    async saveCaseRequest() {     
        //Mandatory Field Check
        if (!this.state.policyNumber) {
            NotificationManager.error("Poliçe Numarası Girilmedi");
            return;
        }
        if (!this.state.policyHolderName) {
            NotificationManager.error("Sigortalı Adı Soyadı Girilmedi");
            return;
        }
        if (!this.state.policyHolderProduct) {
            NotificationManager.error("Ürün Girilmedi");
            return;
        }
        if (!this.state.callerName) {
            NotificationManager.error("Hizmet Verilecek Kişi Adı Soyadı Girilmed");
            return;
        }
        if (!this.state.callerPhone) {
            NotificationManager.error("Hizmet Verilecek Kişi Telefon Numarası Girilmedi");
            return;
        }
        if (!this.state.callerTown?.tid) {
            NotificationManager.error("Şehir Seçilmedi");
            return;
        }
        if (!this.state.callerTownArea?.taid) {
            NotificationManager.error("İlçe Seçilmedi");
            return;
        }
        if (!this.state.callerReasonOfCall?.id) {
            NotificationManager.error("Arama Sebebi Seçilmedi");
            return;
        }
        if (!this.state.callerServiceTypes?.length > 0) {
            NotificationManager.error("Talepler Seçilmedi");
            return;
        }
        var missingParameter = false;
        this.state.callerServiceTypes.forEach((value) => {
            if (value.value === 1) {
                if (!this.state.towingServiceName) {
                    NotificationManager.error("Çekicilecek Servisin Adı Girilmedi");
                    missingParameter = true;
                    return;
                }
                if (!this.state.towingServiceAddress) {
                    NotificationManager.error("Çekicilecek Servisin Adresi Girilmedi");
                    missingParameter = true;
                    return;
                }
            }
            if (value.value === 2) {
                if (!this.state.racDeliveryDate) {
                    NotificationManager.error("İkame Araç Teslim Tarihi Seçilmedi");
                    missingParameter = true; 
                    return;
                }
                if (!this.state.racVehicleSegment?.id) {
                    NotificationManager.error("İkame Araç Grubu Seçilmedi");
                    missingParameter = true;
                    return;
                }
                if (!this.state.racVehicleGearType?.id) {
                    NotificationManager.error("İkame Şanzıman Seçilmedi");
                    missingParameter = true;
                    return;
                }
                if (!this.state.racVehicleFuelType?.id) {
                    NotificationManager.error("İkame Yakıt Tipi Seçilmedi");
                    missingParameter = true;
                    return;
                }
            }
            if (value.value === 3) {
                if (!this.state.valetRequestDate) {
                    NotificationManager.error("Vale Talep Tarihi Seçilmedi");
                    missingParameter = true;
                    return;
                }
                if (!this.state.valetDirection?.id) {
                    NotificationManager.error("Vale Yönü Seçilmedi");
                    missingParameter = true;
                    return;
                }
                if (!this.state.valetServiceName) {
                    NotificationManager.error("Vale Hizmet Verilecek Servis Adı Girilmedi");
                    missingParameter = true;
                    return;
                }
                if (!this.state.valetServiceAddress) {
                    NotificationManager.error("Vale Hizmet Verilecek Servis Adresi Girilmed");
                    missingParameter = true;
                    return;
                }
            }
        })
        if (missingParameter) {
            return;
        }

        //Disable Button
        this.setState({ saveIconClasses: "fa-spin ml-2" });
        this.setState({ saveBtnDisabled: true });

        //Get Selected Service Type IDs
        var selectedServiceTypes = [];
        for (const [, value] of this.state.callerServiceTypes.entries()) {
            selectedServiceTypes.push(value.value);
        }        

        //Create request
        var request =
        {
            PolicyNumber: this.state.policyNumber,
            PolicyHolderName: this.state.policyHolderName,
            PolicyHoderPlate: this.state.policyHoderPlate,
            PolicyHolderProduct: this.state.policyHolderProduct,
            PolicyHolderVehicleBrand: this.state.policyHolderVehicleBrand,
            PolicyHolderVehicleModel: this.state.policyHolderVehicleModel,
            PolicyHolderVehicleModelYear: parseInt(this.state.policyHolderVehicleModelYear),
            CallerName: this.state.callerName,
            CallerPhone: this.state.callerPhone,
            CallerTownID: this.state.callerTown?.tid,
            CallerTownAreaID: this.state.callerTownArea?.taid,
            CallerDistrict: this.state.callerDistrict,
            CallerReasonOfCallID: this.state.callerReasonOfCall?.id,
            CallerDescription: this.state.callerDescription,
            TowingServiceName: this.state.towingServiceName,
            TowingServiceAddress: this.state.towingServiceAddress,
            RacDeliveryUnixDate: this.state.racDeliveryDate ? String(this.state.racDeliveryDate.unix()) : "",
            RacVehicleSegmentID: this.state.racVehicleSegment?.id,
            RacVehicleGearTypeID: this.state.racVehicleGearType?.id,
            RacVehicleFuelTypeID: this.state.racVehicleFuelType?.id,
            ValetDirectionID: this.state.valetDirection?.id,
            ValetRequestUnixDate: this.state.valetRequestDate ? String(this.state.valetRequestDate.unix()) : "",
            ValetServiceName: this.state.valetServiceName,
            ValetServiceAddress: this.state.valetServiceAddress,
            CallerServiceTypeIDs: selectedServiceTypes,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }

        axios.post("api/caserequest/savecaserequest/", request)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.isSuccess) {
                        if (res.data.data) {
                            NotificationManager.success("Dosya Talebi Başarı İle Oluşturuldu", null, 2000);
                            setTimeout(() => {
                                this.setState({ redirect: "/RequestedCases" });
                            }, 2500)
                        }
                    }
                    else {
                        NotificationManager.error(res.data.message);
                        this.setState({ saveIconClasses: "fa-spin d-none ml-2" });
                        this.setState({ saveBtnDisabled: false });
                    }
                }
                else {
                    NotificationManager.error("Bir Bağlantı Hatası İle Karışılaşıldı");
                    this.setState({ saveIconClasses: "fa-spin d-none ml-2" });
                    this.setState({ saveBtnDisabled: false });
                }
            });
    }

    componentDidMount() {
        this.getTowns();
        this.getCaseRequestCallReasonList();
        this.getCaseRequestServiceTypeList();
        this.getVehicleSegmentList();
        this.getGearTypeList();
        this.getFuelTypeList();
        this.getValetDirectionsList();
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <>
                <Card>
                    <Card.Header>
                        <h5>Sigortalı Sorgulama</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row className="flex-row">
                            <Col xs={12} sm={6} md={4}>
                                <TextInput
                                    name="policyNumber"
                                    faicon={faUser}
                                    label="Poliçe Numarası"
                                    placeholder="Poliçe Numarası"
                                    value={this.state.policyNumber}
                                    handleChange={this.handleTextInputChange}
                                    buttonText="Ara"
                                    buttonEvent={this.getCustomerByPolicyNumber}
                                    btnIsLoading={this.state.searchIsLoading}
                                />
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <TextInput
                                    name="policyHolderName"
                                    faicon={faIdCard}
                                    label="Sigortalı Ad Soyad"
                                    placeholder="Ad Soyad"
                                    value={this.state.policyHolderName}
                                    handleChange={this.handleTextInputChange}
                                    disabled={!this.state.policyManuelEntry}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <TextInput
                                    name="policyHoderPlate"
                                    faicon={faHashtag} label="Plaka"
                                    placeholder="Plaka"
                                    value={this.state.policyHoderPlate}
                                    handleChange={this.handleTextInputChange}
                                    disabled={!this.state.policyManuelEntry}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <TextInput
                                    name="policyHolderProduct"
                                    faicon={faBox} label="Ürün"
                                    placeholder="Ürün"
                                    value={this.state.policyHolderProduct}
                                    handleChange={this.handleTextInputChange}
                                    disabled={!this.state.policyManuelEntry}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <TextInput
                                    name="policyHolderVehicleBrand"
                                    faicon={faCar} label="Araç Marka"
                                    placeholder="Araç Marka"
                                    value={this.state.policyHolderVehicleBrand}
                                    handleChange={this.handleTextInputChange}
                                    disabled={!this.state.policyManuelEntry}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <TextInput
                                    name="policyHolderVehicleModel"
                                    faicon={faCarSide} label="Araç Model"
                                    placeholder="Araç Model"
                                    value={this.state.policyHolderVehicleModel}
                                    handleChange={this.handleTextInputChange}
                                    disabled={!this.state.policyManuelEntry}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <TextInput
                                    name="policyHolderVehicleModelYear"
                                    faicon={faCalendarAlt} label="Araç Model Yılı"
                                    placeholder="Araç Model Yılı"
                                    value={this.state.policyHolderVehicleModelYear}
                                    handleChange={this.handleIntegerTextInputChange}
                                    disabled={!this.state.policyManuelEntry}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <hr/>
                <Card>
                    <Card.Header>
                        <h5>Hizmet Verilecek Kişi Bilgileri</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <TextInput name="callerName" faicon={faIdCard} label="Ad Soyad" placeholder="Ad Soyad" value={this.state.callerName} handleChange={this.handleTextInputChange} />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <TextInput name="callerPhone" faicon={faPhoneAlt} label="Telefon" placeholder="Telefon" value={this.state.callerPhone} handleChange={this.handlePhoneTextInputChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <SelectInput
                                    options={this.state.towns}
                                    handleChange={this.handleTownChange}
                                    label="Şehir"
                                    placeholder="Şehir Seçiniz"
                                    faicon={faMapMarkerAlt}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={!this.state.callerTown?.tid ? null : { value: this.state.callerTown.tid, label: this.state.callerTown.tdescr }}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <SelectInput
                                    options={this.state.townAreas}
                                    handleChange={this.handleTownAreaChange}
                                    label="İlçe"
                                    placeholder="İlçe Seçiniz"
                                    faicon={faMapMarkerAlt}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={!this.state.callerTownArea?.taid ? null : { value: this.state.callerTownArea.taid, label: this.state.callerTownArea.tadescr }}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <TextInput name="callerDistrict" faicon={faMapMarkerAlt} label="Mahalle" placeholder="Mahalle" value={this.state.callerDistrict} handleChange={this.handleTextInputChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <SelectInput
                                    options={this.state.callReasons}
                                    handleChange={this.handleRequestCallReasonChange}
                                    label="Arama Sebebi"
                                    placeholder="Arama Sebebi Seçiniz"
                                    faicon={faPhoneAlt}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={!this.state.callerReasonOfCall?.id ? null : { value: this.state.callerReasonOfCall.id, label: this.state.callerReasonOfCall.callReason }}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <SelectInput
                                    options={this.state.serviceTypes}
                                    handleChange={this.handleRequestServiceTypeChange}
                                    label="Talepler"
                                    placeholder="Talepleri Seçiniz"
                                    faicon={faBoxes}
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    value={this.state.callerServiceTypes}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <label>Açıklama</label>
                                <FormControl
                                    as="textarea"
                                    name = "callerDescription"
                                    className="rounded-0 noresize"
                                    placeholder="Açıklama"
                                    rows={3}
                                    onChange={this.handleTextInputChange}
                                    value={this.state.callerDescription}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <RenderTowingInfo
                    selectedServiceTypes={this.state.callerServiceTypes}
                    handleTextInputChange={this.handleTextInputChange}
                    towingServiceName={this.state.towingServiceName}
                    towingServiceAddress={this.state.towingServiceAddress}
                />
                <RenderRentalInfo
                    selectedServiceTypes={this.state.callerServiceTypes}
                    racDeliveryDate={this.state.racDeliveryDate}
                    racVehicleSegment={this.state.racVehicleSegment}
                    racVehicleGearType={this.state.racVehicleGearType}
                    racVehicleFuelType={this.state.racVehicleFuelType}
                    handleRacDeliveryDateChange={this.handleRacDeliveryDateChange}
                    handleRacVehicleSegmentChange={this.handleRacVehicleSegmentChange}
                    handleRacVehicleGearTypeChange={this.handleRacVehicleGearTypeChange}
                    handleRacVehicleFuelTypeChange={this.handleRacVehicleFuelTypeChange}
                    racVehicleSegments={this.state.racVehicleSegments}
                    racVehicleGearTypes={this.state.racVehicleGearTypes}
                    racVehicleFuelTypes={this.state.racVehicleFuelTypes}
                />
                <RenderValetInfo
                    selectedServiceTypes={this.state.callerServiceTypes}
                    handleTextInputChange={this.handleTextInputChange}
                    valetDirection={this.state.valetDirection}
                    valetRequestDate={this.state.valetRequestDate}
                    valetServiceName={this.state.valetServiceName}
                    valetServiceAddress={this.state.valetServiceAddress}
                    handleValetDirectionChange={this.handleValetDirectionChange}
                    handleValetRequestDateChange={this.handleValetRequestDateChange}
                    valetDirections={this.state.valetDirections}
                />
                <Button variant="primary" className="float-right mt-2" disabled={this.state.saveBtnDisabled} onClick={this.saveCaseRequest} >
                    <FontAwesomeIcon icon={faSave} className="mr-2" />
                    <span>Kaydet ve Gönder</span>
                    <FontAwesomeIcon className={this.state.saveIconClasses} icon={faCircleNotch} />
                </Button>
                <NotificationContainer/>
            </>
        );
    }
}
