import React, { Component } from 'react';

//Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

//DataTable
import DataTable from 'react-data-table-component';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

//moment
import "moment/locale/tr";
import moment from 'moment';


export class ServiceList extends Component {
    static displayName = ServiceList.name;

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.searchData,
            serviceColumns: [
                {
                    cell: row =>
                        <Button variant="primary" onClick={() => this.props.getServiceByID(row.fdid)}>
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                    ,
                    button: true,
                },
                {
                    name: 'Protokol',
                    selector: 'protocolNumber',
                    sortable: true,
                    omit: this.props.isProtocolHidden
                },
                {
                    name: 'Plaka/Kart No',
                    selector: 'customerCarPlate',
                    sortable: true,
                    omit: this.props.isPlateHidden
                },
                {
                    name: 'Servis ID',
                    selector: 'fdid',
                    sortable: true,
                },
                {
                    name: 'Hasar Tipi',
                    selector: 'caseDescription',
                    sortable: true,
                    wrap: true,
                    omit: this.props.isDamageTypeHidden
                },
                {
                    name: 'Hizmet Tipi',
                    selector: 'serviceDescription',
                    sortable: true,
                    wrap: true,
                    omit: this.props.isServiceTypeHidden
                },
                {
                    name: 'Hizmet Tarih',
                    sortable: true,
                    cell: row => <>{moment(row.serviceDate).format('DD.MM.YYYY HH:mm')}</>,
                    selector: row => moment(row.serviceDate).unix(),
                    omit: this.props.isServiceDateHidden
                },
                {
                    name: 'Durum',
                    selector: 'providerServiceStatus',
                    sortable: true,
                    wrap: true,
                    omit: this.props.isProviderStatusHidden
                },
                {
                    name: 'Raxa Durum',
                    selector: 'raxaServiceStatus',
                    sortable: true,
                    wrap: true,
                    omit: this.props.isRaxaStatusHidden
                },
                {
                    name: 'Dosya Numarası',
                    selector: 'caseNumber',
                    sortable: true,
                    wrap: true,
                    omit: this.props.isCaseNumberHidden
                }
            ],
            conditionalRowStyles: [
                {
                    when: row => row.Durum == 3,
                    style: {
                        backgroundColor: 'rgb(255, 87, 51)',
                        color: 'white'
                    },
                },
            ],
        };
    }
    static defaultProps = {
        isProtocolHidden: false,
        isPlateHidden: false,
        isDamageTypeHidden: false,
        isServiceTypeHidden: false,
        isServiceDateHidden: false,
        isProviderStatusHidden: false,
        isRaxaStatusHidden: false,
        isCaseNumberHidden: false
    };

    handleChange(evt) {
        const value = evt.target.value;
        this.setState({ [evt.target.name]: value });
    }
    render() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <Card>
                           
                            <Card.Body>
                                <DataTable
                                    columns={this.state.serviceColumns}
                                    data={this.props.searchData.data.data}
                                    defaultSortField="title"
                                    title="Servisler"
                                    pagination={false}
                                    progressPending={this.props.serviceTableLoading}
                                    conditionalRowStyles={this.state.conditionalRowStyles}
                                    progressComponent={
                                        <>
                                            <h5>Yükleniyor</h5><FontAwesomeIcon className="fa-spin ml-3" icon={faCircleNotch} size="2x" />
                                        </>
                                    }
                                    noDataComponent={
                                        <h5>Veri bulunmamaktadır</h5>
                                    }
                                    highlightOnHover={true}
                                    striped={true}
                                />
                            </Card.Body>
                            <Card.Footer>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
