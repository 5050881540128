import React, { Component } from 'react';
import axios from 'axios';
import { TextInput } from './TextInput';
import { DateInput } from './DateInput';
import { SelectInput } from './SelectInput';


//Bootstrap
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';


//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHashtag } from '@fortawesome/free-solid-svg-icons'
import { faCar } from '@fortawesome/free-solid-svg-icons'
import { faMapMarked } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'



export class HomeRepairSearch extends Component {
    static displayName = HomeRepairSearch.name;

    constructor(props) {
        super(props);
        this.state = {
            incidentStartDate: "",
            incidentEndDate: "",
            fileStartDate: "",
            fileEndDate: "",
            protocolNumber: null,
            carPlate: "",
            selectedTownID: null,
            selectedHomeRepairHRStatus: [],
            selectedHomeRepairApprovals: [],
            selectedInsuranceCompanyID: null,
            searchBtnDisabled: false,
            searchingIconClasses: "fa-spin d-none ml-2",
            towns: [],
            insuranceCompanies: [],
            hrStatuses: [],
            hrApprovals:[],
            searchByIncidentDate: false,
            searchByFileDate: false
        };
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.GetTowns = this.GetTowns.bind(this);
        this.GetInsuranceCompanies = this.GetInsuranceCompanies.bind(this);
        this.GetHomeRepairStatuses = this.GetHomeRepairStatuses.bind(this);
        this.GetHomeRepairApprovals = this.GetHomeRepairApprovals.bind(this);
        this.handleSelectedHRApprovals = this.handleSelectedHRApprovals.bind(this);
        this.handleSelectedHRStatus = this.handleSelectedHRStatus.bind(this);
        this.handleSelectedInsuranceCompanies = this.handleSelectedInsuranceCompanies.bind(this);
        this.handleSelected = this.handleSelected.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.SearchServices = this.SearchServices.bind(this);
    }
    

    handleChange(evt) {
        const value = evt.target.value;
        this.setState({ [evt.target.name]: value });
    }
    handleDate(moment, name) {
        this.setState({ [name]: moment });
    }
    handleSelected(selectedOptions) {
        this.setState({ selectedTownID: selectedOptions.value });
    }
    handleSelectedHRStatus(selectedOptions) {
        this.setState({ selectedHomeRepairHRStatus: selectedOptions });
    }
    handleSelectedInsuranceCompanies(selectedOptions) {
        this.setState({ selectedInsuranceCompanyID: selectedOptions.value });
    }
    handleSelectedHRApprovals(selectedOptions) {
        this.setState({ selectedHomeRepairApprovals: selectedOptions });
    }
    handleCheckboxChange(event) {
        const target = event.target
        const checked = target.checked
        const name = target.name
        this.setState({
            [name]: checked,
        });
    }

    async GetTowns() {
        axios.get("api/parameter/gettowns")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.towns.push({ value: value.tid, label: value.tdescr });
                }
            });
    }

    async GetInsuranceCompanies() {
        axios.get("api/service/getinsurancecompanies")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.insuranceCompanies.push({ value: value.id, label: value.name });
                }
            });
    }

    async GetHomeRepairStatuses() {
        axios.get("api/service/gethomerepairstatuses")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.hrStatuses.push({ value: value.id, label: value.name });
                }
            });
    }

    async GetHomeRepairApprovals() {
        axios.get("api/service/gethomerepairapprovals")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.hrApprovals.push({ value: value.id, label: value.name });
                }
            });
    }

    async GetTowns() {
        axios.get("api/parameter/gettowns")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.towns.push({ value: value.tid, label: value.tdescr });
                }
            });
    }

    async SearchServices() {
        var selectedHomeRepairHRStatus = [];
        if (this.state.selectedHomeRepairHRStatus) {
            for (const [, value] of this.state.selectedHomeRepairHRStatus.entries()) {
                selectedHomeRepairHRStatus.push(value.value);
            }
        }
        var selectedHomeRepairApprovals = [];
        if (this.state.selectedHomeRepairApprovals) {
            for (const [, value] of this.state.selectedHomeRepairApprovals.entries()) {
                selectedHomeRepairApprovals.push(value.value);
            }
        }

        var request = {
            ProtocolNumber:isNaN(this.state.protocolNumber) ? null : parseInt(this.state.protocolNumber),
            InsuranceFileNo: this.state.insuranceFileNo,
            InsuranceCustomerName: this.state.insCustomerName,
            IPARef:this.state.ipaRef,
            UnixIncidentStartDate: this.state.incidentStartDate === "" ? "" : String(this.state.incidentStartDate.unix()),
            UnixIncidentEndDate: this.state.incidentEndDate === "" ? "" : String(this.state.incidentEndDate.unix()),
            UnixFileStartDate: this.state.fileStartDate === "" ? "" : String(this.state.fileStartDate.unix()),
            UnixFileEndDate: this.state.fileEndDate === "" ? "" : String(this.state.fileEndDate.unix()),
            SearchByFileDate: this.state.searchByFileDate,
            SearchByIncidentDate:this.state.searchByIncidentDate,
            SelectedTownId: this.state.selectedTownID,
            SelectedInsuranceCompanyID: this.state.selectedInsuranceCompanyID,
            SelectedHRStatuses: selectedHomeRepairHRStatus,
            SelectedHRApprovals: selectedHomeRepairApprovals,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        };

        this.props.searchCallback(request);
    }

    componentDidMount() {
        this.GetTowns();
        this.SearchServices();
        this.GetInsuranceCompanies();
        this.GetHomeRepairStatuses();
        this.GetHomeRepairApprovals();
    }

    render() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header>
                                <h4>Konut Dosya Arama</h4>
                            </Card.Header>
                            <Card.Body>
                                <Form.Group className="mb-0">
                                    <Row>
                                        <Col xs={12} sm={3} md={3}>
                                            <Form.Check name="searchByIncidentDate" label="İhbar Tarihi ile Ara" onChange={this.handleCheckboxChange}/>
                                            <DateInput
                                                timeFormat={false}
                                                label="İhbar Tarihi Başlangıç"
                                                placeholder="İhbar Tarihi Başlangıç"
                                                handleChange={this.handleDate}
                                                stateName="incidentStartDate"
                                                disabled={!this.state.searchByIncidentDate}
                                            />
                                        </Col>
                                        <Col xs={12} sm={3} md={3} className="mt-4">
                                            <DateInput
                                                timeFormat={false}
                                                label="İhbar Tarihi Bitiş"
                                                placeholder="İhbar Tarihi Bitiş"
                                                handleChange={this.handleDate}
                                                stateName="incidentEndDate"
                                                disabled={!this.state.searchByIncidentDate}
                                            />
                                        </Col>
                                        <Col xs={12} sm={3} md={3}>
                                            <Form.Check name="searchByFileDate" label="Dosya Tarihi ile Ara" onChange={this.handleCheckboxChange} />
                                            <DateInput
                                                timeFormat={false}
                                                label="Dosya Tarihi Başlangıç"
                                                placeholder="Dosya Tarihi Başlangıç"
                                                handleChange={this.handleDate}
                                                stateName="fileStartDate"
                                                disabled={!this.state.searchByFileDate}
                                            />
                                        </Col>
                                        <Col xs={12} sm={3} md={3} className="mt-4">
                                            <DateInput
                                                timeFormat={false}
                                                label="Dosya Tarihi Biti"
                                                placeholder="Dosya Tarihi Bitiş"
                                                handleChange={this.handleDate}
                                                stateName="fileEndDate"
                                                disabled={!this.state.searchByFileDate}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6} md={3}>
                                            <TextInput
                                                faicon={faHashtag}
                                                name="protocolNumber"
                                                label="Protokol Numarası"
                                                placeholder="Protokol Numarası"                                                
                                                handleChange={this.handleChange}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3}>
                                            <TextInput
                                                faicon={faHashtag}
                                                name="ipaRef"
                                                label="IPA Ref"
                                                placeholder="IPA Ref"
                                                handleChange={this.handleChange}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3}>
                                            <TextInput
                                                faicon={faHashtag}
                                                name="insuranceFileNo"
                                                label="Sigorta Dosya No"
                                                placeholder="Sigorta Dosya No"
                                                handleChange={this.handleChange}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3}>
                                            <TextInput
                                                faicon={faHashtag}
                                                name="insCustomerName"
                                                label="Sigortalı Adı"
                                                placeholder="Sigortalı Adı"
                                                handleChange={this.handleChange}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3}>
                                            <SelectInput
                                                options={this.state.towns}
                                                handleChange={this.handleSelected}
                                                faicon={faMapMarked}
                                                label="Şehir"
                                                placeholder="Şehir Seçiniz"
                                                isMulti={false}
                                                closeMenuOnSelect={true}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3}>
                                            <SelectInput
                                                options={this.state.hrStatuses}
                                                handleChange={this.handleSelectedHRStatus}
                                                faicon={faMapMarked}
                                                label="Statü"
                                                placeholder="Statü Seçiniz"
                                                isMulti={true}
                                                closeMenuOnSelect={false}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3}>
                                            <SelectInput
                                                options={this.state.hrApprovals}
                                                handleChange={this.handleSelectedHRApprovals}
                                                faicon={faMapMarked}
                                                label="Onarım Onay Durumu"
                                                placeholder="Onay Durumu Seçiniz"
                                                isMulti={true}
                                                closeMenuOnSelect={false}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3}>
                                            <SelectInput
                                                options={this.state.insuranceCompanies}
                                                handleChange={this.handleSelectedInsuranceCompanies}
                                                faicon={faMapMarked}
                                                label="Sigorta Şirketi"
                                                placeholder="Sigorta Şirketi Seçiniz"
                                                isMulti={false}
                                                closeMenuOnSelect={true}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Card.Body>
                            <Card.Footer>
                                <Button variant="primary" disabled={this.state.searchBtnDisabled} onClick={() => this.SearchServices()} >
                                    <FontAwesomeIcon icon={faSearch} className="mr-2" />
                                    <span>Arama Yap</span>
                                    <FontAwesomeIcon className={this.state.searchingIconClasses} icon={faCircleNotch} />
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
