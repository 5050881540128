import React, { Component } from 'react';
import axios from 'axios';
import { ServiceSearch } from './ServiceSearch';
import { ServiceList } from './ServiceList';
import { ServiceDetail } from './ServiceDetail';

//Bootstrap
import Modal from 'react-bootstrap/Modal';

export class UnprocessedServices extends Component {
    static displayName = UnprocessedServices.name;

    constructor(props) {
        super(props);
        this.state = {
            searchData: { data: { data: [] } },
            serviceData: { data: { data: [] } },
            showServiceDetailModal: false,
            serviceTalbeLoading: false,
            ref: React.createRef()
        };
        this.handleserviceDetailModalClose = this.handleserviceDetailModalClose.bind(this);
        this.handleSearchCallback = this.handleSearchCallback.bind(this);
        this.searchServices = this.searchServices.bind(this);
        this.getServiceByID = this.getServiceByID.bind(this);
    }

    handleSearchCallback(childData){
        this.setState({ serviceTalbeLoading: true});
        if (childData.data.data != null)
            this.setState({ searchData: childData }, () => this.setState({ serviceTalbeLoading: false }))
        else
            this.setState({ searchData: { data: { data: [] } } }, () => this.setState({ serviceTalbeLoading: false }))
    }
    handleserviceDetailModalClose() {
        this.setState({ showServiceDetailModal: false });
        this.state.ref.current.SearchServices();
    }
    handleserviceDetailModalOpen() {
        this.setState({ showServiceDetailModal: true });
    }

    async searchServices(request) {
        axios.post("api/service/getservicesbyrequest", request)
            .then(res => {
                this.handleSearchCallback(res);
                //Implement error handling
            });
    }
    async getServiceByID(serviceID) {
        var request = {
            ServiceID: serviceID,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/service/getservicebyrequest/", request)
            .then(res => {
                if (res.data.data != null)
                    this.setState({ serviceData: res })
                this.handleserviceDetailModalOpen();
                //Implement error handling
            });
    }
   
    render() {
        return (
            <>
                <div className="noPrint">
                <ServiceSearch searchCallback={this.searchServices} ref={this.state.ref} />
                <ServiceList
                    searchData={this.state.searchData}
                    serviceDetailCallback={this.handleServiceDetailCallback}
                    serviceTalbeLoading={this.state.serviceTalbeLoading}
                    getServiceByID={this.getServiceByID}
                    isCaseNumberHidden={true}
                />
                <Modal dialogClassName="modal-90w" show={this.state.showServiceDetailModal} onHide={() => this.handleserviceDetailModalClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Servis Detayı</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ServiceDetail {...this.props} service={this.state.serviceData} closeModal={this.handleserviceDetailModalClose} />
                    </Modal.Body>
                </Modal>
                </div>
            </>
        );
    }
}
