import React, { Component } from 'react';
import axios from 'axios';
//Custom Components
import { LabeledData } from './LabeledData';
import { TextInput } from './TextInput';
import { SelectInput } from './SelectInput';
import { DateInput } from './DateInput';
//Bootstrap
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import FormControl from 'react-bootstrap/FormControl';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
//React-Datetime
import "react-datetime/css/react-datetime.css";
import "moment/locale/tr";
import moment from 'moment';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { faHashtag } from '@fortawesome/free-solid-svg-icons'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faShare } from '@fortawesome/free-solid-svg-icons'
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'



import { faCar } from '@fortawesome/free-solid-svg-icons'
import { faMapMarked } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
//DataTable
import DataTable from 'react-data-table-component';
//Image Resizer
import Resizer from 'react-image-file-resizer';
//FullCalendar
import FullCalendar, { CalendarApi, formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';
//React-Notification
import { NotificationContainer, NotificationManager } from 'react-notifications';


export class HomeRepairDetail extends Component {
    static displayName = HomeRepairDetail.name;
    calendarRef = React.createRef();
    materialRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            homeRepairCaseFilesLoaded:false,
            fullCalendarSelect: true,
            sendRepsToIPADisabled: false,
            saveRepsButtonDisabled: false,
            homeRepairIsInitialCallNote: false,
            searchingIconClasses: "fa-spin d-none ml-2",
            serviceData: {},
            homeRepairAgendaTypes: [],
            columnsRepairmentsPrint: [
                {
                    name: 'Yapılan İşin / Kullanılan Malzemenin ADI',
                    cell: row => <span>{row.groupName ? row.groupName.toUpperCase() : row.material}</span>,
                    wrap: true
                },
                {
                    name: 'Malzeme Detay',
                    selector: 'materialDetail',
                    sortable: false,
                    wrap: true,
                },
                {
                    name: 'Mahal',
                    selector: 'room',
                    sortable: false,
                    wrap: true,
                },
                {
                    name: 'Miktar',
                    selector: 'amount',
                    sortable: false,
                    wrap: true,
                },
                {
                    name: 'Birim',
                    selector: 'unit',
                    sortable: false,
                    wrap: true,
                },
                {
                    name: 'Birim Fiyat',
                    selector: 'pricePerUnit',
                    sortable: false,
                    wrap: true,
                },
                {
                    name: 'Toplam',
                    selector: 'totalPrice',
                    sortable: false,
                    wrap: true,
                },
                {
                    name: 'Kalem Toplamı',
                    selector: 'groupTotal',
                    sortable: false,
                    wrap: true,
                    cell: row => <span>{row.groupTotal > 0 ? row.groupTotal : ''}</span>,
                },

            ],
            columns: [
                {
                    name: 'Not',
                    selector: 'note',
                    sortable: false,
                    wrap: true,
                    width: '60%',//
                },
                {
                    cell: row => <span>{row.createUser == null ? row.createIpaEksperUser.userName : row.createUser.displayName}, <span className="text-right">
                    {new Intl.DateTimeFormat("tr-TR", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: false
                    }).format(moment(row.recordDate))
                        }</span></span>,
                    wrap: true
                }
            ],
            columnsRepairment: [
                {
                    name: 'Onarım Tipi',
                    selector: 'homeRepairRepairmentType.name',
                    sortable: false,
                    wrap: true
                },
                {
                    name: 'Malzeme',
                    selector: 'homeRepairMaterial.name',
                    sortable: false,
                    wrap: true
                },
                {
                    name: 'Mahal',
                    //selector: 'homeRepairRoom.name',
                    cell: row => <span>{row.homeRepairRoom?.name}</span>,
                    sortable: false,
                    wrap: true
                },
                {
                    name: 'Birim',
                    selector: 'homeRepairMaterialUnitType.name',
                    sortable: false,
                    wrap: true
                },
                {
                    name: 'Miktar',
                    selector: 'materialAmount',
                    sortable: false,
                    wrap: true
                },
                {
                    name: 'Birim Fiyat',
                    selector: 'materialPricePerUnit',
                    sortable: false,
                    wrap: true
                },
                {
                    name: 'Toplam Fiyat',
                    selector: 'totalPrice',
                    sortable: false,
                    wrap: true
                },
                {
                    cell: row => <span>{row.createUser == null ? row.createIPAEksperUser?.userName : row.createUser?.displayName}</span>,
                    wrap: true
                },
                {
                    cell: row => <Button variant="warning" onClick={() => this.handleUpdateRepairment(row)}>Güncelle</Button>,
                    /*wrap: true,
                    ignoreRowClick: true,*/
                    button: true
                },
                {
                    cell: row => <Button variant="danger" onClick={() => this.handleDeleteRepairment(row)}>Sil</Button>,
                    /*wrap: true,
                    ignoreRowClick: true,*/
                    button: true
                }
            ],
            conditionalRowStyles: [
                {
                    when: row => row.user == null,
                    style: {
                        backgroundColor: 'rgb(2, 117, 216)',
                        color: 'white'
                    },
                },
                {
                    when: row => row.user != null,
                    style: {
                        backgroundColor: 'rgb(92, 184, 92)',
                        color: 'white'
                    },
                },
            ],
            conditionalHomeRepairRowStyles: [

                {
                    when: row => row.createUser == null,
                    style: {
                        backgroundColor: 'rgb(2, 117, 216)',
                        color: 'white'
                    },
                },
                {
                    when: row => row.createUser != null,
                    style: {
                        backgroundColor: 'rgb(92, 184, 92)',
                        color: 'white'
                    },
                },
                {
                    when: row => row.isInitialCallNote,
                    style: {
                        backgroundColor: 'orange',
                        color: 'white'
                    },
                },

            ],
            conditionalHomeRepairRepairmentRowStyles: [
                {
                    when: row => row.createUser == null,
                    style: {
                        backgroundColor: 'rgb(2, 117, 216)',
                        color: 'white'
                    },
                },
                {
                    when: row => row.createUser != null,
                    style: {
                        backgroundColor: 'rgb(92, 184, 92)',
                        color: 'white'
                    },
                },
            ],
            saveIconClasses: "fa-spin d-none ml-2",
            searchBtnDisabled: false,
            racGroups: [],
            racGearTypes: [],
            racBrands: [],
            racModels: [],
            providerVehicleInventoryList: [],
            providerStaffInventoryList: [],
            photosPreview: [],
            homeRepairFilesPreview: [],
            homeRepairFiles: [],
            homeRepairNoteToSave: "",
            homeRepairSelectedAgendaItem: { id: 0, agendaType: {} },
            homeRepairSelectedRepairment: {
                id: 0,
                repairmentType: {}
            },
            homeRepairRepairmentTypes: [],
            homeRepairMaterials: [],
            homeRepairRooms: [],
            homeRepairUnits: [],
            homeRepairCaseFileTypes: [],
            printableRepairments: [],
            showRepairmentsDetailsModal: false
        };
        this.handleHomeRepairNoteChange = this.handleHomeRepairNoteChange.bind(this);
        this.handleHomeRepairRepairmentNoteChange = this.handleHomeRepairRepairmentNoteChange.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.getAgendaItemTypes = this.getAgendaItemTypes.bind(this);
        this.handleEventClick = this.handleEventClick.bind(this);
        this.handleHomeRepairAgendaItemDescription = this.handleHomeRepairAgendaItemDescription.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.handleSelected = this.handleSelected.bind(this);
        this.gethomeRepairRepairmentTypes = this.gethomeRepairRepairmentTypes.bind(this);
        this.gethomeRepairRooms = this.gethomeRepairRooms.bind(this);
        this.gethomeRepairUnits = this.gethomeRepairUnits.bind(this);
        this.gethomeRepairMaterialsByRepairmentType = this.gethomeRepairMaterialsByRepairmentType.bind(this);
        this.handleSelectedMaterial = this.handleSelectedMaterial.bind(this);
        this.handleSelectedUnit = this.handleSelectedUnit.bind(this);
        this.handleSelectedRepairmentType = this.handleSelectedRepairmentType.bind(this);
        this.handleSelectedRoom = this.handleSelectedRoom.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handlePricePerUnitChange = this.handlePricePerUnitChange.bind(this);
        this.handleTotalPriceChange = this.handleTotalPriceChange.bind(this);
        this.handleMaterialDetailChange = this.handleMaterialDetailChange.bind(this);
        this.handleUpdateRepairment = this.handleUpdateRepairment.bind(this);
        this.calculateTotalPrice = this.calculateTotalPrice.bind(this);
        this.handleRepairmentsDetailModalClose = this.handleRepairmentsDetailModalClose.bind(this);
        this.sendRepairmentsToIPA = this.sendRepairmentsToIPA.bind(this);
        this.toggleIsHRInitialCallNote = this.toggleIsHRInitialCallNote.bind(this);
        this.handleHomeRepairFileUpload = this.handleHomeRepairFileUpload.bind(this);
        this.refreshComponent = this.refreshComponent.bind(this);
        this.gethomeRepairCaseFileTypes = this.gethomeRepairCaseFileTypes.bind(this);
        this.handleSelectedCaseFileType = this.handleSelectedCaseFileType.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.gethomeRepairCaseFiles = this.gethomeRepairCaseFiles.bind(this);
        //getFile
    }

    //Event Handlers

    handleHomeRepairFileUpload(evt) {
        if (!this.state.homeRepairSelectedCaseFileType) {
            NotificationManager.error("Lütfen önce Dosya Tipini Seçiniz");
            return;
        }
        var fileInput = false
        if (evt.target.files[0]) {
            fileInput = true
        }
        var file = evt.target.files[0];
        if(this.isFileImage(file))
            Resizer.imageFileResizer(
                file,
                1600,
                1600,
                'JPEG',
                100,
                0,
                uri => {
                    file["base64"] = uri;
                    this.uploadHomeRepairFile(file);
                }
            );
        else
            this.getBase64(file)
                .then(result => {
                    file["base64"] = result;
                    this.uploadHomeRepairFile(file);
                    this.setState({
                        base64URL: result,
                        file
                    });

                })
                .catch(err => {
                    console.log(err);
                });
        //var base64FileString = this.getBase64(file);
        //console.log("base64 String : ", base64FileString);
        evt.target.value = null;

    }

    isFileImage(file) {
        return file && file['type'].split('/')[0] === 'image';
    }

    getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                
                baseURL = reader.result;
               
                resolve(baseURL);
            };
            
        });
    }
    //homeRepairEvents
    handleHomeRepairNoteChange(evt) {
        this.setState({ homeRepairNoteToSave: evt.target.value })
    }
    handleHomeRepairRepairmentNoteChange(evt) {
        this.setState({ serviceData: { ...this.state.serviceData, repairNotes: evt.target.value } })
    }
    handleTabChange(eventKey) {
        this.setState({ fullCalendarSelect: true })
        if (eventKey === "homeRepairFiles" && !this.state.homeRepairCaseFilesLoaded) {
            this.setState({homeRepairCaseFilesLoading: true})
            this.gethomeRepairCaseFiles();
        }
            
    }
    handleEventClick(arg) {
        var selectedEvent = {
            agendaDate: arg.event.start,
            id: arg.event.extendedProps.itemId,
            description: arg.event.extendedProps.description,
            agendaType: arg.event.extendedProps.agendaType?.name ? {
                label: arg.event.extendedProps.agendaType.name,
                value: arg.event.extendedProps.agendaType.id
            } : null
        }
        this.setState({ homeRepairSelectedAgendaItem: selectedEvent })

    }

    handleDayClick() {
        this.setState({ homeRepairSelectedAgendaItem: { id: 0, agendaType: {}, description :'' }})
    }

    toggleIsHRInitialCallNote() {
        var value = this.state.homeRepairIsInitialCallNote;
        this.setState({ homeRepairIsInitialCallNote: !value });
    }

    handleHomeRepairAgendaItemDescription(evt) {
        this.setState({
            homeRepairSelectedAgendaItem: { ...this.state.homeRepairSelectedAgendaItem, description: evt.target.value }
        });
    }
    handleDate(moment) {
        this.setState({ homeRepairSelectedAgendaItem: { ...this.state.homeRepairSelectedAgendaItem, agendaDate: moment } })
    }
    handleSelected(selectOption) {
        this.setState({ homeRepairSelectedAgendaItem: { ...this.state.homeRepairSelectedAgendaItem, agendaType: selectOption } })
    }
    handleSelectedMaterial(selectOption) {
        this.setState({ homeRepairSelectedRepairment: { ...this.state.homeRepairSelectedRepairment, material: selectOption } })
    }
    handleSelectedCaseFileType(selectOption) {
        this.setState({ homeRepairSelectedCaseFileType: selectOption });
        
    }
    handleSelectedRepairmentType(selectOption) {
        this.setState({ homeRepairSelectedRepairment: { ...this.state.homeRepairSelectedRepairment, repairmentType: selectOption, material :null },homeRepairMaterials: [],  }, this.gethomeRepairMaterialsByRepairmentType)
    }
    handleSelectedUnit(selectOption) {
        this.setState({ homeRepairSelectedRepairment: { ...this.state.homeRepairSelectedRepairment, unit: selectOption } })
    }
    handleSelectedRoom(selectOption) {
        this.setState({ homeRepairSelectedRepairment: { ...this.state.homeRepairSelectedRepairment, room: selectOption } })
    }
    handleAmountChange(evt) {
        this.setState({ homeRepairSelectedRepairment: { ...this.state.homeRepairSelectedRepairment, amount: evt.target.value } }, this.calculateTotalPrice);
    }
    handlePricePerUnitChange(evt) {
        this.setState({ homeRepairSelectedRepairment: { ...this.state.homeRepairSelectedRepairment, pricePerUnit: evt.target.value } }, this.calculateTotalPrice);
    }
    handleTotalPriceChange(evt) {
        this.setState({ homeRepairSelectedRepairment: { ...this.state.homeRepairSelectedRepairment, totalPrice: evt.target.value } });
    }
    handleMaterialDetailChange(evt) {
        this.setState({ homeRepairSelectedRepairment: { ...this.state.homeRepairSelectedRepairment, materialDetail: evt.target.value } });
    }
    handleDeleteRepairment(row) {
        if (window.confirm('Silmek istediğinize emin misiniz?')) {
            var selectedEvent = {
                /*repairmentType: {
                    label: row.homeRepairRepairmentType.name,
                    value: row.homeRepairRepairmentType.id
                },
                material: {
                    label: row.homeRepairMaterial.name,
                    value: row.homeRepairMaterial.id
                },
                room:  {
                    label: row.homeRepairRoom.name,
                    value: row.homeRepairRoom.id
                },
                unit: {
                    label: row.homeRepairMaterialUnitType.name,
                    value: row.homeRepairMaterialUnitType.id
                },*/
                id: row.id,
                /*amount: row.materialAmount,
                pricePerUnit: row.materialPricePerUnit,
                totalPrice: row.totalPrice,
                materialDetail: row.materialDetail,*/
                isActive: false,
                UserID: parseInt(window.sessionStorage.getItem("userID")),
                UserAccessToken: window.sessionStorage.getItem("userToken"),
                ServiceID: this.state.serviceData.id
            };
            axios.post("api/service/deletehomerepairrepairment/", selectedEvent)
                .then(res => {
                    if (res.data.data) {
                        //alert("Onarım Silinmiştir.");
                        NotificationManager.success("Onarım Silinmiştir");
                        this.setState({
                            searchBtnDisabled: false,
                            homeRepairSelectedRepairment: {
                                id: 0,
                                repairmentType: {}
                            },
                            refresh:true
                        },this.refreshComponent);
                    }
                    else {
                        //alert(res.data.message);
                        NotificationManager.error(res.data.message);
                        this.setState({
                            searchBtnDisabled: false,
                            saveIconClasses: "fa-spin d-none ml-2",
                            homeRepairSelectedRepairment: {
                                id: 0,
                                repairmentType: {}
                            }
                        });
                    }
                }).catch(e => {
                    //console.log(e);
                    NotificationManager.error("Bir hata oluştu");
                })
        }
    }
    handleUpdateRepairment(row) {
        var selectedEvent = {
            repairmentType: {
                label: row.homeRepairRepairmentType.name,
                value: row.homeRepairRepairmentType.id
            },
            material: {
                label: row.homeRepairMaterial.name,
                value: row.homeRepairMaterial.id
            },
            room: row.homeRepairRoom ? {
                label: row.homeRepairRoom.name,
                value: row.homeRepairRoom.id
            } : null,
            unit: {
                label: row.homeRepairMaterialUnitType.name,
                value: row.homeRepairMaterialUnitType.id
            },
            id: row.id,
            amount: row.materialAmount.replace(',', '.'),
            pricePerUnit: row.materialPricePerUnit.replace(',', '.'),
            totalPrice: row.totalPrice.replace(',', '.'),
            materialDetail: row.materialDetail
        };
        this.setState({ homeRepairSelectedRepairment: selectedEvent })

    }
    calculateTotalPrice() {
        var amnt = this.state.homeRepairSelectedRepairment.amount?.replace(',', '.');
        var ppu = this.state.homeRepairSelectedRepairment.pricePerUnit?.replace(',', '.');
        if (!amnt || !ppu)
            return;
        if (isNaN(ppu) || isNaN(amnt)) {
            //alert('')
            NotificationManager.error("Birim fiyat ve Miktar alanlarına lütfen geçerli değerler giriniz!");
            return false;
        }

        axios.get("api/service/calculaterepairmenttotalprice?amount=" + amnt + "&pricePerUnit=" + ppu)
            .then(res => {
                if (res.data.data) {
                    this.setState({ homeRepairSelectedRepairment: { ...this.state.homeRepairSelectedRepairment, totalPrice: res.data.data } });
                }
                else {
                    //alert(res.data.message);
                    NotificationManager.error(res.data.message);
                    this.setState({ saveIconClasses: "fa-spin d-none ml-2" })
                    this.setState({ searchBtnDisabled: false });
                }
            });
    }
    renderPrintRepairments() {
        return (
            <React.Fragment>
                <div class="row mb-2 border border-gray rounded">
                    <div class="col-lg-4">
                        <div class="d-lg-flex mb-2 pt-1">

                            <span class="text">ASİSTANS HASAR ONARIM FORMU</span>
                        </div>
                    </div>
                </div>
                <div>
                    <table border="1" style={{ width: "70%" }}>
                        <tr>
                            <td>DOSYA NO</td>
                            <td>{this.state.serviceData?.mainCase?.caseProductOwner?.platePolicyNumber}</td>
                        </tr>
                        <tr>
                            <td>SİGORTALI</td>
                            <td>{this.state.serviceData?.mainCase?.caseProductOwner ? this.state.serviceData.mainCase.caseProductOwner.name : ''}</td>
                        </tr>
                        <tr>
                            <td>ONARIM ADRESİ</td>
                            <td>
                                {this.state.serviceData.mainCase?.sceneAdress}
                            </td>
                        </tr>
                    </table>
                </div>
                <DataTable
                    columns={this.state.columnsRepairmentsPrint}
                    data={this.state.printableRepairments}
                    noDataComponent={
                        <h5>Veri bulunmamaktadır</h5>
                    }

                    highlightOnHover={true}
                    striped={true}
                />

                <br />

                <div className="table-responsive">
                    <table className="table">
                    <tr>
                        <td>

                            <table border="1">
                                <tr>
                                    <td>ASİSTANS FİRMASI</td>
                                    <td>INTER PARTNERS ASSISTANCE</td>
                                </tr>
                                <tr>
                                    <td>KAŞE / İMZA</td>
                                    <td>{this.state.serviceData.specialistUser?.displayName}</td>
                                </tr>
                            </table>


                        </td>
                        <td>
                                <div className="d-flex justify-content-end">

                                <table border="1">
                                    <tr>
                                        <td>TOPLAM</td>
                                        <td>{this.state.repairmentsTotal}</td>
                                    </tr>
                                    <tr>
                                        <td>KDV (%20) </td>
                                        <td>{this.state.repairmentsVat}</td>
                                    </tr>
                                    <tr>
                                        <td>GENEL TOPLAM</td>
                                        <td>{this.state.repairmentsSum}</td>
                                    </tr>
                                </table>


                            </div>

                        </td>
                    </tr>
                </table>
                </div>
                <br />


                <div class="row">
                    <span>NOT/AÇIKLAMA :</span>
                    {this.state.serviceData?.repairNotes}
                </div>

            </React.Fragment>
        );

    }
    handleRepairmentsDetailModalClose() {
        this.setState({ showRepairmentsDetailsModal: false });
    }


    //API Calls

    async uploadHomeRepairFile(file) {
        var request = {
            CaseFile: file.base64,
            CaseID: this.state.serviceData.mainCase.caseID,
            ServiceID: this.state.serviceData.id,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken"),
            CaseFileType: { ID: this.state.homeRepairSelectedCaseFileType.value },
            FileName: file.name,
            Description: file.name,
            //CreatedUserID: /*(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 2 :*/ 153,
            CreatedIPAEksperUserID: parseInt(window.sessionStorage.getItem("userID"))
        }
        axios.post("api/service/homerepairfileupload/", request)
            .then(res => {
                console.log("res",res);
                if (res.data.data) {
                    //alert("Dosya yüklendi.");
                    NotificationManager.success("Dosya yüklendi");
                    this.setState({ homeRepairCaseFilesLoading: true, homeRepairCaseFilesLoaded: false }, this.gethomeRepairCaseFiles);
                }
                else {
                    //alert(res.data.message);
                    NotificationManager.error(res.data.message);
                }
            });
    }

    async getAgendaItemTypes(apiRoot) {
        axios.get("api/service/gethomerepairagendatypes")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.homeRepairAgendaTypes.push({ value: value.id, label: value.name });
                }
            });
    }
    async gethomeRepairRepairmentTypes(apiRoot) {
        axios.get("api/service/gethomerepairrepairmenttypes")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.homeRepairRepairmentTypes.push({ value: value.id, label: value.name });
                }
            });
    }
    async gethomeRepairRooms(apiRoot) {
        axios.get("api/service/gethomerepairrooms")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.homeRepairRooms.push({ value: value.id, label: value.name });
                }
            });
    }

    async gethomeRepairCaseFiles(apiRoot) {

        var request = {
            ID: this.state.serviceData.mainCase.caseID,
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/service/gethomerepaircasefilesbyrequest",request)
            .then(res => {
                if (res.data.data)
                    this.setState({
                        ...this.state, serviceData: { ...this.state.serviceData, mainCase: { ...this.state.serviceData.mainCase, caseFiles: res.data.data } }
                    })
                return res;
            })
            .finally(res => {
                this.setState({ homeRepairCaseFilesLoading: false, homeRepairCaseFilesLoaded: true });
            });
        
    }
    async gethomeRepairUnits(apiRoot) {
        axios.get("api/service/gethomerepairunits")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.homeRepairUnits.push({ value: value.id, label: value.name });
                }
            });
    }
    async gethomeRepairCaseFileTypes(apiRoot) {
        axios.get("api/service/getcasefiletypesforprovider")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.homeRepairCaseFileTypes.push({ value: value.id, label: value.typeName });
                }
            });
    }
    async gethomeRepairMaterialsByRepairmentType(apiRoot) {
        var newTypes = [];
        //this.setState({homeRepairMaterials : []})
        axios.get("api/service/gethomerepairmaterialsbytypeid?repairmentTypeID=" + this.state.homeRepairSelectedRepairment?.repairmentType?.value)
            .then(res => {  
                for (const [, value] of res.data.data.entries()) {
                    newTypes.push({ value: value.id, label: value.name });
                }
                this.setState({ homeRepairMaterials: newTypes });
            });
    }
    async saveHomeRepairNote() {
        if (!this.state.homeRepairNoteToSave) {
            NotificationManager.error("Not girilmedi");
            return;
        }
        var request = {
            HomeRepairCase: { ID: this.state.serviceData?.id },
            Note: this.state.homeRepairNoteToSave,
            IsActive: true,
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken"),
            ServiceID: this.state.serviceData.id,
            IsInitialCallNote: this.state.homeRepairIsInitialCallNote
        }
        axios.post("api/service/savehomerepairnote/", request)
            .then(res => {
                if (res.data.data) {
                    //alert("Not Kaydedildi.");
                    NotificationManager.success("Not kaydedildi");
                    this.setState({ refresh: true }, this.refreshComponent);
                }
                else {
                    //alert();
                    NotificationManager.error(res.data.message);
                    this.setState({ saveIconClasses: "fa-spin d-none ml-2" })
                    this.setState({ searchBtnDisabled: false });
                }
            }).catch(e => {
                NotificationManager.error("Bir hata oluştu.");
            })
    }

    async saveHomeRepairRepairmentNote() {
        if (!this.state.serviceData.repairNotes) {
            NotificationManager.error("Onarım Notu girilmedi");
            return;
        }
        var request = {
            ID: this.state.serviceData?.id ,
            RepairNotes: this.state.serviceData?.repairNotes,
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken"),
            ServiceID: this.state.serviceData.id,
            
        }
        axios.post("api/service/savehomerepairrepairmentnote/", request)
            .then(res => {
                if (res.data.data) {
                    //alert("Not Kaydedildi.");
                    NotificationManager.success("Not kaydedildi");
                    this.setState({ refresh: true }, this.refreshComponent);
                }
                else {
                    //alert();
                    NotificationManager.error(res.data.message);
                    this.setState({ saveIconClasses: "fa-spin d-none ml-2" })
                    this.setState({ searchBtnDisabled: false });
                }
            }).catch(e => {
                NotificationManager.error("Bir hata oluştu.");
            })
    }


    async saveHomeRepairAgendaItem() {
        if (!this.state.homeRepairSelectedAgendaItem.agendaDate) {
            //alert('.')
            NotificationManager.error("Gidiş Tarihi Saati girilmedi");
            return;
        }
        if (!this.state.homeRepairSelectedAgendaItem.description) {
            //alert('Açıklama girilmedi.')
            NotificationManager.error("Açıklama girilmedi");
            return;
        }

        var request = {
            ID: this.state.homeRepairSelectedAgendaItem.id,
            HomeRepairCase: { ID: this.state.serviceData?.id },
            AgendaType: this.state.homeRepairSelectedAgendaItem.agendaType.value ? { ID: this.state.homeRepairSelectedAgendaItem.agendaType.value } : null,
            AgendaUnixDate: String(moment(this.state.homeRepairSelectedAgendaItem.agendaDate).unix()),
            Description: this.state.homeRepairSelectedAgendaItem.description,
            IsActive: true,
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            CreateIpaEksperUser: { ID: parseInt(window.sessionStorage.getItem("userID")) },
            UserAccessToken: window.sessionStorage.getItem("userToken"),
            ServiceID: this.state.serviceData.id
        }
        axios.post("api/service/savehomerepairagendaitem/", request)
            .then(res => {
                if (res.data.data) {
                    //alert("Ajanda Kaydedilmiştir.")
                    NotificationManager.success("Ajanda Kaydedilmiştir");
                    this.setState({ refresh: true }, this.refreshComponent);
                }
                else {
                    //alert(res.data.message);
                    NotificationManager.error(res.data.message);
                    this.setState({ saveIconClasses: "fa-spin d-none ml-2" })
                    this.setState({
                        searchBtnDisabled: false,
                        homeRepairSelectedAgendaItem: {
                            id: 0,
                            agendaType: {}
                        }
                    });
                }
            }).catch(e => { console.log(e) })
    }
    async saveHomeRepairRepairment() {
        if (!this.state.homeRepairSelectedRepairment.repairmentType.value) {
            //alert('Onarım Tipi girilmedi');
            NotificationManager.error("Onarım Tipi girilmedi");
            return;
        }
        if (!this.state.homeRepairSelectedRepairment.material) {
            //alert('Malzeme girilmedi');
            NotificationManager.error("Malzeme girilmedi");
            return;
        }
        if (!this.state.homeRepairSelectedRepairment.amount /*|| isNaN(this.state.homeRepairSelectedRepairment.amount)*/) {
            //alert('');
            NotificationManager.error("Miktar girilmedi veya hatalı girildi");
            return;
        }
        if (!this.state.homeRepairSelectedRepairment.pricePerUnit /*|| isNaN(this.state.homeRepairSelectedRepairment.pricePerUnit)*/) {
            //alert('');
            NotificationManager.error("Birim Fiyat girilmedi veya hatalı girildi");
            return;
        }
        if (!this.state.homeRepairSelectedRepairment.unit) {
            //alert('Birim girilmedi');
            NotificationManager.error("Birim girilmedi");
            return;
        }
        var request = {
            ID: this.state.homeRepairSelectedRepairment.id,
            HomeRepairCase: { ID: this.state.serviceData?.id },
            MaterialAmount: this.state.homeRepairSelectedRepairment.amount.replace(',', '.'),
            MaterialPricePerUnit: this.state.homeRepairSelectedRepairment.pricePerUnit.replace(',', '.'),
            TotalPrice: String(this.state.homeRepairSelectedRepairment.totalPrice),
            MaterialDetail: this.state.homeRepairSelectedRepairment.materialDetail,
            HomeRepairRepairmentType: { ID: this.state.homeRepairSelectedRepairment.repairmentType.value },
            HomeRepairMaterial: { ID: this.state.homeRepairSelectedRepairment.material.value },
            HomeRepairRoom: this.state.homeRepairSelectedRepairment.room ? { ID: this.state.homeRepairSelectedRepairment.room.value } : null,
            HomeRepairMaterialUnitType: { ID: this.state.homeRepairSelectedRepairment.unit.value },
            IsActive: true,
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken"),
            ServiceID: this.state.serviceData.id
        }
        axios.post("api/service/savehomerepairrepairment/", request)
            .then(res => {
                if (res.data.data) {
                    //alert("Onarım Kaydedilmiştir.")
                    NotificationManager.success("Onarım kaydedilmiştir");
                    this.setState({
                        searchBtnDisabled: false,
                        homeRepairSelectedRepairment: {
                            id: 0,
                            repairmentType: {},
                            pricePerUnit: "",
                            amount: "",
                            totalPrice: "",
                            materialDetail: "",
                            material: null,
                            room: null,
                            unit: null
                        },
                        refresh:true
                    },this.refreshComponent);
                }
                else {
                    //alert(res.data.message);
                    NotificationManager.error(res.data.message);
                    this.setState({ saveIconClasses: "fa-spin d-none ml-2" })
                    this.setState({
                        searchBtnDisabled: false,
                        homeRepairSelectedRepairment: {
                            id: 0,
                            repairmentType: {}
                        }
                    });
                }
            }).catch(e => {
                console.log(e);
                NotificationManager.error("Bir hata oluştu");
            })
    }
    async getRepairmentsPrintTable() {
       axios.post("api/service/generaterepairmentprintdata/", this.state.serviceData)
            .then(res => {
                if (res.data.data) {
                    this.setState({ printableRepairments: res.data.data, repairmentsTotal: res.data.totalPrice, repairmentsVat: res.data.vat, repairmentsSum: res.data.sum })
                }
                else {
                    alert(res.data.message);
                    NotificationManager.error(res.data.message);
                }
            }).catch(e => {
                console.log(e);
                NotificationManager.error("Bir hata oluştu");
            })
    }
    async sendRepairmentsToIPA() {
        var request = {
            ID: this.state.serviceData?.id,
            RepairApproval: { ID: 2 },
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken"),
            ServiceID: this.state.serviceData.id
        }
        axios.post("api/service/sendrepairmentstoipa/", request)
            .then(res => {
                if (res.data.data) {
                    //alert("Onarımlar IPA'ya Gönderilmiştir.")
                    NotificationManager.success("Onarımlar IPA'ya gönderilmiştir.");
                    //this.setState({ refresh: true }, this.refreshComponent);
                    this.setState({ ...this.state, sendRepsToIPADisabled: true, saveRepsButtonDisabled: true, refresh: true }, this.refreshComponent);
                }
                else {
                    //alert(res.data.message);
                    NotificationManager.error(res.data.message);
                    this.setState({ searchBtnDisabled: false, saveIconClasses: "fa-spin d-none ml-2" });
                }
            }).catch(e => {
                console.log(e);
                NotificationManager.error("Bir hata oluştu");
            })
    }

    getFile(item) {
        window.open('api/service/gethomerepaircasefile?fileID=' + item.id, '_blank');       
    }

    //Component Events
    componentDidMount() {
        this.setState({ serviceData: this.props.service.data.data }, () => {
            var agendaItems = this.state.serviceData.agendaItems?.map((x) => {
                return {
                    itemId: x.id,
                    start: x.agendaDate,//.ToString("s"),
                    color: "orange",
                    typeId: x.agendaType?.id,
                    title: x.agendaType ? x.agendaType.name + " -  " + x.description : x.description,
                    description: x.description,
                    agendaType: x.agendaType
                };

            });
            var notes = !this.state.serviceData.repairNotesList ? null : this.state.serviceData.repairNotesList?.filter(x => x.isInitialCallNote);
            var repairments = this.state.serviceData.repairments;
            this.getRepairmentsPrintTable();
            /*let calendarApi = this.calendarRef.current.getApi()
            calendarApi.refetchEvents()
            //calendarApi.render()
            //calendarApi.getEvents()
            calendarApi.updateSize()*/
           /* for (const [, value] of this.state.serviceData?.photos?.entries()) {
                var dataURI = "data:image/jpeg;base64," + value.imageData;
                var byteString = atob(dataURI.split(',')[1]);
                var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
                var ab = new ArrayBuffer(byteString.length);
                var ia = new Uint8Array(ab);
                for (var i = 0; i < byteString.length; i++)
                    ia[i] = byteString.charCodeAt(i);
                var blob = new Blob([ab], { type: mimeString });
                Resizer.imageFileResizer(
                    blob,
                    150,
                    150,
                    'JPEG',
                    100,
                    0,
                    uri => {
                        if (!this.state.photosPreview.some(x => (x === uri))) {
                            const newArray = this.state.photosPreview.concat(uri);
                            this.setState({ photosPreview: newArray });
                        }
                        else
                            alert("Fotoğraf önceden eklenmiş.")
                    }
                );
            }*/
            this.setState({
                ...this.state,
                serviceData: {
                    ...this.state.serviceData,
                    photos: []
                },
                agendaItems: agendaItems,
                repairments: repairments,
                sendRepsToIPADisabled: this.state.serviceData.repairApproval?.isLocked,
                saveRepsButtonDisabled: this.state.serviceData.repairApproval?.isLocked,
                columnsRepairment: [
                    {
                        name: 'Onarım Tipi',
                        selector: 'homeRepairRepairmentType.name',
                        sortable: false,
                        wrap: true
                    },
                    {
                        name: 'Malzeme',
                        selector: 'homeRepairMaterial.name',
                        sortable: false,
                        wrap: true
                    },
                    {
                        name: 'Mahal',
                        //selector: 'homeRepairRoom.name',
                        cell: row => <span>{row.homeRepairRoom?.name}</span>,
                        sortable: false,
                        wrap: true
                    },
                    {
                        name: 'Birim',
                        selector: 'homeRepairMaterialUnitType.name',
                        sortable: false,
                        wrap: true
                    },
                    {
                        name: 'Miktar',
                        selector: 'materialAmount',
                        sortable: false,
                        wrap: true
                    },
                    {
                        name: 'Birim Fiyat',
                        selector: 'materialPricePerUnit',
                        sortable: false,
                        wrap: true
                    },
                    {
                        name: 'Toplam Fiyat',
                        selector: 'totalPrice',
                        sortable: false,
                        wrap: true
                    },
                    {
                        cell: row => <span>{row.createUser == null ? row.createIPAEksperUser?.userName : row.createUser?.displayName}</span>,
                        wrap: true
                    },
                    {
                        cell: row => <Button variant="warning" disabled={this.state.saveRepsButtonDisabled} onClick={() => this.handleUpdateRepairment(row)}>Güncelle</Button>,
                        /*wrap: true,
                        ignoreRowClick: true,*/
                        button: true
                    },
                    {
                        cell: row => <Button variant="danger" disabled={this.state.saveRepsButtonDisabled} onClick={() => this.handleDeleteRepairment(row)}>Sil</Button>,
                        /*wrap: true,
                        ignoreRowClick: true,*/
                        button: true
                    }
                ],
                hasInitialCallNote: notes && notes.length > 0
            });
        });
        this.getAgendaItemTypes();
        this.gethomeRepairRepairmentTypes();
        this.gethomeRepairRooms();
        this.gethomeRepairUnits();
        this.gethomeRepairCaseFileTypes();
        //this.gethomeRepairMaterialsByRepairmentType();

    }

    refreshComponent() {

        var request = {
            ID: this.state.serviceData.id,
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/service/gethomerepaircasebyrequest/", request)
            .then(res => {
                if (res.data.data != null) {
                    this.setState({ serviceData: res.data.data }, () => {
                        var agendaItems = this.state.serviceData.agendaItems?.map((x) => {
                            return {
                                itemId: x.id,
                                start: x.agendaDate,//.ToString("s"),
                                color: "orange",
                                typeId: x.agendaType?.id,
                                title: x.agendaType ? x.agendaType.name + " -  " + x.description : x.description,
                                description: x.description,
                                agendaType: x.agendaType
                            };

                        });
                        var notes = !this.state.serviceData.repairNotesList ? null : this.state.serviceData.repairNotesList?.filter(x => x.isInitialCallNote);
                        var repairments = this.state.serviceData.repairments;
                        this.getRepairmentsPrintTable();
                        
                        this.setState({
                            ...this.state,
                            agendaItems: agendaItems,
                            repairments: repairments,
                            sendRepsToIPADisabled: this.state.serviceData.repairApproval?.isLocked,
                            saveRepsButtonDisabled: this.state.serviceData.repairApproval?.isLocked,
                            columnsRepairment: [
                                {
                                    name: 'Onarım Tipi',
                                    selector: 'homeRepairRepairmentType.name',
                                    sortable: false,
                                    wrap: true
                                },
                                {
                                    name: 'Malzeme',
                                    selector: 'homeRepairMaterial.name',
                                    sortable: false,
                                    wrap: true
                                },
                                {
                                    name: 'Mahal',
                                    //selector: 'homeRepairRoom.name',
                                    cell: row => <span>{row.homeRepairRoom?.name}</span>,
                                    sortable: false,
                                    wrap: true
                                },
                                {
                                    name: 'Birim',
                                    selector: 'homeRepairMaterialUnitType.name',
                                    sortable: false,
                                    wrap: true
                                },
                                {
                                    name: 'Miktar',
                                    selector: 'materialAmount',
                                    sortable: false,
                                    wrap: true
                                },
                                {
                                    name: 'Birim Fiyat',
                                    selector: 'materialPricePerUnit',
                                    sortable: false,
                                    wrap: true
                                },
                                {
                                    name: 'Toplam Fiyat',
                                    selector: 'totalPrice',
                                    sortable: false,
                                    wrap: true
                                },
                                {
                                    cell: row => <span>{row.createUser == null ? row.createIPAEksperUser?.userName : row.createUser?.displayName}</span>,
                                    wrap: true
                                },
                                {
                                    cell: row => <Button variant="warning" disabled={this.state.saveRepsButtonDisabled} onClick={() => this.handleUpdateRepairment(row)}>Güncelle</Button>,
                                    /*wrap: true,
                                    ignoreRowClick: true,*/
                                    button: true
                                },
                                {
                                    cell: row => <Button variant="danger" disabled={this.state.saveRepsButtonDisabled} onClick={() => this.handleDeleteRepairment(row)}>Sil</Button>,
                                    /*wrap: true,
                                    ignoreRowClick: true,*/
                                    button: true
                                }
                            ],
                            hasInitialCallNote: notes && notes.length > 0
                        });
                    });
                }
                    
            });

        
    }
    componentDidUpdate(prevProps) {
        if (prevProps.service !== this.props.service) {
            this.setState({ serviceData: this.props.service.data.data });
            let calendarApi = this.calendarRef.current.getApi()
            calendarApi.refetchEvents()
            //calendarApi.render()
            //calendarApi.getEvents()
            calendarApi.updateSize()
        }
        let calendarApi = this.calendarRef.current.getApi()
        calendarApi.refetchEvents()
        //calendarApi.render()
        //calendarApi.getEvents()
        calendarApi.updateSize()
        
    }

    render() {

        return (
            <>
                <div className="noPrint">
                    <Card className="mb-1">
                        <Card.Header>
                            <h6>Genel Bilgileri</h6>
                        </Card.Header>
                        <Card.Body>
                            <h5><Badge variant="primary">Konut Dosya Bilgileri</Badge></h5>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className="mb-0">
                                        <Row>
                                            <Col xs={12} sm={12} md={6}>
                                                <LabeledData label="Sigorta Dosya No:" data={this.state.serviceData.mainCase?.caseProductOwner?.platePolicyNumber} />
                                            </Col>
                                            <Col xs={12} sm={12} md={6}>
                                                <LabeledData label="Muşteri:" data={this.state.serviceData.mainCase?.caseProductOwner?.name} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={6}>
                                                <LabeledData label="İhbar Tarihi:" data={this.state.serviceData.mainCase?.callDate ? moment(this.state.serviceData.mainCase?.callDate).format("DD.MM.YYYY HH:mm") : "---"} />
                                            </Col>
                                            <Col xs={12} sm={12} md={6}>
                                                <LabeledData label="Müşteri Tel:" data={this.state.serviceData.mainCase?.callerMobilePhoneNumber} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={6}>
                                                <LabeledData label="IPA Ref:" data={this.state.serviceData.mainCase?.caseReferanceNumber} />
                                            </Col>
                                            <Col xs={12} sm={12} md={6}>
                                                <LabeledData label="Protokol:" data={this.state.serviceData.mainCase?.caseProthocolNumber} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={6}>
                                                <LabeledData label="Sigorta Şirketi:" data={this.state.serviceData.mainCase?.caseInsurance?.insuranceName} />
                                            </Col>
                                            <Col xs={12} sm={12} md={6}>
                                                <LabeledData label="Adres:" data={this.state.serviceData.mainCase?.sceneAdress} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={4}>
                                                <LabeledData label="Hasar:" data={this.state.serviceData.mainCase?.damageTypeParoxMatrixDescrtiption} />
                                            </Col>
                                            <Col xs={12} sm={12} md={4}>
                                                <LabeledData label="Hasar Tipi:" data={this.state.serviceData.mainCase?.damageDescription} />
                                            </Col>
                                            <Col xs={12} sm={12} md={4}>
                                                <LabeledData label="IPA Uzmanı:" data={this.state.serviceData.specialistUser?.displayName} />
                                            </Col>
                                        </Row>
                                        
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                            <Card>
                                <Card.Header>
                                    <h6>Konut Dosyası İşlemleri</h6>
                                </Card.Header>
                            </Card>
                            <Tabs defaultActiveKey="homerepairRepairments" onSelect={this.handleTabChange}>
                                <Tab eventKey="homerepairRepairments" title="Onarımlar">
                                    <Card>
                                        <Card.Body>
                                            <Row>
                                                <Col xs={9} className="mb-3">
                                                    <LabeledData label="Onarım Kayıt Durumu:" data={this.state.serviceData.repairApproval?.name} />
                                                </Col>
                                                <Col xs={3} className="mb-3">
                                                    <Button variant="link" onClick={() => { this.setState({ showRepairmentsDetailsModal: true }) }}>
                                                        <FontAwesomeIcon icon={faPrint} className="fa fa-2x" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                            {(this.state.serviceData.repairApproval?.id === 4 || this.state.serviceData.repairApproval?.id === 1 || this.state.serviceData.repairApproval?.id === 5) &&
                                        <>
                                        <Row>
                                            <Col xs={12} sm={12} md={3}>
                                                <SelectInput
                                                    options={this.state.homeRepairRepairmentTypes}
                                                    handleChange={this.handleSelectedRepairmentType}
                                                    faicon={faMapMarked}
                                                    label="Onarım Tipi"
                                                    placeholder="Onarım Tipi Seçiniz"
                                                    isMulti={false}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.homeRepairSelectedRepairment.repairmentType}
                                                />
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <SelectInput
                                                    ref={this.materialRef}
                                                    options={this.state.homeRepairMaterials}
                                                    handleChange={this.handleSelectedMaterial}
                                                    faicon={faMapMarked}
                                                    label="Malzeme"
                                                    placeholder="Malzeme Seçiniz"
                                                    isMulti={false}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.homeRepairSelectedRepairment.material}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={3}>
                                                <TextInput faicon={faMoneyBill} label="Malzeme Detayı" placeholder="Malzeme Detayı" value={this.state.homeRepairSelectedRepairment.materialDetail} handleChange={this.handleMaterialDetailChange} />
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <SelectInput
                                                    options={this.state.homeRepairRooms}
                                                    handleChange={this.handleSelectedRoom}
                                                    faicon={faMapMarked}
                                                    label="Mahal"
                                                    placeholder="Mahal Seçiniz"
                                                    isMulti={false}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.homeRepairSelectedRepairment.room}
                                                />
                                            </Col>


                                        </Row>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={3}>

                                                                
                                                            <TextInput faicon={faHashtag} label="Miktar" placeholder="Miktar" value={this.state.homeRepairSelectedRepairment.amount} handleChange={this.handleAmountChange} />
                                                        </Col>
                                                        <Col xs={12} sm={12} md={3}>
                                                            <SelectInput
                                                                options={this.state.homeRepairUnits}
                                                                handleChange={this.handleSelectedUnit}
                                                                faicon={faMapMarked}
                                                                label="Birim"
                                                                placeholder="Birim Seçiniz"
                                                                isMulti={false}
                                                                closeMenuOnSelect={true}
                                                                value={this.state.homeRepairSelectedRepairment.unit}
                                                            />
                                                        </Col>
                                                                    <Col xs={12} sm={12} md={3}>
                                                                        <TextInput faicon={faMoneyBill} label="Birim Fiyat" placeholder="Birim Fiyat" value={this.state.homeRepairSelectedRepairment.pricePerUnit} handleChange={this.handlePricePerUnitChange} />
                                                                    </Col>
                                                                    <Col xs={12} sm={12} md={3} className="mt-4">
                                                                        <LabeledData label="Toplam Fiyat:" data={this.state.homeRepairSelectedRepairment.totalPrice} />
                                                                    </Col>
                                                                    
                                                    </Row>
                                                    <hr />
                                                                
                                                    <Row className="mt-3">
                                                        <Col>
                                                            <Button variant="primary" className="float-right mr-3" disabled={this.state.saveRepsButtonDisabled} onClick={() => this.saveHomeRepairRepairment()} >
                                                                <FontAwesomeIcon icon={faSave} className="mr-2" />
                                                                <span>Onarımı Kaydet</span>
                                                                <FontAwesomeIcon className={this.state.saveIconClasses} icon={faCircleNotch} />
                                                            </Button>
                                                        </Col>
                                            </Row>

                                    <Row>
                                        <Col xs={12} sm={12} md={12}>
                                            <Row>
                                                <Col>
                                                    <label>Onarım Notu:</label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} md={10}>
                                                    <FormControl
                                                        as="textarea"
                                                        className="rounded-0 noresize"
                                                        placeholder="Onarım Notu"
                                                        rows={3}
                                                        onChange={this.handleHomeRepairRepairmentNoteChange}
                                                        value={this.state.serviceData?.repairNotes}
                                                    />
                                                </Col>
                                                <Col xs={12} sm={12} md={2}>

                                                  
                                                    <Button variant="primary" className="float-right" onClick={() => this.saveHomeRepairRepairmentNote()} >
                                                        <FontAwesomeIcon icon={faSave} className="mr-2" />
                                                        <span>Not Ekle</span>
                                                        <FontAwesomeIcon className={this.state.saveIconClasses} icon={faCircleNotch} />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                        </>}

                                            <DataTable
                                                columns={this.state.columnsRepairment}
                                                data={this.state.serviceData.repairments}
                                                defaultSortField="homeRepairRepairmentType.name"
                                                defaultSortAsc={true}
                                                conditionalRowStyles={this.state.conditionalHomeRepairRepairmentRowStyles}
                                                noDataComponent={
                                                    <h5>Veri bulunmamaktadır</h5>
                                                }

                                                highlightOnHover={true}
                                                striped={true}
                                    />

                                    <Row className="mt-3">
                                        <Col>
                                            <Button variant="primary" className="float-right mr-3" disabled={this.state.sendRepsToIPADisabled} onClick={() => this.sendRepairmentsToIPA()} >
                                                <FontAwesomeIcon icon={faSave} className="mr-2" />
                                                <span>Onarımları IPA'ya Gönder</span>
                                                <FontAwesomeIcon className={this.state.saveIconClasses} icon={faCircleNotch} />
                                            </Button>
                                        </Col>
                                    </Row>
                                        </Card.Body>
                                    </Card>

                                </Tab>
                                <Tab eventKey="homeRepairFiles" title="Konut Dosyaları">
                                    <Card className="mb-1">
                                        <Card.Body>
                                    <Row className="mb-2">
                                        <Col xs={12} sm={6} md={6} lg={4}>
                                            <SelectInput
                                                options={this.state.homeRepairCaseFileTypes}
                                                handleChange={this.handleSelectedCaseFileType}
                                                faicon={faMapMarked}
                                                placeholder="Dosya Tipi seçiniz"
                                                isMulti={false}
                                                closeMenuOnSelect={true}
                                                value={this.state.homeRepairSelectedCaseFileType}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={6} lg={ 8}>
                                                    <Form>
                                                        <Form.File label="Dosya Yükle (Lütfen Önce Dosya Tipini Seçiniz)" custom onChange={this.handleHomeRepairFileUpload} />
                                                    </Form>
                                        </Col>
                                       
                                            </Row>
                                    <Row>
                                        
                                        
                                        {
                                            this.state.homeRepairCaseFilesLoading ?
                                                <div className="d-flex justify-content-center  mt-3">
                                                    <div className="spinner-border mr-3" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <span>Konut Dosyaları Yükleniyor...</span>
                                                </div>
                                                :
                                                this.state.serviceData.mainCase?.caseFiles?.length > 0 ? 
                                                    this.state.serviceData.mainCase?.caseFiles.map(item => (
                                                        <Col xs={12} sm={6} md={4} lg={3} key={item.id} className="p-2 border-bottom" align="center" >
                                                            <a onClick={() => this.getFile(item)} target="_blank">
                                                                <img src={item.caseFile} alt={item.fileName} className="img-responsive img-thumbnail" />
                                                            </a>
                                                        </Col>
                                                    ))
                                                :
                                                <div className="d-flex justify-content-center  mt-3">
                                                    
                                                    <span>Henüz konut dosyası yüklenmemiş.</span>
                                                </div>
                                        }
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Tab>
                                <Tab eventKey="homerepairAgendaItems" title="Ajanda">
                                    <div>
                                        <Row>
                                            <Col xs={12}>
                                                <Card>
                                                    <Card.Header>
                                                        <h4>Servis Arama</h4>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Form.Group className="mb-0">
                                                            <Row>
                                                                <Col xs={12} sm={6} >
                                                                    <DateInput
                                                                        timeFormat={true}
                                                                        label="Gidiş Tarihi - Saati"
                                                                        placeholder="Gidiş Tarihi - Saati"
                                                                        handleChange={this.handleDate}
                                                                        stateName="agendaDate"
                                                                        value={this.state.homeRepairSelectedAgendaItem.agendaDate}
                                                                    />
                                                                </Col>
                                                                <Col xs={12} sm={6}>
                                                                    <SelectInput
                                                                        options={this.state.homeRepairAgendaTypes}
                                                                        handleChange={this.handleSelected}
                                                                        faicon={faMapMarked}
                                                                        label="Ajanda Tipi"
                                                                        placeholder="Ajanda Tipi Seçiniz"
                                                                        isMulti={false}
                                                                        closeMenuOnSelect={true}
                                                                        value={this.state.homeRepairSelectedAgendaItem.agendaType}
                                                                    />
                                                                </Col>

                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12}>
                                                                    <Row>
                                                                        <Col>
                                                                            <label>Açıklama</label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} sm={12} md={6}>
                                                                            <FormControl
                                                                                as="textarea"
                                                                                className="rounded-0 noresize"
                                                                                placeholder="Açıklama"
                                                                                rows={3}
                                                                                onChange={this.handleHomeRepairAgendaItemDescription}
                                                                                value={this.state.homeRepairSelectedAgendaItem.description}
                                                                            />
                                                                        </Col>
                                                                        <Col xs={12} sm={12} md={6}>
                                                                            <Button variant="primary" className="float-right" onClick={() => this.saveHomeRepairAgendaItem()} >
                                                                                <FontAwesomeIcon icon={faSave} className="mr-2" />
                                                                                <span>Kaydet / Güncelle</span>
                                                                                <FontAwesomeIcon className={this.state.saveIconClasses} icon={faCircleNotch} />
                                                                            </Button>
                                                                            <Button variant="warning" className="float-right mr-3" onClick={this.handleDayClick} >
                                                                                    <FontAwesomeIcon icon={faEdit} className="mr-2" />
                                                                                    <span>Formu Temizle</span>
                                                                                    <FontAwesomeIcon className={this.state.saveIconClasses} icon={faCircleNotch} />
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                            </Row>

                                                        </Form.Group>
                                                    </Card.Body>
                                                    <Card.Footer>

                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                    <FullCalendar
                                plugins={[dayGridPlugin, listPlugin]}
                                ref={this.calendarRef}
                                headerToolbar={{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth listMonth,listWeek'
                                }}
                                initialView={'dayGridMonth'}
                                locale={'tr'}
                                //editable={true}
                                buttonText={{
                                    listWeek: 'Haftalık Ajanda',
                                    listMonth: 'Aylık Ajanda',
                                    today: 'Bugün',
                                    dayGridMonth: 'Ay'
                                }}
                                eventTimeFormat={{ // like '14:30'
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    meridiem: false
                                }}
                                //eventLimit={true}
                                //editable={true}
                                selectable={this.state.fullCalendarSelect}
                                //selectMirror={true}
                                dayMaxEvents={true}
                                //initialEvents={this.state.agendaItems}
                                events={this.state.agendaItems}
                                //eventSources={[{ events = { this.state.agendaItems } }]}// alternatively, use the `events` setting to fetch from a feed
                                //select={this.handleDateSelect}
                                //eventContent={renderEventContent} // custom render function
                                eventClick={this.handleEventClick}
                                
                                    //eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                                    /* you can update a remote database when these fire:
                                    eventAdd={function(){}}
                                    eventChange={function(){}}
                                    eventRemove={function(){}}
                                    */
                                    />
                                </Tab>
                                <Tab eventKey="homerepairNotes" title="Notlar">
                                    <Card className="mb-1">
                                        {this.state.proto}
                                        <Card.Body>

                                            <Row>
                                                <Col xs={12}>
                                                    <Form.Group className="mb-0">
                                                        <Row>
                                                            <Col xs={12} sm={12} md={12}>
                                                                <Row>
                                                                    <Col>
                                                                        <label>Tedarikçi Notu:</label>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={6}>
                                                                        <FormControl
                                                                            as="textarea"
                                                                            className="rounded-0 noresize"
                                                                            placeholder="Yeni Not"
                                                                            rows={3}
                                                                            onChange={this.handleHomeRepairNoteChange}
                                                                            value={this.state.homeRepairNoteToSave}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={12} sm={12} md={6}>

                                                                        <Form.Check
                                                                            type="switch"
                                                                            id="isHRFirstCallNote"
                                                                            label="İlk Arama Notu mu?"
                                                                            checked={this.state.homeRepairIsInitialCallNote}
                                                                            onChange={this.toggleIsHRInitialCallNote}
                                                                            disabled={this.state.hasInitialCallNote}
                                                                        />
                                                                        <Button variant="primary" className="float-right" onClick={() => this.saveHomeRepairNote()} >
                                                                            <FontAwesomeIcon icon={faSave} className="mr-2" />
                                                                            <span>Not Ekle</span>
                                                                            <FontAwesomeIcon className={this.state.saveIconClasses} icon={faCircleNotch} />
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                        </Row>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <DataTable
                                                columns={this.state.columns}
                                                data={this.state.serviceData.repairNotesList}
                                                defaultSortField="RecordDate"
                                                defaultSortAsc={false}
                                                conditionalRowStyles={this.state.conditionalHomeRepairRowStyles}
                                                subHeader={true}
                                                noDataComponent={
                                                    <h5>Veri bulunmamaktadır</h5>
                                                }
                                                subHeaderComponent={(
                                                    <div style={{ alignItems: 'center' }}>
                                                        <FontAwesomeIcon icon={faSquare} className="mr-2 text-primary" /> Tedarikçi Notları
                                                        <br />
                                                        <FontAwesomeIcon icon={faSquare} className="mr-2 text-success" /> IPA Notları
                                                        <br />
                                                        <FontAwesomeIcon icon={faSquare} className="mr-2 text-warning" /> İlk Arama Notu
                                                    </div>
                                                )}
                                                highlightOnHover={true}
                                                striped={true}
                                            />
                                        </Card.Body>
                                    </Card>
                                </Tab>
                            </Tabs>
                    <NotificationContainer/>
                </div>
                <Modal dialogClassName="modal-90w" show={this.state.showRepairmentsDetailsModal} onHide={() => this.handleRepairmentsDetailModalClose()}>
                    <Modal.Header>
                        <Modal.Title>Onarım Detayları</Modal.Title>
                        <div>
                            <Button variant="link" className="noPrint" onClick={() => window.print()}>
                                <FontAwesomeIcon icon={faPrint} />
                            </Button>
                            <Button variant="link" className="noPrint text-danger" onClick={() => this.handleRepairmentsDetailModalClose()}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>

                        {this.renderPrintRepairments()}
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
