import React, { Component } from 'react';

//Bootstrap
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

function RenderFontAwesomeIcon(icon)
{
    if (icon.icon) {
        return (
            <InputGroup.Prepend>
                <InputGroup.Text className="rounded-0">
                    <FontAwesomeIcon icon={icon.icon} />
                </InputGroup.Text>
            </InputGroup.Prepend>
        );
    }
    else
        return null;
}
function RenderFontLabel(label) {
    if (label.label) {
        return (
            <label>{label.label}</label>
        );
    }
    else
        return null;
}

function RenderAppendButton(props) {
    if (props.buttonText) {
        return (
            <InputGroup.Append>
                <Button variant="outline-primary" onClick={() => props.buttonEvent()} disabled={props.isLoading || props.btnDisabled}>
                    <span>{props.buttonText}</span>
                    <FontAwesomeIcon className={props.isLoading ? "fa-spin ml-2" : "d-none" } size="lg" icon={faCircleNotch} />
                </Button>
            </InputGroup.Append>
        );
    }
    else
        return null;
}

export class TextInput extends Component {
    static displayName = TextInput.name;

    constructor(props) {
        super(props);
        this.state = {

        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.props.handleChange(e);
    }

    render() {
        return (
            <>
                <RenderFontLabel label={this.props.label} />
                <InputGroup className="mb-2">
                    <RenderFontAwesomeIcon icon={this.props.faicon} />
                    <Form.Control
                        name={this.props.name}
                        className="rounded-0"
                        placeholder={this.props.placeholder}
                        onChange={e => this.handleChange(e)}
                        value={this.props.value}
                        disabled={this.props.disabled}
                        type={this.props.type}
                    />
                    <RenderAppendButton
                        buttonEvent={this.props.buttonEvent}
                        buttonText={this.props.buttonText}
                        isLoading={this.props.btnIsLoading}
                        btnDisabled={this.props.btnDisabled}
                    />
                </InputGroup>
            </>
        );
    }
}
