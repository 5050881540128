import React, { Component } from 'react';

//Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

//DataTable
import DataTable from 'react-data-table-component';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

//moment
import "moment/locale/tr";
import moment from 'moment';


export class HomeRepairList extends Component {
    static displayName = HomeRepairList.name;

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.searchData,
            serviceColumns: [
                {
                    cell: row =>
                        <Button variant="primary" onClick={() => this.props.getServiceByID(row.id)}>
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                    ,
                    button: true,
                },
                {
                    name: 'Protokol',
                    selector: 'mainCase.caseProthocolNumber',
                    sortable: true
                },
                {
                    name: 'IPA Referans',
                    selector: 'mainCase.caseReferanceNumber',
                    sortable: true
                },
                {
                    name: 'Sigorta Dosya No',
                    selector: 'mainCase.caseProductOwner.platePolicyNumber',
                    sortable: true
                },
                {
                    name: 'Sigortalı',
                    selector: 'mainCase.caseProductOwner.name',
                    sortable: true,
                    wrap: true
                },
                {
                    name: 'Sigorta Şirketi',
                    selector: 'mainCase.caseInsurance.insuranceName',
                    sortable: true,
                    wrap: true
                },
                {
                    name: 'Şehir',
                    selector: 'mainCase.town.description',
                    sortable: true,
                    wrap: true,
                    omit: this.props.isProviderStatusHidden
                }
            ],
        };
    }
    static defaultProps = {
        isProtocolHidden: false,
        isPlateHidden: false,
        isDamageTypeHidden: false,
        isServiceTypeHidden: false,
        isServiceDateHidden: false,
        isProviderStatusHidden: false,
        isRaxaStatusHidden: false,
        isCaseNumberHidden: false
    };

    handleChange(evt) {
        const value = evt.target.value;
        this.setState({ [evt.target.name]: value });
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <Card>
                           
                            <Card.Body>
                                <DataTable
                                    columns={this.state.serviceColumns}
                                    data={this.props.searchData.data.data}
                                    defaultSortField="title"
                                    title="Konut Dosyaları"
                                    pagination={true}
                                    progressPending={this.props.serviceTableLoading}
                                    progressComponent={
                                        <>
                                            <h5>Yükleniyor</h5><FontAwesomeIcon className="fa-spin ml-3" icon={faCircleNotch} size="2x" />
                                        </>
                                    }
                                    noDataComponent={
                                        <h5>Veri bulunmamaktadır</h5>
                                    }
                                    highlightOnHover={true}
                                    striped={true}
                                />
                            </Card.Body>
                            <Card.Footer>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
