import React, { Component } from 'react';
import axios from 'axios';
//Custom Components
import { TextInput } from './TextInput';
import { SelectInput } from './SelectInput';
import { LabeledData } from './LabeledData';
//Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator } from '@fortawesome/free-solid-svg-icons'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import { faHashtag } from '@fortawesome/free-solid-svg-icons'
import { faCar } from '@fortawesome/free-solid-svg-icons'
import { faFan } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
//React-Notification
import { NotificationContainer, NotificationManager } from 'react-notifications';

//Conditional Rendering
function RenderPricingInput(props) {
    //No Pricing
    if (props.pricingTypeID === 1) {
        return (
            <>
                <hr />
                <h4>Fiyatlama tanımınız bulunmamaktadır.</h4>
                <hr />
            </>
        );
    }
    //RAC pricing
    if (props.pricingTypeID === 2) {
        return (
            <Row className="flex-row">
                <Col xs={12} sm={12} md={6} lg={4}>
                    <SelectInput
                        options={props.racVehicleSegments}
                        handleChange={props.handleRacVehicleSegmentChange}
                        label="Araç Grubu"
                        placeholder="Araç Grubu Seçiniz"
                        faicon={faCar}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={!props.selectedRacVehicleSegment.value ? null : { value: props.selectedRacVehicleSegment.value, label: props.selectedRacVehicleSegment.label }}
                    />
                </Col>
                <Col xs={12} sm={12} md={6} lg={4}>
                    <TextInput
                        name="selectedRacVehicleSegmentDrop"
                        faicon={faLocationArrow}
                        label="Drop/KM"
                        placeholder="Drop/KM"
                        value={props.selectedRacVehicleSegmentDrop}
                        handleChange={props.handleIntegerTextInputChange}
                    />
                </Col>
                <Col xs={12} sm={12} md={6} lg={4}>
                    <TextInput
                        name="selectedRacVehicleSegmentDayDuration"
                        faicon={faHashtag}
                        label="Kiralama Süresi (Gün)"
                        placeholder="Kiralama Süresi (Gün)"
                        value={props.selectedRacVehicleSegmentDayDuration}
                        handleChange={props.handleIntegerTextInputChange}
                    />
                </Col>
                <Col xs={12} sm={12} md={6}>
                    <SelectInput
                        options={props.pricingPeriods}
                        handleChange={props.handlePricingPeriodChange}
                        label="Fiyat Dönemi"
                        placeholder="Fiyat Dönemi Seçiniz"
                        faicon={faFan}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={!props.selectedPricingPeriod.value ? null : { value: props.selectedPricingPeriod.value, label: props.selectedPricingPeriod.label }}
                    />
                </Col>
            </Row>
        )
    } else {
        return (
            <Row className="flex-row">
                <Col xs={12} sm={12} md={6}>
                    <SelectInput
                        options={props.towVehicleTypes}
                        handleChange={props.handleTowVehicleTypeChange}
                        label="Araç Tipi"
                        placeholder="Araç Tipi Seçiniz"
                        faicon={faCar}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={!props.selectedTowVehicleType.value ? null : { value: props.selectedTowVehicleType.value, label: props.selectedTowVehicleType.label }}
                    />
                </Col>
                <Col xs={12} sm={12} md={6}>
                    <TextInput
                        name="selectedTowKM"
                        faicon={faLocationArrow}
                        label="Mesafe(KM)"
                        placeholder="Mesafe(KM)"
                        value={props.selectedTowKM}
                        handleChange={props.handleIntegerTextInputChange}
                    />
                </Col>
                <Col xs={12} sm={12} md={6}>
                    <SelectInput
                        options={props.pricingPeriods}
                        handleChange={props.handlePricingPeriodChange}
                        label="Fiyat Dönemi"
                        placeholder="Fiyat Dönemi Seçiniz"
                        faicon={faFan}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={!props.selectedPricingPeriod.value ? null : { value: props.selectedPricingPeriod.value, label: props.selectedPricingPeriod.label }}
                    />
                </Col>
            </Row>
        );
    }
}

export class Pricing extends Component {
    static displayName = Pricing.name;

    constructor(props) {
        super(props);
        this.state = {
            racVehicleSegments: [],
            towVehicleTypes: [],
            selectedRacVehicleSegment: {},
            selectedRacVehicleSegmentDrop: "",
            selectedRacVehicleSegmentDayDuration: "",
            calculateDisabled: false,
            calculatingClasses: "fa-spin d-none ml-2",
            calculationResult: "",
            selectedTowVehicleType: {},
            selectedPricingPeriod: {},
            selectedTowKM: "",
            pricingPeriods: []
        };
        this.getProviderPricingInfo = this.getProviderPricingInfo.bind(this);
        this.handleRacVehicleSegmentChange = this.handleRacVehicleSegmentChange.bind(this);
        this.handleIntegerTextInputChange = this.handleIntegerTextInputChange.bind(this);
        this.calculatePrice = this.calculatePrice.bind(this);
        this.handleTowVehicleTypeChange = this.handleTowVehicleTypeChange.bind(this);
        this.handlePricingPeriodChange = this.handlePricingPeriodChange.bind(this);
    }

    handleIntegerTextInputChange(evt) {
        if ((String(parseInt(evt.target.value)) === evt.target.value && parseInt(evt.target.value) >= 0) || evt.target.value === "")
            this.setState({ [evt.target.name]: evt.target.value });
    }
    handleRacVehicleSegmentChange(selectedOption) {
        this.setState({ selectedRacVehicleSegment: selectedOption });
    }
    handleTowVehicleTypeChange(selectedOption) {
        this.setState({ selectedTowVehicleType: selectedOption });
    }
    handlePricingPeriodChange(selectedOption) {
        this.setState({ selectedPricingPeriod: selectedOption });
    }

    async calculatePrice() {
        if (this.state.pricingParameters.pricingTypeID === 1)
            return;

        if (this.state.pricingParameters.pricingTypeID === 2) {
            if (!this.state.selectedRacVehicleSegment?.value) {
                NotificationManager.error("Araç Segmenti Seçilmedi", null, 2000);
                return;
            }
            if (!this.state.selectedRacVehicleSegmentDayDuration) {
                NotificationManager.error("Kiralama Süresi Girilmedi", null, 2000);
                return;
            }
            if (!this.state.selectedPricingPeriod?.value) {
                NotificationManager.error("Dönem Seçilmedi", null, 2000);
                return;
            }
        }
        else {
            if (!this.state.selectedTowVehicleType?.value) {
                NotificationManager.error("Araç Tipi Seçilmedi", null, 2000);
                return;
            }
            if (!this.state.selectedTowKM) {
                NotificationManager.error("Mesefa Girilmedi", null, 2000);
                return;
            }
            if (!this.state.selectedPricingPeriod?.value) {
                NotificationManager.error("Dönem Seçilmedi", null, 2000);
                return;
            }
        }

        this.setState({
            calculatingClasses: "fa-spin ml-2",
            calculateDisabled: true
        })
        console.log(this.state.selectedRacVehicleSegmentDayDuration);
        var request = {
            TowVehicleTypeID: this.state.selectedTowVehicleType.value,
            PeriodID: this.state.selectedPricingPeriod.value,
            TowingDistance: parseInt(this.state.selectedTowKM),
            RacVehicleTypeID: this.state.selectedRacVehicleSegment.value,
            RacDayDuration: parseInt(this.state.selectedRacVehicleSegmentDayDuration),
            RacDropDistance: parseInt(this.state.selectedRacVehicleSegmentDrop),
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        };

        axios.post("api/pricing/getprice", request)
            .then(res => {
                this.setState({
                    calculationResult: res.data.data ?? res.data.message,
                    calculatingClasses: "fa-spin d-none ml-2",
                    calculateDisabled: false
                })
            })
            .catch(function (error) {
                if (error.response) {
                    NotificationManager.error(error.response.data, null, 2000);
                }
            });
    }

    async getProviderPricingInfo() {
        var request = {
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/pricing/getpricingparameters", request)
            .then(res => {
                for (const [, value] of res.data.data.racVehicleTypes.entries()) {
                    this.state.racVehicleSegments.push({ value: value.id, label: value.name });
                }

                for (const [, value] of res.data.data.towVehicleTypes.entries()) {
                    this.state.towVehicleTypes.push({ value: value.id, label: value.name });
                }

                for (const [, value] of res.data.data.pricingPeriods.entries()) {
                    this.state.pricingPeriods.push({ value: value.id, label: value.periodName });
                    if (value.isCurrent)
                        this.setState({ selectedPricingPeriod: { value: value.id, label: value.periodName } });
                }
                this.setState({ pricingParameters: res.data.data })

            });
    }

    componentDidMount() {
        this.getProviderPricingInfo();
    }

    render() {
        return (
            <>
                <Card>
                    <Card.Header>
                        <h5>Ücret Hesaplama</h5>
                    </Card.Header>
                    <Card.Body>
                        <RenderPricingInput
                            pricingTypeID={this.state.pricingParameters?.pricingTypeID}
                            racVehicleSegments={this.state.racVehicleSegments}
                            handleRacVehicleSegmentChange={this.handleRacVehicleSegmentChange}
                            selectedRacVehicleSegment={this.state.selectedRacVehicleSegment}
                            handleIntegerTextInputChange={this.handleIntegerTextInputChange}
                            selectedRacVehicleSegmentDayDuration={this.state.selectedRacVehicleSegmentDayDuration}
                            selectedRacVehicleSegmentDrop={this.state.selectedRacVehicleSegmentDrop}
                            towVehicleTypes={this.state.towVehicleTypes}
                            selectedTowVehicleType={this.state.selectedTowVehicleType}
                            selectedPricingPeriod={this.state.selectedPricingPeriod}
                            handleTowVehicleTypeChange={this.handleTowVehicleTypeChange}
                            handlePricingPeriodChange={this.handlePricingPeriodChange}
                            selectedTowKM={this.state.selectedTowKM}
                            pricingPeriods={this.state.pricingPeriods}
                        />
                        <Row className="flex-row">
                            <Col xs={12} sm={12} md={4}>
                                <Button
                                    variant="primary"
                                    className="float-right mt-2 btn-block"
                                    disabled={this.state.calculateDisabled}
                                    onClick={this.calculatePrice} >
                                    <FontAwesomeIcon icon={faCalculator} className="mr-2" />
                                    <span>Hesapla</span>
                                    <FontAwesomeIcon className={this.state.calculatingClasses} icon={faCircleNotch} />
                                </Button>
                            </Col>
                            <Col xs={12} sm={12} md={8}>
                                <LabeledData label="Ücret (₺):" data={this.state.calculationResult} />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <NotificationContainer />
            </>
        );
    }
}
