import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import unregister from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.css';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

unregister();
ReactDOM.render(
  <BrowserRouter >
    <App />
  </BrowserRouter>,
  rootElement);

//registerServiceWorker();

