import React, { Component } from 'react';

//Bootstrap
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

export class LabeledData extends Component {
    static displayName = LabeledData.name;

    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    render() {
        return (
            <>
                <InputGroup className="mt-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon3" className="rounded-0">
                            <b>{this.props.label}</b>
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl className="rounded-0" disabled value={this.props.data ? this.props.data : "" } />
                </InputGroup>

            </>
        );
    }
}
