import React, { Component } from 'react';
import axios from 'axios';

import { ServiceSearch } from './ServiceSearch';
import { ServiceList } from './ServiceList';
import { InsuranceServiceDetail } from './InsuranceServiceDetail';

//Bootstrap
import Modal from 'react-bootstrap/Modal';

export class InsuranceServices extends Component {
    static displayName = InsuranceServices.name;

    constructor(props) {
        super(props);
        this.state = {
            searchData: { data: { data: [] } },
            serviceData: { data: { data: [] } },
            serviceTableLoading: true
        };
        this.searchServices = this.searchServices.bind(this);
        this.handleserviceDetailModalClose = this.handleserviceDetailModalClose.bind(this);
        this.handleserviceDetailModalOpen = this.handleserviceDetailModalOpen.bind(this);
        this.getInsuranceServiceByID = this.getInsuranceServiceByID.bind(this);
    }

    handleserviceDetailModalClose() {
        this.setState({ showServiceDetailModal: false });
    }
    handleserviceDetailModalOpen() {
        this.setState({ showServiceDetailModal: true });
    }

    async searchServices(request) {
        this.setState({ serviceTableLoading: true });
        axios.post("api/service/getinsuranceservicesbyrequest", request)
            .then(res => {
                if (res.data.data != null)
                    this.setState({ searchData: res }, () => this.setState({ serviceTableLoading: false }))
                else
                    this.setState({ searchData: { data: { data: [] } } }, () => this.setState({ serviceTableLoading: false }))
            });
    }
    async getInsuranceServiceByID(serviceID) {
        var request = {
            ServiceID: serviceID,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/service/getinsuranceservicebyrequest", request)
            .then(res => {
                if (res.data.data != null)
                    this.setState({ serviceData: res })
                this.handleserviceDetailModalOpen();
                //Implement error handling
            });
    }

    render() {
        return (
            <>
                <ServiceSearch searchCallback={this.searchServices} ref={this.state.ref} />
                <ServiceList
                    searchData={this.state.searchData}
                    //serviceDetailCallback={this.handleServiceDetailCallback}
                    serviceTableLoading={this.state.serviceTableLoading}
                    getServiceByID={this.getInsuranceServiceByID}
                    isProtocolHidden={true}
                    isProviderStatusHidden={true}
                    isRaxaStatusHidden={true}
                />
                <Modal dialogClassName="modal-90w" show={this.state.showServiceDetailModal} onHide={() => this.handleserviceDetailModalClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Servis Detayı</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InsuranceServiceDetail service={this.state.serviceData} closeModal={this.handleserviceDetailModalClose} />
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
