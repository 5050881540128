import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';


export class AuthorityForm extends Component {
    static displayName = AuthorityForm.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Card className="mt-3">
                    <Card.Header>
                        <h5>Yetki Formu</h5>
                    </Card.Header>
                    <Card.Body>
                        <p>
                            <b>Şahıs adına olan araçlar için:</b>
                            <br /><br />
                            İlgili yetki belgesinin A4 kağıdı üzerinde ".......... plakalı aracımın yerine tahsis edilecek olan ikame aracın........... TC No'lu ......... ...... tarafından teslim alınmasına ve kullanılmasına yetki veriyorum." şeklinde ve sağ alt köşesinde poliçe sahibinin isim-soyisim ve imzası bulunacak şekilde yönlendirilmesi gerekmektedir.
                            <br /><br />
                            Ek içerisinde poliçe sahibinin kimlik bilgilerinin de bulunması gerekmektedir.
                            <br /><br />
                            Belirtilmiş olan şekilde tarafımıza yönlendirme sağlandığında organizasyon işlemleri başlatılacaktır.
                            <br />
                        </p>
                        <br />
                        <p><b>Firma veya Kurum adına olan araçlar için:</b>
                            <br /><br />
                            İlgili yetki belgesinin A4 kağıdı üzerinde ".......... plakalı aracımın yerine tahsis edilecek olan ikame aracın........... TC No'lu ......... ...... tarafından teslim alınmasına ve kullanılmasına yetki veriyorum." şeklinde ve sağ alt köşesinde firma kaşesi ile yönlendirilmesi gerekmektedir.
                            <br /><br />
                            Firma yetkilisi iseniz ve imza sirküsüne sahip iseniz sadece imza sirküsünün gönderilmesi yeterli olacaktır.
                            <br /><br />
                            Belirtilmiş olan şekilde tarafımıza yönlendirme sağlandığında organizasyon işlemleri başlatılacaktır.
                            <br />
                        </p>
                    </Card.Body>
                </Card>
            </>
        );
    }
}
