import React, { Component } from 'react';
import axios from 'axios';
import { TextInput } from './TextInput';
import { DateInput } from './DateInput';
import { SelectInput } from './SelectInput';

//Bootstrap
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHashtag } from '@fortawesome/free-solid-svg-icons'
import { faCar } from '@fortawesome/free-solid-svg-icons'
import { faMapMarked } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

//React-Notification
import { NotificationContainer, NotificationManager } from 'react-notifications';


export class ServiceSearch extends Component {
    static displayName = ServiceSearch.name;

    constructor(props) {
        super(props);
        this.state = {
            startDate: "",
            endDate: "",
            protocolNumber: null,
            carPlate: "",
            selectedTowns: [],
            searchBtnDisabled: false,
            searchingIconClasses: "fa-spin d-none ml-2",
            towns: [],
            serviceID: null
        };
        this.GetTowns = this.GetTowns.bind(this);
        this.handleSelected = this.handleSelected.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.SearchServices = this.SearchServices.bind(this);
    }
    

    handleChange(evt) {
        const value = evt.target.value;
        this.setState({ [evt.target.name]: value });
    }
    handleDate(moment, name) {
        this.setState({ [name]: moment });
    }
    handleSelected(selectedOptions) {
        this.setState({ selectedTowns: selectedOptions });
    }

    async GetTowns() {
        axios.get("api/parameter/gettowns")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.towns.push({ value: value.tid, label: value.tdescr });
                }
            });
    }
    async SearchServices() {
        var selectedTownIDs = [];
        if (isNaN(this.state.serviceID)) {
            NotificationManager.error("Servis ID rakamlardan oluşmalıdır.");
            return;
        }
        if (this.state.selectedTowns) {
            for (const [, value] of this.state.selectedTowns.entries()) {
                selectedTownIDs.push(value.value);
            }
        }

        if (this.state.startDate !== "") {
            try {
                String(this.state.startDate.unix())
            }
            catch (err) {
                NotificationManager.error("Başlangıç Tarihi Hatalı Girildi.");
                return;
            }
        }
        if (this.state.endDate !== "") {
            try {
                String(this.state.endDate.unix())
            }
            catch (err) {
                NotificationManager.error("Bitiş Tarihi Hatalı Girildi.");
                return;
            }
        }
        
        var request = {
            ProtocolNumber: parseInt(this.state.protocolNumber),
            CarPlate: this.state.carPlate,
            UnixStartDate: this.state.startDate === "" ? "" : String(this.state.startDate.unix()),
            UnixEndDate: this.state.endDate === "" ? "" : String(this.state.endDate.unix()),
            TownIDs: selectedTownIDs,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken"),
            ServiceID: parseInt(this.state.serviceID)
        };

        this.props.searchCallback(request);
    }

    componentDidMount() {
        this.GetTowns();
        this.SearchServices();
    }

    render() {
        return (
            <>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header>
                                <h4>Servis Arama</h4>
                            </Card.Header>
                            <Card.Body>
                                <Form.Group className="mb-0">
                                    <Row>
                                        <Col xs={12} sm={6} md={4}>
                                            <DateInput
                                                timeFormat={false}
                                                label="Başlangıç Tarihi"
                                                placeholder="Başlangıç Tarihi"
                                                handleChange={this.handleDate}
                                                stateName="startDate"
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={4}>
                                            <DateInput
                                                timeFormat={false}
                                                label="Bitiş Tarihi"
                                                placeholder="Bitiş Tarihi"
                                                handleChange={this.handleDate}
                                                stateName="endDate"
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={4}>
                                            <TextInput
                                                faicon={faHashtag}
                                                name="serviceID"
                                                label="Servis ID"
                                                placeholder="Servis ID"
                                                handleChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6} md={4}>
                                            <TextInput
                                                faicon={faHashtag}
                                                name="protocolNumber"
                                                label="Protokol Numarası"
                                                placeholder="Protokol Numarası"                                                
                                                handleChange={this.handleChange}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={4}>
                                            <TextInput
                                                faicon={faCar}
                                                name="carPlate"
                                                label="Araç Plakası"
                                                placeholder="Araç Plakası"
                                                handleChange={this.handleChange}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={4}>
                                            <SelectInput
                                                options={this.state.towns}
                                                handleChange={this.handleSelected}
                                                faicon={faMapMarked}
                                                label="Şehir"
                                                placeholder="Şehir Seçiniz"
                                                isMulti={true}
                                                closeMenuOnSelect={false}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Card.Body>
                            <Card.Footer>
                                <Button variant="primary" disabled={this.state.searchBtnDisabled} onClick={() => this.SearchServices()} >
                                    <FontAwesomeIcon icon={faSearch} className="mr-2" />
                                    <span>Arama Yap</span>
                                    <FontAwesomeIcon className={this.state.searchingIconClasses} icon={faCircleNotch} />
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
                <NotificationContainer />
            </>
        );
    }
}
