import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';


export class CookiePolicy extends Component {
    static displayName = CookiePolicy.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Card className="mt-3">
                    <Card.Header>
                        <h5>Çerez Politikası</h5>
                    </Card.Header>
                    <Card.Body>
                        <p>
                            IPA İstanbul ziyaretiniz sırasında kesinlikle zorunlu çerezleri toplar.
                            <br /><br />
                            Çerez, bir kullanıcı ziyaret ettiğinde dil tercihleriniz veya giriş bilgileriniz gibi sizinle ilgili bilgileri hatırlamak için bir web sitesinin tarayıcınızdan cihazınızda saklamasını istediği küçük bir veri dosyasıdır (metin dosyası). AXA Partners, bu politikada açıklanan amaçlar doğrultusunda <span><a href="https://www.ipaeksper.com">https://www.ipaeksper.com</a></span> adresini ziyaret ettiğinizde "dahili" çerezler toplar ve yerleştirir.
                            <br /><br /><br />
                            <b>MEVCUT POLİTİKANIN GÜNCELLENMESİ </b>
                            <br /><br />
                            IPA İstanbul, <span><a href="https://www.ipaeksper.com">https://www.ipaeksper.com</a></span> web sitesinde gezinirken bırakılan çerezlerle ilgili değişikliklere yanıt olarak bu çerez politikasını zaman zaman güncelleyebilir. Bu gizlilik politikası en son 9 Ekim 2023 tarihinde güncellenmiştir.
                            <br /><br /><br />
                            <b>IPA İSTANBUL'DA KİŞİSEL VERİLERİNİZİ KİMLER İŞLİYOR?</b>
                            <br /><br />
                            INTER PARTNER ASSISTANCE YARDIM VE DESTEK HİZMETLERİ TİCARET ANONİM ŞİRKETİ, Saray Mah. Site Yolu Cad. No:5 Kat:5 34768 İstanbul, Türkiye (" IPA İstanbul " veya " biz " veya " bizim ") kişisel verilerinizi bu çerez politikasında belirtildiği şekilde işlemektedir.
                            <br /><br /><br />
                            <b>KİŞİSEL VERİLERİNİZLE İLGİLİ HAKLARINIZ NELERDİR?</b>
                            <br /><br />
                            Çerezlerin bırakılması, kişisel verilerinizin işlenmesi anlamına gelebilir. Böyle bir durumda ve Kişisel Verilerin Korunması Kanunu’na göre, kullanabileceğiniz haklarınız vardır.Bu haklara başvurmak için lütfen <span><a href="https://www.axapartners.com.tr/tr/gizlilik_politikasi" target="_blank">https://www.axapartners.com.tr/tr/gizlilik_politikasi</a></span> adresimizi inceleyin.
                            <br /><br /><br />
                            <b>HERHANGİ BİR TALEBİ ELE ALMAK VEYA HAKLARINIZI KULLANMAK İÇİN VERİ SORUMLUSU İLE NASIL İLETİŞİME GEÇİLİR?</b>
                            <br /><br />
                            Bu bilgi bildirimi ile ilgili herhangi bir sorunuz, şikayetiniz veya yorumunuz varsa veya yukarıda listelenen haklarınızı kullanmak istiyorsanız, lütfen <span><a href="mailto:interpartnerassistance@hs03.kep.tr">interpartnerassistance@hs03.kep.tr</a></span> veya <span><a href="mailto:info@ipaistanbul.com">info@ipaistanbul.com</a></span> adresine bir e-posta göndererek bunları ele alın. IPA İstanbul, kimliğinizi doğrulamak ve/veya aradığınız verilerin bulunmasına yardımcı olmak için sizden ek bilgi isteyebilir.
                            <br /><br /><br />
                            <b>KİŞİSEL VERİLERİN KORUNMASI KURUMUNA NASIL ŞİKAYETTE BULUNABİLİRİM?</b>
                            <br /><br />
                            Kişisel verilerinizin nasıl işlendiğine ilişkin endişelerinizi, ikamet ettiğiniz, çalıştığınız veya haklarınızın ihlal edildiğini düşündüğünüz Üye Devletteki yetkili bir denetim makamına iletme hakkına sahipsiniz.
                            <br /><br />
                            Türkiye'de kişisel verileri koruma makamı Kişisel Verileri Koruma Kurumu'dur. Taleplerinizi doğrudan <span><a href="https://www.kvkk.gov.tr/" target="_blank">www.kvkk.gov.tr</a></span> adresinden veya aşağıdaki adresten posta yoluyla iletebilirsiniz:
                            <br />Nasuh Akar Mahallesi 1407. Sok. No:4, 06520 Çankaya/Ankara.
                            <br /><br />
                            Avrupa Birliği'ndeki veri koruma makamlarının listesine aşağıdaki bağlantıdan ulaşabilirsiniz:<br />
                            <span><a href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm" target="_blank">https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm</a></span>
                            <br /><br />
                            AB üyesi olmayan ülkeler için lütfen yerel veri koruma otoritenizin web sitesine bakın:<br />
                            <span><a href="https://www.kvkk.gov.tr/" target="_blank">https://www.kvkk.gov.tr</a></span>
                            <br /><br />
                            
                        </p>

                        <p>

                            <b>SİTEMİZDE GEZİNİRKEN KAYDEDİLEN ÇEREZLERİN KATEGORİLERİ VE AMAÇLARI NELERDİR?</b>
                            <br /><br />
                            <div id="ot-sdk-cookie-policy"></div>
                            <br /><br />
                            <b>ÇEREZ TERCİHLERİ </b>
                            <br /><br />
                            <span><a href="https://www.ipaeksper.com">https://www.ipaeksper.com</a></span> web sitesinde toplanan çerezler, web sitesinin düzgün çalışması için kesinlikle gereklidir. Bu nedenle çerezlerin toplanması zorunludur.
                            <br /><br />
                            Çerezlerin nasıl silineceği veya reddedileceğine ilişkin ayrıntılar ve genel olarak çerezler hakkında daha fazla bilgi için <span><a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a></span> adresini de ziyaret edebilirsiniz.

                        </p>

                        <br />
                    </Card.Body>
                </Card>
            </>
        );
    }
}


/*
 * <!-- OneTrust Cookies List start -->
 *  <div id="ot-sdk-cookie-policy"></div>
 * <!-- OneTrust Cookies List end -->
 * Added on 16/10/2023
 */