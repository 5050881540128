import React, { Component } from 'react';
import axios from 'axios';

//Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faSave } from '@fortawesome/free-solid-svg-icons'

//React-Notification
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { LabeledData } from './LabeledData';
import { TextInput } from './TextInput';

export class UserSettings extends Component {
    static displayName = UserSettings.name;

    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            uppercaseIcon: faTimesCircle,
            uppercaseIconClass: "mr-1 text-danger",
            lowercaseIcon: faTimesCircle,
            lowercaseIconClass: "mr-1 text-danger",
            numberIcon: faTimesCircle,
            numberIconClass: "mr-1 text-danger",
            symbolIcon: faTimesCircle,
            symbolIconClass: "mr-1 text-danger",
            eightCharIcon: faTimesCircle,
            eightCharIconClass: "mr-1 text-danger",
            matchIcon: faTimesCircle,
            matchIconClass: "mr-1 text-danger",
            updatePasswordLoadingIconClass: "d-none",
            updatePasswordButtonDisabled: false,
            oldPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
            passwordRulesColClass: "d-none"
        };
        this.getUserData = this.getUserData.bind(this);
        this.handlebtnChangePassword = this.handlebtnChangePassword.bind(this);
        this.handleTextInputChange = this.handleTextInputChange.bind(this);
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleNewPasswordConfirmChange = this.handleNewPasswordConfirmChange.bind(this);
    }

    handleTextInputChange(evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    handleNewPasswordChange(evt) {
        if (evt.target.value.length !== 0)
            this.setState({ passwordRulesColClass: "smalltext" });
        this.setState({ newPassword: evt.target.value });
        //Check UpperCase
        if ((/[A-Z]/.test(evt.target.value))) {
            this.setState({
                uppercaseIcon: faCheckCircle,
                uppercaseIconClass: "mr-1 text-success",
            });
        }
        else {
            this.setState({
                uppercaseIcon: faTimesCircle,
                uppercaseIconClass: "mr-1 text-danger",
            });
        }

        //Check LowerCase
        if ((/[a-z]/.test(evt.target.value))) {
            this.setState({
                lowercaseIcon: faCheckCircle,
                lowercaseIconClass: "mr-1 text-success",
            });
        }
        else {
            this.setState({
                lowercaseIcon: faTimesCircle,
                lowercaseIconClass: "mr-1 text-danger",
            });
        }

        //Check Number
        if ((/[0-9]/.test(evt.target.value))) {
            this.setState({
                numberIcon: faCheckCircle,
                numberIconClass: "mr-1 text-success",
            });
        }
        else {
            this.setState({
                numberIcon: faTimesCircle,
                numberIconClass: "mr-1 text-danger",
            });
        }

        //Check Number
        if ((/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(evt.target.value))) {
            this.setState({
                symbolIcon: faCheckCircle,
                symbolIconClass: "mr-1 text-success"
            });
        }
        else {
            this.setState({
                symbolIcon: faTimesCircle,
                symbolIconClass: "mr-1 text-danger",
            });
        }

        //Check Count
        if (evt.target.value.length >= 8) {
            this.setState({
                eightCharIcon: faCheckCircle,
                eightCharIconClass: "mr-1 text-success",
            });
        }
        else {
            this.setState({
                eightCharIcon: faTimesCircle,
                eightCharIconClass: "mr-1 text-danger",
            });
        }

        //Check Match
        if (evt.target.value === this.state.newPasswordConfirm && evt.target.value !=="") {
            this.setState({
                matchIcon: faCheckCircle,
                matchIconClass: "mr-1 text-success",
            });
        }
        else {
            this.setState({
                matchIcon: faTimesCircle,
                matchIconClass: "mr-1 text-danger",
            });
        }

    }
    handleNewPasswordConfirmChange(evt) {
        this.setState({ newPasswordConfirm: evt.target.value });
        //Check Match
        if (evt.target.value === this.state.newPassword && evt.target.value !== "") {
            this.setState({
                matchIcon: faCheckCircle,
                matchIconClass: "mr-1 text-success",
            });
        }
        else {
            this.setState({
                matchIcon: faTimesCircle,
                matchIconClass: "mr-1 text-danger",
            });
        }
    }
    async handlebtnChangePassword() {
        this.setState({
            updatePasswordLoadingIconClass: "fa-spin ml-2",
            updatePasswordButtonDisabled: true
        });
        if (!(/[A-Z]/.test(this.state.newPassword)) ||
            !(/[a-z]/.test(this.state.newPassword)) ||
            !(/[0-9]/.test(this.state.newPassword)) ||
            !(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(this.state.newPassword)) ||
            this.state.newPassword.length < 8 ||
            this.state.newPassword !== this.state.newPasswordConfirm
        ) {
            NotificationManager.error("Şifre kuralları sağlanmadı");
            this.setState({
                updatePasswordLoadingIconClass: "d-none",
                updatePasswordButtonDisabled: false
            });
            return;
        }
        var request =
        {
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken"),
            Password: this.state.newPassword,
            OldPassword: this.state.oldPassword
        }
        axios.post("api/user/renewpassword/", request)
            .then(res => {
                if (res.data?.isSuccess) {
                    this.setState({
                        newPassword: "",
                        oldPassword: "",
                        newPasswordConfirm: "",
                        updatePasswordLoadingIconClass: "d-none",
                        updatePasswordButtonDisabled: false
                    }, () => {
                        NotificationManager.success("Şifre başarı ile değiştirildi.");
                    });
                } else {
                    NotificationManager.error("Hatalı İşlem");
                    this.setState({
                        updatePasswordLoadingIconClass: "d-none",
                        updatePasswordButtonDisabled: false
                    });
                }
            });
    }

    async getUserData() {
        var request = {
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/user/getuserinfo/", request)
            .then(res => {
                if (res.data.isSuccess) {
                    this.setState({ userData: res.data.data });
                }
                else
                    NotificationManager.error("Kullanıcı Bilgileri Alınamadı");
            });
    }

    componentDidMount() {
        this.getUserData();
    }


    render() {
        return (
            <>
                <Card className="mt-3">
                    <Card.Header>
                        <h5>Kullanıcı Bilgileri</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={6} xs={12}>
                                <LabeledData
                                    label="İsim"
                                    data={this.state.userData.userName}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} xs={12}>
                                <LabeledData
                                    label="Kullanıcı Adı"
                                    data={this.state.userData.userVisibleName}
                                />
                            </Col>
                            <Col sm={6} xs={12}>
                                <LabeledData
                                    label="E-mail"
                                    data={this.state.userData.userEmail}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Header>
                        <h5>Şifre Değişikliği</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={6} xs={12}>
                                <TextInput
                                    name="oldPassword"
                                    faicon={faKey}
                                    label="Eski Şifre"
                                    placeholder="Eski şifrenizi giriniz"
                                    type="password"
                                    value={this.state.oldPassword}
                                    handleChange={this.handleTextInputChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} xs={12}>
                                <TextInput
                                    name="newPassword"
                                    faicon={faLock}
                                    label="Yeni Şifre"
                                    placeholder="Yeni şifrenizi giriniz"
                                    value={this.state.newPassword}
                                    handleChange={this.handleNewPasswordChange}
                                    type="password"
                                />
                            </Col>
                            <Col sm={6} xs={12}>
                                <TextInput
                                    name="newPasswordConfirm"
                                    faicon={faLock}
                                    label="Yeni Şifre Doğrulama"
                                    placeholder="Yeni şifrenizi tekrar giriniz"
                                    value={this.state.newPasswordConfirm}
                                    handleChange={this.handleNewPasswordConfirmChange}
                                    type="password"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} xs={12} >
                                <ListGroup as="ul" className={this.state.passwordRulesColClass}>
                                    <ListGroup.Item as="li" active className="p-1">Şifre Gereksinimleri</ListGroup.Item>
                                    <ListGroup.Item as="li" className="p-1">
                                        <FontAwesomeIcon className={this.state.uppercaseIconClass} icon={this.state.uppercaseIcon} />
                                        Bir adet büyük harf
                                        </ListGroup.Item>
                                    <ListGroup.Item as="li" className="p-1">
                                        <FontAwesomeIcon className={this.state.lowercaseIconClass} icon={this.state.lowercaseIcon} />
                                        Bir adet küçük harf
                                        </ListGroup.Item>
                                    <ListGroup.Item as="li" className="p-1">
                                        <FontAwesomeIcon className={this.state.numberIconClass} icon={this.state.numberIcon} />
                                        Bir adet rakam
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className="p-1">
                                        <FontAwesomeIcon className={this.state.symbolIconClass} icon={this.state.symbolIcon} />
                                        Bir adet sembol
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className="p-1">
                                        <FontAwesomeIcon className={this.state.eightCharIconClass} icon={this.state.eightCharIcon} />
                                        Minimum 8 karakter
                                        </ListGroup.Item>
                                    <ListGroup.Item as="li" className="p-1">
                                        <FontAwesomeIcon className={this.state.matchIconClass} icon={this.state.matchIcon} />
                                        İki şifre aynı olmalı
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col sm={6} xs={12}>
                                <Button variant="primary" className="btn-block" disabled={this.state.updatePasswordButtonDisabled} onClick={this.handlebtnChangePassword} >
                                    <FontAwesomeIcon icon={faSave} className="mr-2" />
                                    <span>Şifreyi Değiştir</span>
                                    <FontAwesomeIcon className={this.state.updatePasswordLoadingIconClass} icon={faCircleNotch} />
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <NotificationContainer />
            </>
        );
    }
}