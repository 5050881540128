import React, { Component } from 'react';
import axios from 'axios'
//Custom Components
import { TextInput } from './TextInput';
import { SelectInput } from './SelectInput';
import { DateInput } from './DateInput';
//Bootstrap
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';
//DataTable
import DataTable from 'react-data-table-component';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faHashtag } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faIdCard } from '@fortawesome/free-solid-svg-icons'
import { faBox } from '@fortawesome/free-solid-svg-icons'
import { faCar } from '@fortawesome/free-solid-svg-icons'
import { faCarSide } from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faBoxes } from '@fortawesome/free-solid-svg-icons'
import { faTint } from '@fortawesome/free-solid-svg-icons'
import { faDirections } from '@fortawesome/free-solid-svg-icons'
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
//moment
import "moment/locale/tr";
import moment from 'moment';
//React-Notification
import { NotificationContainer, NotificationManager } from 'react-notifications';

//Conditional Rendering
function RenderTowingInfo(props) {
    if (!props.selectedServiceTypes?.length > 0)
        return (<></>);
    var render = false;
    for (const [, value] of props.selectedServiceTypes.entries()) {
        if (value.value === 1) {
            render = true;
            break;
        }
    }
    if (render) {
        return (
            <>
                <hr />
                <Card>
                    <Card.Header>
                        <h5>Çekici Bilgileri</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={12} sm={6}>
                                <label>Servis Adı</label>
                                <FormControl
                                    as="textarea"
                                    name="towingServiceName"
                                    className="rounded-0 noresize"
                                    placeholder="Çekilecek servisin adı"
                                    rows={3}
                                    onChange={props.handleTextInputChange}
                                    value={props.towingServiceName}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <label>Hizmet Adresi</label>
                                <FormControl
                                    as="textarea"
                                    name="towingServiceAddress"
                                    className="rounded-0 noresize"
                                    placeholder="Hizmet verilecek adresin açık tarifi"
                                    rows={3}
                                    onChange={props.handleTextInputChange}
                                    value={props.towingServiceAddress}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>
        )
    }
    return (<></>);
}
function RenderRentalInfo(props) {
    if (!props.selectedServiceTypes?.length > 0)
        return (<></>);
    var render = false;
    for (const [, value] of props.selectedServiceTypes.entries()) {
        if (value.value === 2) {
            render = true;
            break;
        }
    }
    if (render) {
        return (
            <>
                <hr />
                <Card>
                    <Card.Header>
                        <h5>Ikame Bilgileri</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={12} sm={6}>
                                <DateInput
                                    timeFormat={false}
                                    label="İkame Teslim Tarihi"
                                    placeholder="İkame Teslim Tarihi Seçiniz"
                                    handleChange={props.handleRacDeliveryDateChange}
                                    value={props.racDeliveryDate ? props.racDeliveryDate : ""}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <SelectInput
                                    options={props.racVehicleSegments}
                                    handleChange={props.handleRacVehicleSegmentChange}
                                    label="Araç Grubu"
                                    placeholder="Araç Grubu Seçiniz"
                                    faicon={faCar}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={!props.racVehicleSegment.id ? null : { value: props.racVehicleSegment.id, label: props.racVehicleSegment.vehicleSegmentName }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6}>
                                <SelectInput
                                    options={props.racVehicleGearTypes}
                                    handleChange={props.handleRacVehicleGearTypeChange}
                                    label="Şanzıman Tipi"
                                    placeholder="Şanzıman Tipi Seçiniz"
                                    faicon={faCarSide}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={!props.racVehicleGearType.id ? null : { value: props.racVehicleGearType.id, label: props.racVehicleGearType.gearTypeName }}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <SelectInput
                                    options={props.racVehicleFuelTypes}
                                    handleChange={props.handleRacVehicleFuelTypeChange}
                                    label="Yakıt Tipi"
                                    placeholder="Yakıt Tipi Seçiniz"
                                    faicon={faTint}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={!props.racVehicleFuelType.id ? null : { value: props.racVehicleFuelType.id, label: props.racVehicleFuelType.fuelTypeName }}
                                />
                            </Col>
                        </Row>
                        <hr/>
                        <Row className="">
                            <Col xs={12} sm={6}>
                                <TextInput
                                    faicon={faUndoAlt}
                                    label="Araç Dönüş Durumu"
                                    placeholder="Araç Dönüşü"
                                    value={props.racReturnDate ? props.racReturnUser.userVisibleName + " Tarafından Dönüş Yapılmış" : "Araç Dönüşü Yapılmamış"}
                                    buttonText="Dönüş Yap"
                                    disabled={true}
                                    buttonEvent={props.returnRentalVehicle}
                                    btnIsLoading={props.racReturnLoading}
                                    btnDisabled={props.racReturnDate ? true:false}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <DateInput
                                    timeFormat={false}
                                    label="İkame Dönüş Tarihi"
                                    placeholder="İkame Teslim Tarihi Seçiniz"
                                    disabled={true}
                                    value={props.racReturnDate ? props.racReturnDate.format("DD.MM.YYYY hh:mm") : ""}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>
        )
    }
    return (<></>);
}
function RenderValetInfo(props) {
    if (!props.selectedServiceTypes?.length > 0)
        return (<></>);
    var render = false;
    for (const [, value] of props.selectedServiceTypes.entries()) {
        if (value.value === 3) {
            render = true;
            break;
        }
    }
    if (render) {
        return (
            <>
                <hr />
                <Card>
                    <Card.Header>
                        <h5>Vale Bilgileri</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={12} sm={6}>
                                <DateInput
                                    timeFormat={false}
                                    label="Vale Talep Tarihi"
                                    placeholder="Vale Talep Tarihi Seçiniz"
                                    handleChange={props.handleValetRequestDateChange}
                                    value={props.valetRequestDate ? props.valetRequestDate : ""}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <SelectInput
                                    options={props.valetDirections}
                                    handleChange={props.handleValetDirectionChange}
                                    label="Vale Yönü"
                                    placeholder="Vale Yönü Seçiniz"
                                    faicon={faDirections}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={!props.valetDirection.id ? null : { value: props.valetDirection.id, label: props.valetDirection.valetDirection }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6}>
                                <label>Servis Adı</label>
                                <FormControl
                                    as="textarea"
                                    name="valetServiceName"
                                    className="rounded-0 noresize"
                                    placeholder="Hizmet verilecek servisin adı"
                                    rows={3}
                                    onChange={props.handleTextInputChange}
                                    value={props.valetServiceName}
                                />
                            </Col>
                            <Col xs={12} sm={6}>
                                <label>Hizmet Adresi</label>
                                <FormControl
                                    as="textarea"
                                    name="valetServiceAddress"
                                    className="rounded-0 noresize"
                                    placeholder="Hizmet verilecek adresin açık tarifi"
                                    rows={3}
                                    onChange={props.handleTextInputChange}
                                    value={props.valetServiceAddress}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>
        )
    }
    return (<></>);
}

export class RequestedCases extends Component {
    static displayName = RequestedCases.name;

    constructor(props) {
        super(props);
        this.state = {
            requestedCasesColumns: [
                {
                    cell: row =>
                        <Button variant="primary" onClick={() => this.getCaseRequestByID(row.id)}>
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                    ,
                    button: true,
                },
                {
                    name: "Plaka",
                    selector: "plateNumber",
                    sortable: true,
                },
                {
                    name: "Ad Soyad",
                    selector: "policyHoderName",
                    sortable: true,
                },
                {
                    name: "Poliçe No",
                    selector: "policyNumber",
                    sortable: true,
                    wrap: true
                },
                {
                    name: "Talepler",
                    cell: row => {
                        var requestsText = ""
                        row.serviceTypes.forEach((value) => {
                            requestsText = requestsText ? requestsText + ", " + value.serviceType : value.serviceType
                        });
                        return (<>{requestsText}</>);
                    },
                    sortable: true,
                    wrap: true
                },
                {
                    name: "Durum",
                    selector: "status",
                    sortable: true,
                    wrap: true
                },
                {
                    name: "Oluşturan",
                    selector: "requestCreatorUserVisibleName",
                    sortable: true,
                    wrap: true
                },
                {
                    name: "Oluşturma Tarihi",
                    sortable: true,
                    cell: row => <>{moment(row.dateRequestCreated).format("DD.MM.YYYY HH:mm")}</>,
                    selector: row => moment(row.dateRequestCreated).unix()
                },
                {
                    name: "İşleme Alan",
                    selector: "caseCreatorUserName",
                    sortable: true,
                    wrap: true
                },
                {
                    name: "İşleme Alma Tarihi",
                    sortable: true,
                    cell: row => <>{row.dateCaseCreated ? moment(row.dateCaseCreated).format("DD.MM.YYYY HH:mm") : ""}</>,
                    selector: row => row.dateCaseCreated ? moment(row.dateCaseCreated).unix() : ""
                },
            ],
            requestedCases: [],
            requestedCasesLoading: false,
            searchPolicyNumber: "",
            searchPlateNumber: "",
            dateStart: "",
            dateEnd: "",
            showCaseRequestDetailModal: false,
            policyNumber: "",
            policyHolderName: "",
            policyHoderPlate: "",
            policyHolderProduct: "",
            policyHolderVehicleBrand: "",
            policyHolderVehicleModel: "",
            policyHolderVehicleModelYear: "",
            callerName: "",
            callerPhone: "",
            callerTown: {},
            callerTownArea: {},
            callerDistrict: "",
            callerReasonOfCall: {},
            callerDescription: "",
            callerServiceTypes: [],
            towingServiceName: "",
            towingServiceAddress: "",
            racDeliveryDate: "",
            racVehicleSegment: {},
            racVehicleGearType: {},
            racVehicleFuelType: {},
            racReturnDate: "",
            racReturnUser: "",
            valetDirection: {},
            valetRequestDate: "",
            valetServiceName: "",
            valetServiceAddress: "",
            caseOpenedByUser: {},
            towns: [],
            townAreas: [],
            callReasons: [],
            serviceTypes: [],
            racVehicleSegments: [],
            racVehicleGearTypes: [],
            racVehicleFuelTypes: [],
            valetDirections: [],
            racReturnLoading: false,
            currentRequestID: 0,
            searchIconClasses: "d-none",
            saveIconClasses:"fa-spin d-none ml-2"
        };
        this.handleTextInputChange = this.handleTextInputChange.bind(this);
        this.handleIntegerTextInputChange = this.handleIntegerTextInputChange.bind(this);
        this.handlePhoneTextInputChange = this.handlePhoneTextInputChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleTownChange = this.handleTownChange.bind(this);
        this.handleTownAreaChange = this.handleTownAreaChange.bind(this);
        this.handleRequestCallReasonChange = this.handleRequestCallReasonChange.bind(this);
        this.handleRacDeliveryDateChange = this.handleRacDeliveryDateChange.bind(this);
        this.handleRacVehicleSegmentChange = this.handleRacVehicleSegmentChange.bind(this);
        this.handleRacVehicleGearTypeChange = this.handleRacVehicleGearTypeChange.bind(this);
        this.handleRacVehicleFuelTypeChange = this.handleRacVehicleFuelTypeChange.bind(this);
        this.handleValetDirectionChange = this.handleValetDirectionChange.bind(this);
        this.handleValetRequestDateChange = this.handleValetRequestDateChange.bind(this);
        this.getCaseRequestList = this.getCaseRequestList.bind(this);
        this.getTowns = this.getTowns.bind(this);
        this.getTownAreasByTownID = this.getTownAreasByTownID.bind(this);
        this.getCaseRequestCallReasonList = this.getCaseRequestCallReasonList.bind(this);
        this.getCaseRequestServiceTypeList = this.getCaseRequestServiceTypeList.bind(this);
        this.getVehicleSegmentList = this.getVehicleSegmentList.bind(this);
        this.getGearTypeList = this.getGearTypeList.bind(this);
        this.getFuelTypeList = this.getFuelTypeList.bind(this);
        this.getValetDirectionsList = this.getValetDirectionsList.bind(this);
        this.getCustomerByPolicyNumber = this.getCustomerByPolicyNumber.bind(this);
        this.returnRentalVehicle = this.returnRentalVehicle.bind(this);
        this.updateCaseRequest = this.updateCaseRequest.bind(this);
        this.exportDataToExcel = this.exportDataToExcel.bind(this);
        this.saveAsExcelFile = this.saveAsExcelFile.bind(this);
    }

    handleTextInputChange(evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    handleIntegerTextInputChange(evt) {
        if ((String(parseInt(evt.target.value)) === evt.target.value && parseInt(evt.target.value) > 0) || evt.target.value === "")
            this.setState({ [evt.target.name]: evt.target.value });
    }
    handlePhoneTextInputChange(evt) {
        if (evt.target.value === "") {
            this.setState({ [evt.target.name]: evt.target.value });

        } else {
            var validNumber = true;
            if (evt.target.value.split("")[0] !== "0") {
                evt.target.value = "0" + evt.target.value
            }
            if (evt.target.value.length > 11) {
                validNumber = false;
            }
            evt.target.value.split("").forEach((v) => {
                if (isNaN(v)) {
                    validNumber = false;
                }
            });
            if (!validNumber) {
                return;
            }
            this.setState({ [evt.target.name]: evt.target.value });
        }
    }
    handleStartDateChange(moment) {
        this.setState({ dateStart: moment });
    }
    handleEndDateChange(moment) {
        this.setState({ dateEnd: moment });
    }
    handleCaseRequestDetailModalClose() {
        this.setState({ showCaseRequestDetailModal: false });
    }
    handleTownChange(selectedOption) {
        this.setState({ townAreas: [] }, () =>
            this.setState({
                callerTown: {
                    tid: selectedOption.value,
                    tdescr: selectedOption.label
                }
            }, () => this.getTownAreasByTownID(this.state.callerTown.tid))
        );
    }
    handleTownAreaChange(selectedOption) {
        this.setState({
            callerTownArea: {
                taid: selectedOption.value,
                tadescr: selectedOption.label
            }
        });
    }
    handleRequestCallReasonChange(selectedOption) {
        this.setState({
            callerReasonOfCall: {
                id: selectedOption.value,
                callReason: selectedOption.label
            }
        });
    }
   
    handleRacDeliveryDateChange(moment) {
        this.setState({ racDeliveryDate: moment });
    }
    handleRacVehicleSegmentChange(selectedOption) {
        this.setState({
            racVehicleSegment: {
                id: selectedOption.value,
                vehicleSegmentName: selectedOption.label
            }
        });
    }
    handleRacVehicleGearTypeChange(selectedOption) {
        this.setState({
            racVehicleGearType: {
                id: selectedOption.value,
                GearTypeName: selectedOption.label
            }
        });
    }
    handleRacVehicleFuelTypeChange(selectedOption) {
        this.setState({
            racVehicleFuelType: {
                id: selectedOption.value,
                FuelTypeName: selectedOption.label
            }
        });
    }
    handleValetDirectionChange(selectedOption) {
        this.setState({
            valetDirection: {
                id: selectedOption.value,
                valetDirection: selectedOption.label
            }
        });
    }
    handleValetRequestDateChange(moment) {
        this.setState({ valetRequestDate: moment });
    }
    saveAsExcelFile(buffer, fileName) {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }
    exportDataToExcel() {
        var exportData = [];
        this.state.requestedCases.forEach(item => {
            var requestType = "";
            item.serviceTypes.forEach(rt => {
                requestType = requestType +","+ rt.serviceType
            });
            requestType = requestType.substring(1);
            var exportRecord = {
                "Plaka": item.plateNumber?.trim(),
                "Ad Soyad": item.policyHoderName?.trim(),
                "Poliçe No": item.policyNumber?.trim(),
                "Talepler": requestType,
                "Durum": item.status?.trim(),
                "Oluşturan": item.requestCreatorUserVisibleName?.trim(),
                "Oluşturma Tarih": moment(item.dateRequestCreated).format('DD.MM.YYYY HH:mm'),
            }
            exportData.push(exportRecord);
        });

        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(exportData);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'export');
        });
        return;
    }

    async getCaseRequestList() {
        this.setState({
            ...this.state,
            requestedCasesLoading: true,
            searchIconClasses: "fa-spin ml-2"
        })
        var request =
        {
            PolicyNumber: this.state.searchPolicyNumber,
            PlateNumber: this.state.searchPlateNumber,
            CaseRequestUnixDateStart: this.state.dateStart ? String(this.state.dateStart.unix()) : "",
            CaseRequestUnixDateEnd: this.state.dateEnd ? String(this.state.dateEnd.unix()) : "",
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/caserequest/getcaserequestlist/", request)
            .then(res => {
                if (res.data?.isSuccess) {
                    if (res.data.data.length > 0) {
                        this.setState({ requestedCases: res.data.data }, () => NotificationManager.success(res.data.data.length + ' Adet Dosya Talebi Başarı İle Listelendi.'));
                    }
                    else {
                        NotificationManager.warning("Eşleşen Bir Kayıt Bulunamadı");
                    }
                } else {
                    NotificationManager.warning("Talep Bulunamadı");
                }
                this.setState({
                    ...this.state,
                    requestedCasesLoading: false,
                    searchIconClasses: "d-none"
                });
            });
    }
    async getCaseRequestByID(id) {
        var request =
        {
            ID:id,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/caserequest/getcaserequestbyrequest/", request)
            .then(res => {
                if (res.data?.isSuccess) {
                    var newState = this.state;
                    newState.currentRequestID = id;
                    newState.policyNumber = res.data?.data?.policyNumber;
                    newState.policyHolderName = res.data?.data?.policyHolderName;
                    newState.policyHoderPlate = res.data?.data?.policyHoderPlate;
                    newState.policyHolderProduct = res.data?.data?.policyHolderProduct;
                    newState.policyHolderVehicleBrand = res.data?.data?.policyHolderVehicleBrand;
                    newState.policyHolderVehicleModel = res.data?.data?.policyHolderVehicleModel;
                    newState.policyHolderVehicleModelYear = res.data?.data?.policyHolderVehicleModelYear;
                    newState.callerName = res.data?.data?.callerName;
                    newState.callerPhone = res.data?.data?.callerPhone;
                    newState.callerTown = {
                        tid: res.data?.data?.callerTown?.tid,
                        tdescr: res.data?.data?.callerTown?.tdescr,
                    };
                    newState.callerTownArea = {
                        taid: res.data?.data?.callerTownArea?.taid,
                        tadescr: res.data?.data?.callerTownArea?.tadescr,
                    };;
                    newState.callerDistrict = res.data?.data?.callerDistrict;
                    newState.callerReasonOfCall = {
                        id: res.data?.data?.callerReasonOfCall?.id,
                        callReason: res.data?.data?.callerReasonOfCall?.callReason
                    };
                    newState.callerDescription = res.data?.data?.callerDescription;
                    newState.towingServiceName = res.data?.data?.towingServiceName;
                    newState.towingServiceAddress = res.data?.data?.towingServiceAddress;
                    newState.racDeliveryDate = moment(res.data?.data?.racDeliveryDate);
                    newState.racVehicleSegment = {
                        id: res.data?.data?.racVehicleSegment?.id,
                        vehicleSegmentName: res.data?.data?.racVehicleSegment?.vehicleSegmentName,
                    };
                    newState.racVehicleGearType = {
                        id: res.data?.data?.racVehicleGearType?.id,
                        gearTypeName: res.data?.data?.racVehicleGearType?.gearTypeName
                    };
                    newState.racVehicleFuelType = {
                        id: res.data?.data?.racVehicleFuelType?.id,
                        fuelTypeName: res.data?.data?.racVehicleFuelType?.fuelTypeName
                    };
                    newState.racReturnDate = res.data?.data?.returnDate ? moment(res.data?.data?.returnDate) : "";
                    newState.racReturnUser = res.data?.data?.returnUser;
                    newState.valetDirection = {
                        id: res.data?.data?.valetDirection?.id,
                        valetDirection: res.data?.data?.valetDirection?.valetDirection
                    };
                    newState.valetRequestDate = moment(res.data?.data?.valetRequestDate);
                    newState.valetServiceName = res.data?.data?.valetServiceName;
                    newState.valetServiceAddress = res.data?.data?.valetServiceAddress;
                    newState.showCaseRequestDetailModal = true;
                    newState.callerServiceTypes = [];
                    for (const [, value] of res.data?.data?.callerServiceTypes.entries()) {
                        newState.callerServiceTypes.push({
                            value: value.id,
                            label: value.serviceType
                        });
                    }
                    newState.caseOpenedByUser = res.data?.data?.caseOpenedByUser;
                    this.setState(newState, () => {
                        NotificationManager.success("Dosya Talebi Bilgileri Alındı.");
                        this.getTownAreasByTownID(this.state.callerTown.tid);
                    });
                } else {
                    NotificationManager.warning("Bir Hata İle Karşılaşıldı");
                }
            });
    }
    async getTowns() {
        axios.get("api/parameter/gettowns")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.towns.push({ value: value.tid, label: value.tdescr });
                }
            });
    }
    async getTownAreasByTownID(townid) {
        axios.get("api/parameter/gettownareasbytownid/" + townid)
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.townAreas.push({ value: value.taid, label: value.tadescr });
                }
            });
    }
    async getCaseRequestCallReasonList() {
        axios.get("api/parameter/getcaserequestcallreasonlist")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.callReasons.push({ value: value.id, label: value.callReason });
                }
            });
    }
    async getCaseRequestServiceTypeList() {
        axios.get("api/parameter/getcaserequestservicetypelist")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.serviceTypes.push({ value: value.id, label: value.serviceType, blackList: value.blackListedServiceType, isDisabled: false });
                }
            });
    }
    async getVehicleSegmentList() {
        axios.get("api/parameter/getvehiclesegments")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.racVehicleSegments.push({ value: value.id, label: value.vehicleSegmentName });
                }
            });
    }
    async getGearTypeList() {
        axios.get("api/parameter/getgeartypes")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.racVehicleGearTypes.push({ value: value.id, label: value.gearTypeName });
                }
            });
    }
    async getFuelTypeList() {
        axios.get("api/parameter/getfueltypes")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.racVehicleFuelTypes.push({ value: value.id, label: value.fuelTypeName });
                }
            });
    }
    async getValetDirectionsList() {
        axios.get("api/parameter/getvaletdirections")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.valetDirections.push({ value: value.id, label: value.valetDirection });
                }
            });
    }
    async getCustomerByPolicyNumber() {
        if (!this.state.policyNumber) {
            NotificationManager.error("Poliçe Numarası Girilmedi");
            return;
        }
        this.setState({ searchIsLoading: true })
        var request =
        {
            PolicyNumber: this.state.policyNumber,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/caserequest/getpolicyholderbyrequest/", request)
            .then(res => {
                if (res.data?.isSuccess) {
                    if (res.data.data?.policyFound) {
                        this.setState({
                            ...this.state,
                            policyHolderName: res.data.data.customerFullName,
                            policyHoderPlate: res.data.data.customerPlateNumber,
                            policyHolderProduct: res.data.data.productName,
                            policyHolderVehicleBrand: res.data.data.vehicleBrand,
                            policyHolderVehicleModel: res.data.data.vehicleModel,
                            policyHolderVehicleModelYear: res.data.data.vehicleModelYear,
                            policyManuelEntry: false,
                            searchIsLoading: false
                        });
                        NotificationManager.success("Sigortalı Bulundu");
                    } else {
                        this.setState({
                            ...this.state,
                            policyManuelEntry: true,
                            searchIsLoading: false
                        });
                        NotificationManager.warning("Sigortalı Bulunamadı. Bilgileri Manuel Girerek İşme Yapabilirsiniz");
                    }
                } else {
                    this.setState({
                        ...this.state,
                        policyManuelEntry: true,
                        searchIsLoading: false
                    });
                    NotificationManager.warning("Sigortalı Bulunamadı. Bilgileri Manuel Girerek İşme Yapabilirsiniz");
                }
            });
    }
    async returnRentalVehicle() {
        this.setState({ racReturnLoading: true });
        var request =
        {
            ID: this.state.currentRequestID,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/caserequest/returnrentalvehicle/", request)
            .then(res => {
                if (res.data?.isSuccess) {
                    if (res.data.data) {
                        NotificationManager.success("Dönüş İşlemi Başarılı");
                        this.setState({ racReturnLoading: false },
                            () => this.getCaseRequestByID(this.state.currentRequestID));
                    } else {
                        NotificationManager.error("Dönüş İşlemi Başarısız");
                        this.setState({ racReturnLoading: false });
                    }
                } else {
                    NotificationManager.error("Dönüş İşlemi Başarısız");
                    this.setState({ racReturnLoading: false });
                }
            });
    }
    async updateCaseRequest() {
        this.setState({ saveIconClasses: "fa-spin ml-2" });
        var request =
        {
            ID: this.state.currentRequestID,
            PolicyNumber: this.state.policyNumber,
            PolicyHolderName: this.state.policyHolderName,
            PolicyHoderPlate: this.state.policyHoderPlate,
            PolicyHolderProduct: this.state.policyHolderProduct,
            PolicyHolderVehicleBrand: this.state.policyHolderVehicleBrand,
            PolicyHolderVehicleModel: this.state.policyHolderVehicleModel,
            PolicyHolderVehicleModelYear: parseInt(this.state.policyHolderVehicleModelYear),
            CallerName: this.state.callerName,
            CallerPhone: this.state.callerPhone,
            CallerTownID: this.state.callerTown?.tid,
            CallerTownAreaID: this.state.callerTownArea?.taid,
            CallerDistrict: this.state.callerDistrict,
            CallerReasonOfCallID: this.state.callerReasonOfCall?.id,
            CallerDescription: this.state.callerDescription,
            CallerServiceTypeIDs: [],
            TowingServiceName: this.state.towingServiceName,
            TowingServiceAddress: this.state.towingServiceAddress,
            RacDeliveryUnixDate: this.state.racDeliveryDate ? String(this.state.racDeliveryDate.unix()) : "",
            RacVehicleSegmentID: this.state.racVehicleSegment?.id,
            RacVehicleGearTypeID: this.state.racVehicleGearType?.id,
            RacVehicleFuelTypeID: this.state.racVehicleFuelType?.id,
            ValetDirectionID: this.state.valetDirection?.id,
            ValetRequestUnixDate: this.state.valetRequestDate ? String(this.state.valetRequestDate.unix()) : "",
            ValetServiceName: this.state.valetServiceName,
            ValetServiceAddress: this.state.valetServiceAddress,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        if (this.state.callerServiceTypes.length > 0) {
            this.state.callerServiceTypes.forEach(serviceType => {
                request.CallerServiceTypeIDs.push(parseInt(serviceType.value));
            });
        }

        axios.post("api/caserequest/updatecaserequest/", request)
            .then(res => {
                if (res.data?.isSuccess) {
                    if (res.data.data) {
                        NotificationManager.success("Güncelleme İşlemi Başarılı");
                        
                    } else {
                        NotificationManager.error("Güncelleme İşlemi Başarısız");
                        
                    }
                } else {
                    NotificationManager.error("Güncelleme İşlemi Başarısız");
                }
                this.setState({ saveIconClasses: "fa-spin d-none ml-2" });
            });
    }

    componentDidMount() {
        this.getTowns();
        this.getCaseRequestCallReasonList();
        this.getCaseRequestServiceTypeList();
        this.getVehicleSegmentList();
        this.getGearTypeList();
        this.getFuelTypeList();
        this.getValetDirectionsList();
    }

    render() {
        return (
            <>
                <Card>
                    <Card.Header>
                        <h5>Dosya Talepleri</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <TextInput
                                    name="searchPolicyNumber"
                                    faicon={faUser}
                                    label="Poliçe Numarası"
                                    placeholder="Poliçe Numarası"
                                    value={this.state.searchPolicyNumber}
                                    handleChange={this.handleTextInputChange}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <TextInput
                                    name="searchPlateNumber"
                                    faicon={faHashtag}
                                    label="Plaka"
                                    placeholder="Plaka"
                                    value={this.state.searchPlateNumber}
                                    handleChange={this.handleTextInputChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <DateInput
                                    timeFormat={false}
                                    label="Başlangıç Tarihi"
                                    placeholder="Başlangıç Tarihi Seçiniz"
                                    handleChange={this.handleStartDateChange}
                                    value={this.state.dateStart ? this.state.dateStart : ""}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <DateInput
                                    timeFormat={false}
                                    label="Bitiş Tarihi"
                                    placeholder="Bitiş Tarihi Seçiniz"
                                    handleChange={this.handleEndDateChange}
                                    value={this.state.dateEnd ? this.state.dateEnd : ""}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs={12} sm={6} md={4}>
                                <Button variant="primary" className="btn-block" onClick={this.getCaseRequestList} disabled={this.state.requestedCasesLoading}>
                                    <FontAwesomeIcon icon={faSearch} className="mr-2" />
                                    <span>Dosya Talebi Ara</span>
                                    <FontAwesomeIcon icon={faCircleNotch} className={this.state.searchIconClasses} />
                                </Button>
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <Button variant="primary" className="btn-block" href="/NewCaseRequest">
                                    <FontAwesomeIcon icon={faFolderPlus} className="mr-2" />
                                    <span>Yeni Talep</span>
                                </Button>
                            </Col>
                        </Row>
                        
                        <hr />
                        <Row className="justify-content-end">
                            <Col xs={12} sm={6} md={3} lg={2}>
                                <Button variant="primary" className="btn-block" onClick={this.exportDataToExcel}>
                                    <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                                    <span>Excel</span>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <DataTable
                                    columns={this.state.requestedCasesColumns}
                                    data={this.state.requestedCases}
                                    defaultSortField="title"
                                    pagination={true}
                                    progressPending={this.props.requestedCasesLoading}
                                    progressComponent={
                                        <>
                                            <h5>Yükleniyor</h5><FontAwesomeIcon className="fa-spin ml-3" icon={faCircleNotch} size="2x" />
                                        </>
                                    }
                                    noHeader={true}
                                    persistTableHead={true}
                                    noDataComponent={
                                        <Row className="mt-4">
                                            <Col>
                                                <h5>Veri bulunmamaktadır</h5>
                                            </Col>
                                        </Row>
                                    }
                                    highlightOnHover={true}
                                    striped={true}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Modal dialogClassName="modal-90w" show={this.state.showCaseRequestDetailModal} onHide={() => this.handleCaseRequestDetailModalClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Dosya Talebi Detayı</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card>
                            <Card.Header>
                                <h5>Sigortalı Sorgulama</h5>
                            </Card.Header>
                            <Card.Body>
                                <Row className="flex-row">
                                    <Col xs={12} sm={6} md={4}>
                                        <TextInput
                                            name="policyNumber"
                                            faicon={faUser}
                                            label="Poliçe Numarası"
                                            placeholder="Poliçe Numarası"
                                            value={this.state.policyNumber}
                                            handleChange={this.handleTextInputChange}
                                            buttonText="Ara"
                                            buttonEvent={this.getCustomerByPolicyNumber}
                                            btnIsLoading={this.state.searchIsLoading}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6} md={4}>
                                        <TextInput
                                            name="policyHolderName"
                                            faicon={faIdCard}
                                            label="Sigortalı Ad Soyad"
                                            placeholder="Ad Soyad"
                                            value={this.state.policyHolderName}
                                            handleChange={this.handleTextInputChange}
                                            disabled={!this.state.policyManuelEntry}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4}>
                                        <TextInput
                                            name="policyHoderPlate"
                                            faicon={faHashtag} label="Plaka"
                                            placeholder="Plaka"
                                            value={this.state.policyHoderPlate}
                                            handleChange={this.handleTextInputChange}
                                            disabled={!this.state.policyManuelEntry}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4}>
                                        <TextInput
                                            name="policyHolderProduct"
                                            faicon={faBox} label="Ürün"
                                            placeholder="Ürün"
                                            value={this.state.policyHolderProduct}
                                            handleChange={this.handleTextInputChange}
                                            disabled={!this.state.policyManuelEntry}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6} md={4}>
                                        <TextInput
                                            name="policyHolderVehicleBrand"
                                            faicon={faCar} label="Araç Marka"
                                            placeholder="Araç Marka"
                                            value={this.state.policyHolderVehicleBrand}
                                            handleChange={this.handleTextInputChange}
                                            disabled={!this.state.policyManuelEntry}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4}>
                                        <TextInput
                                            name="policyHolderVehicleModel"
                                            faicon={faCarSide} label="Araç Model"
                                            placeholder="Araç Model"
                                            value={this.state.policyHolderVehicleModel}
                                            handleChange={this.handleTextInputChange}
                                            disabled={!this.state.policyManuelEntry}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4}>
                                        <TextInput
                                            name="policyHolderVehicleModelYear"
                                            faicon={faCalendarAlt} label="Araç Model Yılı"
                                            placeholder="Araç Model Yılı"
                                            value={this.state.policyHolderVehicleModelYear}
                                            handleChange={this.handleIntegerTextInputChange}
                                            disabled={!this.state.policyManuelEntry}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <hr />
                        <Card>
                            <Card.Header>
                                <h5>Hizmet Verilecek Kişi Bilgileri</h5>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} sm={6} md={4}>
                                        <TextInput name="callerName" faicon={faIdCard} label="Ad Soyad" placeholder="Ad Soyad" value={this.state.callerName} handleChange={this.handleTextInputChange} />
                                    </Col>
                                    <Col xs={12} sm={6} md={4}>
                                        <TextInput name="callerPhone" faicon={faPhoneAlt} label="Telefon" placeholder="Telefon" value={this.state.callerPhone} handleChange={this.handlePhoneTextInputChange} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6} md={4}>
                                        <SelectInput
                                            options={this.state.towns}
                                            handleChange={this.handleTownChange}
                                            label="Şehir"
                                            placeholder="Şehir Seçiniz"
                                            faicon={faMapMarkerAlt}
                                            isMulti={false}
                                            closeMenuOnSelect={true}
                                            value={!this.state.callerTown?.tid ? null : { value: this.state.callerTown.tid, label: this.state.callerTown.tdescr }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4}>
                                        <SelectInput
                                            options={this.state.townAreas}
                                            handleChange={this.handleTownAreaChange}
                                            label="İlçe"
                                            placeholder="İlçe Seçiniz"
                                            faicon={faMapMarkerAlt}
                                            isMulti={false}
                                            closeMenuOnSelect={true}
                                            value={!this.state.callerTownArea?.taid ? null : { value: this.state.callerTownArea.taid, label: this.state.callerTownArea.tadescr }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4}>
                                        <TextInput name="callerDistrict" faicon={faMapMarkerAlt} label="Mahalle" placeholder="Mahalle" value={this.state.callerDistrict} handleChange={this.handleTextInputChange} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6} md={4}>
                                        <SelectInput
                                            options={this.state.callReasons}
                                            handleChange={this.handleRequestCallReasonChange}
                                            label="Arama Sebebi"
                                            placeholder="Arama Sebebi Seçiniz"
                                            faicon={faPhoneAlt}
                                            isMulti={false}
                                            closeMenuOnSelect={true}
                                            value={!this.state.callerReasonOfCall?.id ? null : { value: this.state.callerReasonOfCall.id, label: this.state.callerReasonOfCall.callReason }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4}>
                                        <SelectInput
                                            options={this.state.serviceTypes}
                                            handleChange={this.handleRequestServiceTypeChange}
                                            label="Talepler"
                                            placeholder="Talepleri Seçiniz"
                                            faicon={faBoxes}
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            value={this.state.callerServiceTypes}
                                            isDisabled={true}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4}>
                                        <label>Açıklama</label>
                                        <FormControl
                                            as="textarea"
                                            name="callerDescription"
                                            className="rounded-0 noresize"
                                            placeholder="Açıklama"
                                            rows={3}
                                            onChange={this.handleTextInputChange}
                                            value={this.state.callerDescription}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <RenderTowingInfo
                            selectedServiceTypes={this.state.callerServiceTypes}
                            handleTextInputChange={this.handleTextInputChange}
                            towingServiceName={this.state.towingServiceName}
                            towingServiceAddress={this.state.towingServiceAddress}
                        />
                        <RenderRentalInfo
                            selectedServiceTypes={this.state.callerServiceTypes}
                            racDeliveryDate={this.state.racDeliveryDate}
                            racVehicleSegment={this.state.racVehicleSegment}
                            racVehicleGearType={this.state.racVehicleGearType}
                            racVehicleFuelType={this.state.racVehicleFuelType}
                            handleRacDeliveryDateChange={this.handleRacDeliveryDateChange}
                            handleRacVehicleSegmentChange={this.handleRacVehicleSegmentChange}
                            handleRacVehicleGearTypeChange={this.handleRacVehicleGearTypeChange}
                            handleRacVehicleFuelTypeChange={this.handleRacVehicleFuelTypeChange}
                            racVehicleSegments={this.state.racVehicleSegments}
                            racVehicleGearTypes={this.state.racVehicleGearTypes}
                            racVehicleFuelTypes={this.state.racVehicleFuelTypes}
                            racReturnLoading={this.state.racReturnLoading}
                            returnRentalVehicle={this.returnRentalVehicle}
                            racReturnDate={this.state.racReturnDate}
                            racReturnUser={this.state.racReturnUser}
                        />
                        <RenderValetInfo
                            selectedServiceTypes={this.state.callerServiceTypes}
                            handleTextInputChange={this.handleTextInputChange}
                            valetDirection={this.state.valetDirection}
                            valetRequestDate={this.state.valetRequestDate}
                            valetServiceName={this.state.valetServiceName}
                            valetServiceAddress={this.state.valetServiceAddress}
                            handleValetDirectionChange={this.handleValetDirectionChange}
                            handleValetRequestDateChange={this.handleValetRequestDateChange}
                            valetDirections={this.state.valetDirections}
                        />
                        <Button variant="primary" className="float-right mt-2" disabled={this.state.caseOpenedByUser == null? false : true} onClick={this.updateCaseRequest} >
                            <FontAwesomeIcon icon={faSave} className="mr-2" />
                            <span>Kaydet ve Gönder</span>
                            <FontAwesomeIcon className={this.state.saveIconClasses} icon={faCircleNotch} />
                        </Button>
                    </Modal.Body>
                </Modal>
                <NotificationContainer />
            </>
        );
    }
}
