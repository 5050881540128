import React, { Component } from 'react';

//Bootstrap
import InputGroup from 'react-bootstrap/InputGroup';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//React-Select
import Select from 'react-select'

function RenderFontAwesomeIcon(icon) {
    if (icon.icon) {
        return (
            <InputGroup.Prepend>
                <InputGroup.Text className="rounded-0">
                    <FontAwesomeIcon icon={icon.icon} />
                </InputGroup.Text>
            </InputGroup.Prepend>
        );
    }
    else {
        return null;
    }
}

function RenderFontLabel(label) {
    if (label.label) {
        return (
            <label>{label.label}</label>
        );
    }
    else
        return null;
}

export class SelectInput extends Component {
    static displayName = SelectInput.name;

    constructor(props) {
        super(props);
        this.state = {

        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(selectOption) {
        this.props.handleChange(selectOption);
    }

    render() {
        return (
            <>
                <RenderFontLabel label={this.props.label} />
                <InputGroup className="mb-2">
                    <RenderFontAwesomeIcon icon={this.props.faicon} />
                    <Select
                        options={this.props.options}
                        onChange={this.handleChange}
                        theme={theme => ({ ...theme, borderRadius: 0, })}
                        placeholder={this.props.placeholder}
                        isMulti={this.props.isMulti}
                        closeMenuOnSelect={this.props.closeMenuOnSelect}
                        className="form-control p-0 border-0 basic-multi-select"
                        value={this.props.value}
                        isDisabled={this.props.isDisabled}                        
                    />
                </InputGroup>
            </>
        );
    }
}
