import React, { Component } from 'react';
import { LabeledData } from './LabeledData';
import { TextInput } from './TextInput';
import { DateInput } from './DateInput';
import axios from 'axios';


//Bootstrap
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

//DataTable
import DataTable from 'react-data-table-component';

//moment
import "moment/locale/tr";
import moment from 'moment';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faBarcode } from '@fortawesome/free-solid-svg-icons'
import { faHashtag } from '@fortawesome/free-solid-svg-icons'
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

//React-Notification
import { NotificationContainer, NotificationManager } from 'react-notifications';


export class Invoicing extends Component {
    static displayName = Invoicing.name;

    constructor(props) {
        super(props);
        this.state = {
            invoiceSerialNumber: "",
            invoiceNumber: "",
            invoiceDate: "",
            invoicabelServices: [],
            invoicabelServicesColumns: [
                {
                    name: 'Protokol',
                    selector: 'protocolNumber',
                    sortable: true,
                },
                {
                    name: 'Plaka/Kart No',
                    selector: 'customerCarPlate',
                    sortable: true,
                },
                {
                    name: 'Dosya',
                    selector: 'caseDescription',
                    sortable: true,
                    wrap: true
                },
                {
                    name: 'Servis',
                    selector: 'serviceDescription',
                    sortable: true,
                    wrap: true
                },
                {
                    name: 'Tarih',
                    sortable: true,
                    cell: row => <>{moment(row.serviceDate).format('DD.MM.YYYY HH:mm')}</>,
                    selector: row => moment(row.serviceDate).unix()
                },
                {
                    name: 'Tutar',
                    selector: 'ipaEksperTotalAmount.providerTotalAmount',
                    sortable: true,
                    wrap: true
                },
            ],
            invoicableServicesSelectionText: {
                singular: 'adet',
                plural: 'adet',
                message: 'servis seçildi'
            },
            invoicableServiceSelection: [],
            existingInvoices: [],
            existingInvoicesColumns: [
                {
                    name: 'Seri No',
                    selector: 'invoiceSerialNumber',
                    sortable: true,
                },
                {
                    name: 'Fatura No',
                    selector: 'invoiceNumber',
                    sortable: true,
                },
                {
                    name: 'Fatura Tarihi',
                    sortable: true,
                    cell: row => <>{moment(row.invoiceDate).format('DD.MM.YYYY')}</>,
                    selector: row => moment(row.invoiceDate).unix()
                },
                {
                    name: 'Toplam Tutar',
                    selector: 'invoiceTotalAmount',
                    sortable: true,
                    wrap: true
                },
                {
                    cell: row =>
                        <Button variant="primary" onClick={() => this.handleViewInvoice(row)}>
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                    ,
                    button: true,
                }
            ],
            existingInvoicesItems: [],
            existingInvoiceItemsColumns: [
                {
                    name: 'Protokol',
                    selector: 'protocolNumber',
                    sortable: true,
                },
                {
                    name: 'Plaka/Kart No',
                    selector: 'customerCarPlate',
                    sortable: true,
                },
                {
                    name: 'Hasar Tipi',
                    selector: 'caseDescription',
                    sortable: true,
                    wrap: true
                }, {
                    name: 'Hizmet Tipi',
                    selector: 'serviceDescription',
                    sortable: true,
                    wrap: true
                },
                {
                    name: 'Hizmet Tarih',
                    sortable: true,
                    cell: row => <>{moment(row.serviceDate).format('DD.MM.YYYY HH:mm')}</>,
                    selector: row => moment(row.serviceDate).unix()
                },
                {
                    name: 'Tutar',
                    selector: 'ipaEksperTotalAmount.providerTotalAmount',
                    sortable: true,
                    wrap: true
                }
            ],
            searchInvoiceNumber: "",
            searchInvoiceStartDate: "",
            searchInvoiceEndDate: "",
            selectedInvoiceSerialNumber: "",
            selectedInvoiceNumber: "",
            selectedInvoiceDate: "",
            selectedInvoiceTotalPrice: "",
            showInvoiceDetailsModal: false

        };
        this.handleNewInvoiceSerialNumberChange = this.handleNewInvoiceSerialNumberChange.bind(this);
        this.handleNewInvoiceNumberChange = this.handleNewInvoiceNumberChange.bind(this);
        this.handleNewInvoiceDateChange = this.handleNewInvoiceDateChange.bind(this);
        this.handleInvoicableServicesSelectChange = this.handleInvoicableServicesSelectChange.bind(this);
        this.handleInvoiceSearchStartDateChange = this.handleInvoiceSearchStartDateChange.bind(this);
        this.handleInvoiceSearchEndDateChange = this.handleInvoiceSearchEndDateChange.bind(this);
        this.handleInvoiceSearchNumberChange = this.handleInvoiceSearchNumberChange.bind(this);
        this.handleViewInvoice = this.handleViewInvoice.bind(this);
        this.saveInvoice = this.saveInvoice.bind(this);
        this.getAprovedServices = this.getAprovedServices.bind(this);
        this.getInvoiceList = this.getInvoiceList.bind(this);
        this.downloadExcel = this.downloadExcel.bind(this);
        this.saveAsExcelFile = this.saveAsExcelFile.bind(this);
        this.getPaymentCalender = this.getPaymentCalender.bind(this);
    }

    handleNewInvoiceSerialNumberChange(evt) {
        if (evt.target.value === "") {
            this.setState({ invoiceSerialNumber: evt.target.value });

        } else {
            var validString = true;
            evt.target.value.split("").forEach((v) => {
                if (!isNaN(v)) {
                    validString = false;
                }
            });
            if (!validString) {
                NotificationManager.warning("Seri Numarası Harflerden Oluşmalıdır.","",1500);
                return;
            }
            this.setState({ invoiceSerialNumber: evt.target.value });
        }
    }
    handleNewInvoiceNumberChange(evt) {
        if (evt.target.value === "") {
            this.setState({ invoiceNumber: evt.target.value });

        } else {
            var validNumber = true;
            evt.target.value.split("").forEach((v) => {
                if (isNaN(v)) {
                    validNumber = false;
                }
            });
            if (!validNumber) {
                NotificationManager.warning("Fatura Numarası Rakamlardan Oluşmalıdır.", "", 1500);
                return;
            }
            this.setState({ invoiceNumber: evt.target.value });
        }
    }
    handleNewInvoiceDateChange(moment) {
        this.setState({ invoiceDate: moment });
    }
    handleInvoicableServicesSelectChange(selection) {
        this.setState({ invoicableServiceSelection: selection });
    }
    handleInvoiceSearchStartDateChange(moment) {
        this.setState({ searchInvoiceStartDate: moment });
    }
    handleInvoiceSearchEndDateChange(moment) {
        this.setState({ searchInvoiceEndDate: moment });
    }
    handleInvoiceSearchNumberChange(evt) {
        this.setState({ searchInvoiceNumber: evt.target.value });
    }
    handleViewInvoice(invoice) {
        this.setState({
            ...this.state,
            selectedInvoiceSerialNumber: invoice.invoiceSerialNumber,
            selectedInvoiceNumber: invoice.invoiceNumber,
            selectedInvoiceDate: invoice.invoiceDate,
            selectedInvoiceTotalPrice: invoice.invoiceTotalAmount,
            existingInvoicesItems: invoice.services,
        }, () => { this.setState({ showInvoiceDetailsModal: true });});
    }
    handleInvoiceDetailModalClose() {
        this.setState({ showInvoiceDetailsModal: false });
    }

    async getAprovedServices() {
        var request = {
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/invoicing/getaprovedservicesbyrequest/", request)
            .then(res => {
                if (!res.data.isSuccess) {
                    NotificationManager.error("Servis Listesi Alınamadı");
                } else {
                    this.setState({ invoicabelServices: res.data.data });

                }
            });
    }
    async saveInvoice() {
        if (!this.state.invoiceNumber) {
            NotificationManager.error("Fatura numarası girilmedi.")
            return;
        }
        if (!this.state.invoiceDate) {
            NotificationManager.error("Fatura tarihi girilmedi.")
            return;
        }
        if (!this.state.invoicableServiceSelection?.selectedRows) {
            NotificationManager.error("Servis seçilmedi.")
            return;
        }

        var request = {
            InvoiceSerial: this.state.invoiceSerialNumber,
            InvoiceNumber: this.state.invoiceNumber,
            InvoiceUnixDate: String(this.state.invoiceDate.unix()),
            IPAEksperServiceIDs: this.state.invoicableServiceSelection.selectedRows.map(item => item.fdid),
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/invoicing/saveinvoice/", request)
            .then(res => {
                if (res.data.isSuccess) {
                    NotificationManager.success("Fatura kaydedildi");
                } else {
                    NotificationManager.error("Fatura Kaydedilemedi");
                }
            });
    }
    async getInvoiceList() {
        this.setState({ existingInvoices: [] })
        var request = {
            invoiceUnixDateStart: this.state.searchInvoiceStartDate ? String(this.state.searchInvoiceStartDate.unix()) : null,
            invoiceUnixDateEnd: this.state.searchInvoiceEndDate ? String(this.state.searchInvoiceEndDate.unix()) : null,
            invoiceNumber: this.state.searchInvoiceNumber,
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }

        axios.post("api/invoicing/getinvoicelist/", request)
            .then(res => {
                if (res.data.isSuccess) {
                    this.setState({ existingInvoices: res.data.data });
                } else {
                    NotificationManager.error("Fatura listesi getirilemedi.");
                }
            });
    }

    async getPaymentCalender() {
        axios({
            url: "api/invoicing/getpaymentcalender",
            method: 'GET',
            responseType: 'blob',
        }).then(res => {
            var blob = new Blob([res.data]);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "odemetakvim.pdf";
            link.click();
        });
    }

    downloadExcel() {
        var exportData = [];
        this.state.invoicabelServices.forEach(item => {
            var exportRecord = {
                "Protokol": item.protocolNumber,
                "Plaka/KartNo": item.customerCarPlate?.trim(),
                "Dosya": item.caseDescription?.trim(),
                "Tarih": moment(item.serviceDate).format('DD.MM.YYYY HH:mm'),
                "Tutar": item.ipaEksperTotalAmount.providerTotalAmount
            }
            exportData.push(exportRecord);
        });

        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(exportData);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'export');
        });
        return;
    }

    saveAsExcelFile(buffer, fileName) {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    componentDidMount() {
        this.getAprovedServices();
    }

    render() {
        return (
            <>
                <div className="noPrint">
                    <Button onClick={this.getPaymentCalender} target="_blank" className="mb-1 float-right">Fatura Kabul ve Ödeme Tarihleri </Button>
                    <Tabs defaultActiveKey="newInvoice">
                        <Tab eventKey="newInvoice" title="Yeni Fatura">
                            <Card>
                                <Card.Header>
                                    <h4>Yeni Fatura</h4>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={4}>
                                            <TextInput
                                                faicon={faBarcode}
                                                label="Seri Numarası"
                                                placeholder="Seri Numarası"
                                                value={this.state.invoiceSerialNumber}
                                                handleChange={this.handleNewInvoiceSerialNumberChange}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={4}>
                                            <TextInput
                                                faicon={faHashtag}
                                                label="Fatura Numarası"
                                                placeholder="Fatura Numarası"
                                                value={this.state.invoiceNumber}
                                                handleChange={this.handleNewInvoiceNumberChange}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={4}>
                                            <DateInput
                                                timeFormat={false}
                                                label="Fatura Tarihi"
                                                placeholder="Fatura Tarihi"
                                                handleChange={this.handleNewInvoiceDateChange}
                                                value={this.state.invoiceDate ? this.state.invoiceDate : ""}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={4}>
                                            <LabeledData label="Servis Adedi:" data={this.state.invoicableServiceSelection?.selectedCount ? this.state.invoicableServiceSelection.selectedCount : "0"} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={4} className="text-danger">
                                            <LabeledData label="Toplam Tutar(₺):" data={this.state.invoicableServiceSelection?.selectedRows ? this.state.invoicableServiceSelection?.selectedRows?.reduce((a, v) => a = a + v.ipaEksperTotalAmount.providerTotalAmount, 0) : "0"} />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={4}>
                                            <Button variant="primary" className="btn-block mt-2" onClick={this.saveInvoice}>
                                                <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
                                                <span>Faturayı Oluştur ve Kaydet</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className="row justify-content-end">
                                        <Col xs={12} sm={6} md={3} lg={2}>
                                            <Button variant="primary" className="btn-block mt-2" onClick={this.downloadExcel}>
                                                <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                                                <span>Excel</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <DataTable
                                                columns={this.state.invoicabelServicesColumns}
                                                data={this.state.invoicabelServices}
                                                defaultSortField="title"
                                                title="Faturalanmamış Servisler"
                                                pagination={false}
                                                progressComponent={
                                                    <>
                                                        <h5>Yükleniyor</h5><FontAwesomeIcon className="fa-spin ml-3" icon={faCircleNotch} size="2x" />
                                                    </>
                                                }
                                                noDataComponent={
                                                    <h5>Veri bulunmamaktadır</h5>
                                                }
                                                selectableRows
                                                selectableRowsHighlight={true}
                                                contextMessage={this.state.invoicableServicesSelectionText}
                                                onSelectedRowsChange={this.handleInvoicableServicesSelectChange}
                                                highlightOnHover={true}
                                                striped={true}
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Tab>
                        <Tab eventKey="oldInvoices" title="Eski Faturalarım">
                            <Card>
                                <Card.Header>
                                    <h4>Eski Fatura Listesi</h4>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={4}>
                                            <DateInput
                                                timeFormat={false}
                                                label="Başlangıç Tarihi"
                                                placeholder="Başlangıç Tarihi"
                                                handleChange={this.handleInvoiceSearchStartDateChange}
                                                value={this.state.searchInvoiceStartDate ? this.state.searchInvoiceStartDate : ""}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={4}>
                                            <DateInput
                                                timeFormat={false}
                                                label="Bitiş Tarihi"
                                                placeholder="Bitiş Tarihi"
                                                handleChange={this.handleInvoiceSearchEndDateChange}
                                                value={this.state.searchInvoiceEndDate ? this.state.searchInvoiceEndDate : ""}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={4}>
                                            <TextInput
                                                faicon={faHashtag}
                                                label="Fatura Numarası"
                                                placeholder="Fatura Numarası"
                                                value={this.state.searchInvoiceNumber}
                                                handleChange={this.handleInvoiceSearchNumberChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col xs={12} sm={12} md={{ span: 6, offset: 6 }} lg={{ span: 4, offset: 8 }}>
                                            <Button variant="primary" className="btn-block" onClick={this.getInvoiceList}>
                                                <FontAwesomeIcon icon={faSearch} className="mr-2" />
                                                <span>Fatura Ara</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <DataTable
                                        columns={this.state.existingInvoicesColumns}
                                        data={this.state.existingInvoices}
                                        defaultSortField="title"
                                        pagination={true}
                                        progressComponent={
                                            <>
                                                <h5>Yükleniyor</h5><FontAwesomeIcon className="fa-spin ml-3" icon={faCircleNotch} size="2x" />
                                            </>
                                        }
                                        noDataComponent={
                                            <h5>Veri bulunmamaktadır</h5>
                                        }
                                        highlightOnHover={true}
                                        striped={true}
                                    />
                                </Card.Body>
                            </Card>
                        </Tab>
                    </Tabs>
                </div>
                <Modal dialogClassName="modal-90w" show={this.state.showInvoiceDetailsModal} onHide={() => this.handleInvoiceDetailModalClose()}>
                    <Modal.Header>
                        <Modal.Title>Fatura Detayı</Modal.Title>
                        <div>
                            <Button variant="link" className="noPrint" onClick={() => window.print()}>
                                <FontAwesomeIcon icon={faPrint} />
                            </Button>
                            <Button variant="link" className="noPrint text-danger" onClick={() => this.handleInvoiceDetailModalClose()}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} sm={12} md={6}>
                                <LabeledData className="p-2" label="Seri Numara:" data={this.state.selectedInvoiceSerialNumber} />
                            </Col>
                            <Col xs={12} sm={12} md={6}>
                                <LabeledData className="p-2" label="Fatura Numarası:" data={this.state.selectedInvoiceNumber} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={6}>
                                <LabeledData className="p-2" label="Fatura Tarihi:" data={moment(this.state.selectedInvoiceDate).format("DD.MM.YYYY")} />
                            </Col>
                            <Col xs={12} sm={12} md={6}>
                                <LabeledData className="p-2" label="Fatura Tutarı(₺):" data={this.state.selectedInvoiceTotalPrice} />
                            </Col>
                        </Row>
                        <DataTable
                            columns={this.state.existingInvoiceItemsColumns}
                            data={this.state.existingInvoicesItems}
                            defaultSortField="title"
                            title="Fatura Kalemleri"
                            pagination={false}
                            progressComponent={
                                <>
                                    <h5>Yükleniyor</h5><FontAwesomeIcon className="fa-spin ml-3" icon={faCircleNotch} size="2x" />
                                </>
                            }
                            noDataComponent={
                                <h5>Veri bulunmamaktadır</h5>
                            }
                            highlightOnHover={true}
                            striped={true}
                        />
                    </Modal.Body>
                </Modal>
                <NotificationContainer />
            </>
        );
    }
}
