import React, { Component } from 'react';
import axios from 'axios';
import { TextInput } from './TextInput';
//Bootstrap
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
//Image Resizer
import Resizer from 'react-image-file-resizer';
//Moment
import moment from 'moment';
//React-Notification
import { NotificationContainer, NotificationManager } from 'react-notifications';

export class RAXADelivery extends Component {
    static displayName = RAXADelivery.name;
    constructor(props) {
        super(props);
        this.state = {
            newImage: {},
            photos: [],
            photosPreview: [],
            photoCountClassName: "p-1 m-1 rounded text-white bg-danger",
            geolocation: {
                latitude: "",
                longitude: ""
            },
            serviceData: {},
            recieverName: "",
            recieverPhoneNumber: "",
            saveIconClasses: "fa-spin d-none ml-2",
            saveBtnDisabled: false
        };
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.getRaxaServiceData = this.getRaxaServiceData.bind(this);
        this.handleReciverNameChange = this.handleReciverNameChange.bind(this);
        this.handleReciverPhoneNumberChange = this.handleReciverPhoneNumberChange.bind(this);
        this.handleRemoveFromArray = this.handleRemoveFromArray.bind(this);
        this.raxaDelivery = this.raxaDelivery.bind(this);
    }

    handleFileUpload(evt) {
        var fileInput = false
        if (evt.target.files[0]) {
            fileInput = true
        }
        if (fileInput) {
            Resizer.imageFileResizer(
                evt.target.files[0],
                1024,
                1024,
                'JPEG',
                100,
                0,
                uri => {
                    if (!this.state.photos.some(x => (x === uri))) {
                        const newArray = this.state.photos.concat(uri);
                        this.setState({ photos: newArray }, () => {
                            if (this.state.photos.length >= 8) this.setState({ photoCountClassName: "p-1 m-1 rounded text-white bg-success" })
                        });
                    }
                }
            );

            Resizer.imageFileResizer(
                evt.target.files[0],
                150,
                150,
                'JPEG',
                100,
                0,
                uri => {
                    if (!this.state.photosPreview.some(x => (x === uri))) {
                        const newArray = this.state.photosPreview.concat(uri);
                        this.setState({ photosPreview: newArray });
                    }
                    else
                        alert("Fotoğraf önceden eklenmiş.")
                }
            );
        }
        evt.target.value = null;
    }
    handleReciverNameChange(evt) {
        this.setState({ recieverName: evt.target.value });
    }
    handleReciverPhoneNumberChange(evt) {
        this.setState({ recieverPhoneNumber: evt.target.value });
    }
    handleRemoveFromArray(uri) {
        let newPhotos = this.state.photos;
        for (const [index, value] of newPhotos.entries()) {
            if (value === uri) 
                newPhotos.splice(index, 1);
        }
        this.setState({ photos: newPhotos });

        let newPhotosPreview = this.state.photosPreview;
        for (const [index, value] of newPhotosPreview.entries()) {
            if (value === uri) 
                newPhotosPreview.splice(index, 1);
        }
        this.setState({ photosPreview: newPhotosPreview});
    }

    async getRaxaServiceData() {
        var request = {
            ServiceID: parseInt(this.props.match.params.serviceid),
            ProviderServiceToken: this.props.match.params.accesstoken
        }
        axios.post("api/raxa/getraxaservicebyrequest/", request)
            .then(res => {
                if (res.data.isSuccess)
                    this.setState({ serviceData: res.data.data });
                else
                    alert("Servis bilgileri alınamadı");
            });
    }
    async raxaDelivery() {
        if (!this.state.recieverName) {
            alert("Alıcı Adı-Soyadı boş bırakılamaz");
            return;
        }
        if (!this.state.recieverPhoneNumber) {
            alert("Alıcı Telefon Numarası boş bırakılamaz");
            return;
        }
        if (!this.state.geolocation.latitude && !this.state.geolocation.longitude) {
            alert("Konum bilgisi olmadan teslimat tamamlanamaz");
            return;
        }
        if (this.state.photos.length < 8) {
            alert("En az 8 adet fotoğraf eklenmesi gerekiyor");
            return;
        }

        this.setState({
            ...this.state,
            saveIconClasses: "fa-spin ml-2",
            saveBtnDisabled: true
        });

        let photos = [];

        for (const [, value] of this.state.photos.entries()) {
            photos.push(value)
        }

        let request = {
            ServiceID: parseInt(this.props.match.params.serviceid),
            StaffAccessToken: this.props.match.params.accesstoken,
            ProviderRACVehicleRecipentName: this.state.recieverName,
            ProviderRACVehicleRecipentPhoneNumber: this.state.recieverPhoneNumber,
            ProviderRACDeliveryLat: String(this.state.geolocation.latitude),
            ProviderRACDeliveryLon: String(this.state.geolocation.longitude),
            Base64Photos: photos,
        }
        axios.post("api/raxa/raxadelivery/", request)
            .then(res => {
                if (res.data.data) {
                    alert("Teslimat işlemi tamamlandı");
                    this.setState({
                        ...this.state,
                        serviceData: {
                            ...this.state.serviceData,
                            providerRACDeliveryDate: moment().format()
                        }
                    });
                }
                else {
                    this.setState({
                        ...this.state,
                        saveIconClasses: "fa-spin d-none ml-2",
                        saveBtnDisabled: true
                    });
                }
            });
    }

    componentDidMount() {
        if (!("geolocation" in navigator)) {
            alert("Konum alınamadı. Cihazınızın konum özelliğinin açık olduğundan ve tarayıcıya izin verdiğinden emin olunuz");
        }
        else {
            let currentstate = this;
            navigator.geolocation.getCurrentPosition(function (position) {
                currentstate.setState({
                    geolocation: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }
                });
            }, (err) => {
                    NotificationManager.error("Konum alma başarısız oldu.");
                    console.log(err);
                    currentstate.setState({
                        geolocation: {
                            latitude: "0",
                            longitude: "0"
                        }
                    });
            });            
        }
        this.getRaxaServiceData();
    }

    render() {
        if (!this.state.serviceData?.providerRACDeliveryDate) {
            let photoCount = this.state.photos.length;
            return (
                <Card className="mt-1">
                    <Card.Header>
                        <h5>R-AXA Teslimat</h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <TextInput value={this.state.serviceData?.serviceCase?.caseProductOwner?.platePolicyNumber || ""} disabled={true} label='Müşteri Plakası' />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput value={this.state.serviceData?.serviceCase?.callerName || ""} disabled={true} label='Müşteri Adı' />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput value={this.state.serviceData?.serviceCase?.callerPhoneNumber || ""} disabled={true} label='Müşteri Telefonu' />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput value={this.state.geolocation.latitude} disabled={true} label='Enlem' />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput value={this.state.geolocation.longitude} disabled={true} label='Boylam' />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput value={moment().format("DD.MM.yyyy HH:mm")} disabled={true} label='Teslimat Tarihi' />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput
                                    label="Alıcı Adı-Soyadı"
                                    placeholder="Alıcı Adı-Soyadı"
                                    value={this.state.recieverName}
                                    handleChange={this.handleReciverNameChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput
                                    label="Alıcı Telefon Numarası"
                                    placeholder="05XXXXXXXXX"
                                    value={this.state.recieverPhoneNumber}
                                    handleChange={this.handleReciverPhoneNumberChange}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <h5>Araç Fotoğrafları</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6 className="text-white bg-danger p-2 rounded" >
                                    Kişisel verilerin korunması kanunu kapsamında
                                    kredi kartı, teslimat evrağı, ehliyet
                                    gibi kişisel belgelerin fotoğraflarını paylaşmayınız!
                            </h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <Form>
                                    <Form.File label="Fotoğraf Yükle" custom onChange={this.handleFileUpload} />
                                </Form>
                            </Col>
                            <Col xs={4}>
                                <label className={this.state.photoCountClassName}>Adet: {photoCount} / 8</label>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <Table bordered hover>
                                    <tbody>
                                        {this.state.photosPreview.map(item => (
                                            <tr key={item}>
                                                <td className="align-middle">
                                                    <Button variant="danger" onClick={() => this.handleRemoveFromArray(item)}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                                <td className="align-middle">
                                                    <img src={item} alt="X" />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    variant="primary"
                                    className="btn-block"
                                    onClick={this.raxaDelivery}
                                    disabled={this.state.saveBtnDisabled}>
                                    <span>Teslimatı Tamamla</span>
                                    <FontAwesomeIcon className={this.state.saveIconClasses} icon={faCircleNotch} />
                            </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                    <NotificationContainer />
                </Card>
            );
        } else {
            return (
                <Card className="mt-3">
                    <Card.Body>
                        <h4 className="text-white bg-success rounded-1 p-2 rounded mt-1">
                            Teslimat İşlemi Tamamlandı
                        </h4>
                    </Card.Body>
                    <NotificationContainer />
                </Card>
            );
        }
    }
}
