import React, { Component } from 'react';
import axios from 'axios';

import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//DataTable
import DataTable from 'react-data-table-component';
import Button from 'react-bootstrap/Button';
//React-Notification
import { NotificationContainer, NotificationManager } from 'react-notifications';





export class RepairmentsPrint extends Component {


    constructor(props) {
        super(props);
        this.state = {
            //myProps: props.location.myProps,
            searchingIconClasses: "fa-spin d-none ml-2",
            columnsRepairmentsPrint: [
                {
                    name: 'Yapılan İşin / Kullanılan Malzemenin ADI',
                    cell: row => <span>{row.groupName ? row.groupName : row.material}</span>,
                    wrap: true
                },
                {
                    name: 'Malzeme Detay',
                    selector: 'materialDetail',
                    sortable: false,
                    wrap: true,
                },
                {
                    name: 'Mahal',
                    selector: 'room',
                    sortable: false,
                    wrap: true,
                },
                {
                    name: 'Miktar',
                    selector: 'amount',
                    sortable: false,
                    wrap: true,
                },
                {
                    name: 'Birim',
                    selector: 'unit',
                    sortable: false,
                    wrap: true,
                },
                {
                    name: 'Birim Fiyat',
                    selector: 'pricePerUnit',
                    sortable: false,
                    wrap: true,
                },
                {
                    name: 'Toplam',
                    selector: 'totalPrice',
                    sortable: false,
                    wrap: true,
                },
                {
                    name: 'Kalem Toplamı',
                    selector: 'groupTotal',
                    sortable: false,
                    wrap: true,
                    cell: row => <span>{row.groupTotal  ? row.groupTotal : ''}</span>,
                },

            ],
            
            conditionalRowStyles: [
                {
                    when: row => row.user == null,
                    style: {
                        backgroundColor: 'rgb(2, 117, 216)',
                        color: 'white'
                    },
                },
                {
                    when: row => row.user != null,
                    style: {
                        backgroundColor: 'rgb(92, 184, 92)',
                        color: 'white'
                    },
                },
            ],
            saveIconClasses: "fa-spin d-none ml-2",
            searchBtnDisabled: false,
            homeRepairRepairmentTypes: [],
            homeRepairMaterials: [],
            homeRepairRooms: [],
            homeRepairUnits: [],
            printableRepairments: [],
            hrCase: {}
        };
        this.getRepairmentsPrintTable = this.getRepairmentsPrintTable.bind(this);
    }
    
    renderPrintRepairments() {
        return (
            <React.Fragment>
                <div className="row mb-2 border border-gray rounded">
                    <div className="col-lg-4">
                        <div className="d-lg-flex mb-2 pt-1">

                            <span className="text">ASİSTANS HASAR ONARIM FORMU</span>
                        </div>

                        
                    </div>
                    <div className="col-lg-8">
                        <div className="d-flex justify-content-end">
                            <Button variant="link" className="noPrint" onClick={() => window.print()}>
                                <FontAwesomeIcon icon={faPrint} />
                            </Button>
                        </div>
                    </div>
                    
                </div>
                <div>
                    <table border="1" style={{ width: "70%" }}>
                        <tr>
                            <td>DOSYA NO</td>
                            <td>{this.state.hrCase?.mainCase?.caseProductOwner?.platePolicyNumber}</td>
                        </tr>
                        <tr>
                            <td>SİGORTALI</td>
                            <td>{this.state.hrCase?.mainCase?.caseProductOwner ? this.state.hrCase.mainCase.caseProductOwner.name : ''}</td>
                        </tr>
                        <tr>
                            <td>ONARIM ADRESİ</td>
                            <td>
                                {this.state.hrCase?.mainCase?.sceneAdress}
                            </td>
                        </tr>
                    </table>
                </div>
                <DataTable
                    columns={this.state.columnsRepairmentsPrint}
                    data={this.state.printableRepairments}
                    noDataComponent={
                        <h5>Veri bulunmamaktadır</h5>
                    }

                    highlightOnHover={true}
                    striped={true}
                />

                <br />
                <div className="table-responsive">
                <table className="table">
                    <tr>
                        <td>

                            <table border="1">
                                <tr>
                                    <td>ASİSTANS FİRMASI</td>
                                    <td>INTER PARTNERS ASSISTANCE</td>
                                </tr>
                                <tr>
                                    <td>KAŞE / İMZA</td>
                                    <td>{this.state.hrCase.specialistUser?.displayName}</td>
                                </tr>
                            </table>


                        </td>
                        <td>
                            <div className="d-flex justify-content-end">

                                <table border="1">
                                    <tr>
                                        <td>TOPLAM</td>
                                        <td>{this.state.repairmentsTotal}</td>
                                    </tr>
                                    <tr>
                                        <td>KDV (%20) </td>
                                        <td>{this.state.repairmentsVat}</td>
                                    </tr>
                                    <tr>
                                        <td>GENEL TOPLAM</td>
                                        <td>{this.state.repairmentsSum}</td>
                                    </tr>
                                </table>


                            </div>

                        </td>
                    </tr>
                </table>
                </div>
                <br />


                <div className="row">
                    <span>NOT/AÇIKLAMA :</span>
                    {this.state.hrCase?.repairNotes}
                </div>

            </React.Fragment>
        );

    }
 
    async getRepairmentsPrintTable(caseCode) {
        axios.post("api/service/generaterepairmentprintdataforexpert/", {CaseCode:caseCode})
            .then(res => {
                if (res.data.data) {
                    console.log(res);
                    this.setState({ printableRepairments: res.data.data,hrCase:res.data.hrCase, repairmentsTotal: res.data.totalPrice, repairmentsVat: res.data.vat, repairmentsSum: res.data.sum })
                }
                else {
                    alert(res.data.message);
                    NotificationManager.error(res.data.message);
                }
            }).catch(e => {
                console.log(e);
                NotificationManager.error("Bir hata oluştu");
            })
    }


    //Component Events
    componentDidMount() {
        this.getRepairmentsPrintTable(this.props.computedMatch.params.caseCode);
    }
   /* componentDidUpdate(prevProps) {
        if (prevProps.service !== this.props.location.myData.service) {
            this.setState({ serviceData: this.props.location.myData.service.data.data });
        }
        let calendarApi = this.calendarRef.current.getApi()
        calendarApi.refetchEvents()
        //calendarApi.render()
        //calendarApi.getEvents()
        calendarApi.updateSize()
    }*/

    render() {
        return (
            <React.Fragment>
                <div className="mb-5 mt-5 mr-5 ml-5">
                    {this.renderPrintRepairments()}
                </div>
            </React.Fragment>
        );
    }
}
