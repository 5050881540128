import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
//Bootstrap
import Navbar from 'react-bootstrap/NavBar';
import Nav from 'react-bootstrap/Nav';
import logo from '..//img/logo_interpartner.png';

//conditional Render
function RenderNavLinks(props) {
    const isProvider = window.sessionStorage.getItem("isProvider");
    if (isProvider === "true") {
        return (
            <>
                {/*<Nav.Link href="/home">Duyurular</Nav.Link>*/}
                <Nav.Link href="./UnprocessedServices">Servisler</Nav.Link>
                <Nav.Link href="./RAXAInventory">R-AXA Envanter</Nav.Link>
                <Nav.Link href="./Invoicing">Fatura İşlemleri</Nav.Link>
                <Nav.Link href="./Pricing" target="popup" onClick={props.handlePricingMenu}>Ücret Hesaplama</Nav.Link>
                <Nav.Link href="./HomerepairCases">Konut Dosya</Nav.Link>
            </>
        );
    }
    if(isProvider === "false"){
        return (
            <>
                <Nav.Link href="./InsuranceServices">Servisler</Nav.Link>
                <Nav.Link href="./RequestedCases">Dosya Talebi</Nav.Link>
            </>
        );
    }
    window.sessionStorage.setItem("userID", "");
    window.sessionStorage.setItem("userToken", "");
    window.sessionStorage.setItem("isProvider", "");
    return <Redirect to='/login' />
}

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
        this.handlePricingMenu = this.handlePricingMenu.bind(this);
    }

    handlePricingMenu() {
        window.open('../Pricing', 'popup', 'width=600,height=400');
    }
    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <Navbar collapseOnSelect expand="lg"  bg="primary" variant="dark" className="mb-3">
                <Navbar.Brand href="/home">
                    <img
                        alt=""
                        src={logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                  IPA Eksper
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">                       
                        <RenderNavLinks handlePricingMenu={this.handlePricingMenu} />
                    </Nav>
                    <Nav>
                        <Nav.Link href="./UserSettings">Kullanıcı İşlemleri</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}
