import React, { Component } from 'react';
import axios from 'axios';
import { HomeRepairSearch } from './HomeRepairSearch';
import { HomeRepairList } from './HomeRepairList';
import { HomeRepairDetail } from './HomeRepairDetail';
//PrimeReact Datatable
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
//React-Datetime
import "react-datetime/css/react-datetime.css";
import "moment/locale/tr";
import moment from 'moment';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
//Bootstrap
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { DateInput } from './DateInput';

//React-Notification
import { NotificationContainer, NotificationManager } from 'react-notifications';

//import '../primeReactDatatable.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

export class HomeRepairCases extends Component {
    static displayName = HomeRepairCases.name;

    constructor(props) {
        super(props);
        this.state = {
            searchData: { data: { data: [] } },
            serviceData: { data: { data: [] } },
            showServiceDetailModal: false,
            serviceTalbeLoading: false,
            expandedRowGroups: [],
            ref: React.createRef()
        };
        this.handleserviceDetailModalClose = this.handleserviceDetailModalClose.bind(this);
        this.handleSearchCallback = this.handleSearchCallback.bind(this);
        this.searchServices = this.searchServices.bind(this);
        this.getServiceByID = this.getServiceByID.bind(this);
        this.searchHomeRepairAgendaItems = this.searchHomeRepairAgendaItems.bind(this);


        this.dateTemplate = this.dateTemplate.bind(this);
        this.headerTemplate = this.headerTemplate.bind(this);
        this.onRowGroupExpand = this.onRowGroupExpand.bind(this);
        this.onRowGroupCollapse = this.onRowGroupCollapse.bind(this);
        this.handleAgendaDate = this.handleAgendaDate.bind(this);
        this.filterAgendaItems = this.filterAgendaItems.bind(this);
    }

    headerTemplate(data) {
        return (
            <React.Fragment>
                <span>{new Intl.DateTimeFormat("tr-TR", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit"
                }).format(moment(data.agendaDate))
                }</span>
            </React.Fragment>
        );
    }
    dateTemplate(data) {
        return (
            <React.Fragment>
                 {new Intl.DateTimeFormat("tr-TR", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12:false
                    }).format(moment(data.agendaDate))
                }
            </React.Fragment>
        );
    }
   

    footerTemplate(data) {
        return (
            <React.Fragment>
                <td><span></span></td>
            </React.Fragment>
        );
    }
    onRowGroupExpand(event) {
       // this.toast.show({ severity: 'info', summary: 'Row Group Expanded', life: 3000 });
    }

    onRowGroupCollapse(event) {
        //this.toast.show({ severity: 'success', summary: 'Row Group Collapsed', life: 3000 });
    }
    handleSearchCallback(childData){
        
        if (childData.data.data != null)
            this.setState({ searchData: childData }, () => this.setState({ serviceTalbeLoading: false }))
        else
            this.setState({ searchData: { data: { data: [] } } }, () => this.setState({ serviceTalbeLoading: false }))
    }
    handleserviceDetailModalClose() {
        this.setState({ showServiceDetailModal: false });
        this.state.ref.current.SearchServices();
    }
    handleserviceDetailModalOpen() {
        this.setState({ showServiceDetailModal: true });
    }
    handleAgendaDate(moment, name) {
        this.setState({ [name]: moment },this.filterAgendaItems);
    }
    filterAgendaItems() {
        var filteredResults;
        if (!this.state.agendaStartDate && !this.state.agendaEndDate) {
            this.setState({filteredAgendaItems:this.state.agendaItems})
            return;
        }           
        if (this.state.agendaStartDate) {
            if (this.state.agendaEndDate) {
                //both are set.
                filteredResults = this.state.agendaItems.filter(x => moment(x.agendaDate) >= this.state.agendaStartDate && moment(x.agendaDate) <= this.state.agendaEndDate);
                this.setState({ filteredAgendaItems: filteredResults });
            } else {
                //only start date is set
                filteredResults = this.state.agendaItems.filter(x => moment(x.agendaDate) >= this.state.agendaStartDate);
                this.setState({ filteredAgendaItems: filteredResults });
            }
        } else {
            //only end date is set
            filteredResults = this.state.agendaItems.filter(x => moment(x.agendaDate) <= this.state.agendaEndDate);
            this.setState({ filteredAgendaItems: filteredResults });
        }
    }
    async searchServices(request) {
        this.setState({ serviceTalbeLoading: true });
        axios.post("api/service/gethomerepaircasesbyrequest", request)
            .then(res => {
                if(res.data.isSuccess)
                    NotificationManager.success(res.data.message);
                else
                    NotificationManager.error(res.data.message);
                this.handleSearchCallback(res);
                //Implement error handling
            });
    }

    async searchHomeRepairAgendaItems() {
        var request = {
            IPAEksperUserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        } 
        axios.post("api/service/gethomerepairagendaitemsbyrequest", request)
            .then(res => {
                var arrangedAgendaItems = !res.data.data ? [] : res.data.data.map((x) => {
                    x["agendaOnlyDate"] = moment(x.agendaDate).format('L');
                    return x;
                });
                //this.setState({ agendaItems: res.data.data });
                this.setState({ agendaItems: arrangedAgendaItems, filteredAgendaItems: arrangedAgendaItems });
                //this.handleSearchCallback(res);
                //Implement error handling
            });
    }
    async getServiceByID(homerepairCaseID) {
        var request = {
            ID: homerepairCaseID,
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/service/gethomerepaircasebyrequest/", request)
            .then(res => {
                if (res.data.data != null)
                    this.setState({ serviceData: res })
                this.handleserviceDetailModalOpen();
                //console.log(res);
                //Implement error handling
            });
    }
    componentDidMount() {
        this.searchHomeRepairAgendaItems();
    }
   
    render() {
        return (
            <>
                
                <div className="noPrint">
                    <Tabs>
                        <Tab eventKey="searchHomeRepairCases" title="Konut Dosya Arama">
                            <HomeRepairSearch searchCallback={this.searchServices} ref={this.state.ref} />

                            {
                                !this.state.serviceTalbeLoading ?

                                    <HomeRepairList
                                        searchData={this.state.searchData}
                                        serviceDetailCallback={this.handleServiceDetailCallback}
                                        serviceTalbeLoading={this.state.serviceTalbeLoading}
                                        getServiceByID={this.getServiceByID}
                                        isCaseNumberHidden={true}
                                    />

                                    :
                                    <>
                                        
                                        <div className="d-flex justify-content-center  mt-3">
                                            <div className="spinner-border mr-3" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <span>Yükleniyor...</span>
                                        </div>
                                    </>
                                    
                            }
                            
                            <NotificationContainer />
                            <Modal dialogClassName="modal-90w" show={this.state.showServiceDetailModal} onHide={() => this.handleserviceDetailModalClose()}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Konut Dosya Detayı</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <HomeRepairDetail {...this.props} service={this.state.serviceData} closeModal={this.handleserviceDetailModalClose} />
                                </Modal.Body>
                            </Modal>
                        </Tab>
                        <Tab eventKey="generalAgenda" title="Genel Ajanda">
                            <Toast ref={(el) => this.toast = el}></Toast>
                            { /*this is start of general agenda datatable.*/}
                            <div className="datatable-rowgroup-demo">
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col xs={12} sm={12} md={6}>
                                                <DateInput
                                                    timeFormat={false}
                                                    label="Başlangıç Tarihi"
                                                    placeholder="Başlangıç Tarihi"
                                                    handleChange={this.handleAgendaDate}
                                                    stateName="agendaStartDate"
                                                    
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={6}>
                                                <DateInput
                                                    timeFormat={false}
                                                    label="Bitiş Tarihi"
                                                    placeholder="Bitiş Tarihi"
                                                    handleChange={this.handleAgendaDate}
                                                    stateName="agendaEndDate"
                                                    
                                                />
                                            </Col>
                                         </Row>
                                    </Card.Body>
                                </Card>
                                <div className="card">
                                    <DataTable value={this.state.filteredAgendaItems} rowGroupMode="subheader" groupField="agendaOnlyDate"
                                        sortMode="single" sortField="agendaDate" sortOrder={-1}
                                        expandableRowGroups expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                                        onRowExpand={this.onRowGroupExpand} onRowCollapse={this.onRowGroupCollapse}
                                        rowGroupHeaderTemplate={this.headerTemplate} rowGroupFooterTemplate={this.footerTemplate}>
                                        <Column field="homeRepairCase.mainCase.caseProductOwner.name" header="Sigortalı"></Column>
                                        <Column field="homeRepairCase.mainCase.callerMobilePhoneNumber" header="Sigortalı Tel"></Column>
                                        <Column field="homeRepairCase.mainCase.caseInsurance.insuranceName" header="Sigorta Şirketi"></Column>
                                        <Column field="homeRepairCase.repairmentRegion.name" header="Onarım Bölgesi"></Column>
                                        <Column field="homeRepairCase.mainCase.sceneAdress" header="Adres"></Column>
                                        <Column field="agendaDate" header="Tarih" body={this.dateTemplate}></Column>
                                        <Column field="agendaType.name" header="Ajanda Tipi"></Column>
                                        <Column field="description" header="Açıklama" ></Column>
                                    </DataTable>
                                </div>
                            </div>
                            { /*this is end of general agenda datatable.*/}
                        </Tab>
                        
                    </Tabs>
                </div>
            </>
        );
    }
}
