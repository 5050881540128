import React, { Component } from 'react';
import axios from 'axios';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

export class PayBack extends Component {
    static displayName = PayBack.name;

    constructor(props) {
        super(props);
        this.getDeclarationForm = this.getDeclarationForm.bind(this);
    }

    async getDeclarationForm() {
        axios({
            url: "api/invoicing/getdeclarationform",
            method: 'GET',
            responseType: 'blob',
        }).then(res => {
            var blob = new Blob([res.data]);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "BeyanFormu.pdf";
            link.click();
        });
    }

    render() {
        return (
            <>
                <Card className="mt-3">
                    <Card.Header>
                        <h5>Geri Ödeme Hakkında Bilgilendirme</h5>
                    </Card.Header>
                    <Card.Body>
                        <p>
                            <b>1. &nbsp;</b> Faturanın aşağıdaki ünvana düzenlenmesi gerekmektedir.
                            <br /><br />
                            <b>Şirket Adı:</b> Inter Partner Assistance Yardım ve Destek Hizmetleri A.Ş.
                            <br />
                            <b>Adres:</b> Yamanevler Mah. Site Yolu Cad. Anel İş Merkezi No:5/13-14 Ümraniye/İstanbul
                            <br />
                            <b>Vergi No:</b> 4780991384
                            <br />
                            <b>Vergi Dairesi:</b>  Anadolu Kurumlar
                        </p>
                        <br />
                        <p><b>2. &nbsp;</b> Aşağıdaki beyan formunun tarafınızca doldurulması ve imzalanması gerekmektedir.<br /><br />
                            <Button onClick={this.getDeclarationForm} target="_blank" className="mb-1 float-left">
                                <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
                                <span>Beyan Formu</span>
                            </Button>
                        </p>
                        <br />
                        <br />
                        <p><b>3. &nbsp;</b> Fatura, Beyan Formu ve SMS ile size iletilen dosya numarasını  <span><a href="mailto:form@ipaistanbul.com">form@ipaistanbul.com</a></span>  adresine mail olarak göndermeniz gerekmektedir. E-Fatura, E-Arşiv Fatura, E-Bilet dışındaki tüm kağıt olarak düzenlenmiş belgelerin (kağıt fatura, perakende satış fişi, taksi fişi vb.) asıllarının şirketimize posta yolu ile iletilmesi gerekmektedir.</p>
                        <br />
                        <p><b>
                            Not: &nbsp;</b>
                            <br />
                            Ruhsat şahıs adına ise ilgili faturanın ruhsat sahibi adına, şirket adına ise faturanın şirketinizin adına ve tevkifatlı düzenlemesi gerekmektedir.
                            <br />
                            Evrakların tarafımıza 5 gün içerisinde eksiksiz olarak iletilmesi gerekmektedir.
                        </p>


                    </Card.Body>
                </Card>
            </>
        );
    }
}
