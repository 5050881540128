import React, { Component } from 'react';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';





export class ServicePhoto extends Component {


    constructor(props) {
        super(props);
        this.state = {
            //myProps: props.location.myProps,
            searchingIconClasses: "fa-spin d-none ml-2",
            hrCase: {}
        };
        this.getRepairmentsPrintTable = this.getRepairmentsPrintTable.bind(this);
    }
    
    renderPrintRepairments() {
        return (
            <React.Fragment>
                <img id="photo" className="img-fluid"/>

            </React.Fragment>
        );

    }
 
    async getRepairmentsPrintTable(ID, servID) {
        axios.post("api/service/getservicephoto/", {id:parseInt(ID),serviceID:parseInt(servID)})
            .then(res => {
                if (res.data.data) {
                    console.log(res);
                    //var urlCreator = window.URL || window.webkitURL;
                    var dataURI = "data:image/jpeg;base64," + res.data.data.imageData;
                    var byteString = atob(dataURI.split(',')[1]);
                    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
                    var ab = new ArrayBuffer(byteString.length);
                    var ia = new Uint8Array(ab);
                    for (var i = 0; i < byteString.length; i++)
                        ia[i] = byteString.charCodeAt(i);
                    var blob = new Blob([ab], { type: mimeString });
                   /* Resizer.imageFileResizer(
                        blob,
                        150,
                        150,
                        'JPEG',
                        100,
                        0,
                        uri => {
                            /*const newArray = this.state.photosPreview.concat(uri);
                            this.setState({ photosPreview: newArray });
                            var imageUrl = URL.createObjectURL(blob);
                            var img = document.querySelector("#photo");
                            img.src = uri;
                        }
                    );*/

                    var imageUrl = URL.createObjectURL(blob);
                    var img = document.querySelector("#photo");
                    img.src = imageUrl;
                    


                }
                else {
                    alert(res.data.message);

                }
            }).catch(e => {
                console.log(e);
            })
    }


    //Component Events
    componentDidMount() {
        this.getRepairmentsPrintTable(this.props.computedMatch.params.id, this.props.computedMatch.params.serviceID);
    }
   /* componentDidUpdate(prevProps) {
        if (prevProps.service !== this.props.location.myData.service) {
            this.setState({ serviceData: this.props.location.myData.service.data.data });
        }
        let calendarApi = this.calendarRef.current.getApi()
        calendarApi.refetchEvents()
        //calendarApi.render()
        //calendarApi.getEvents()
        calendarApi.updateSize()
    }*/

    render() {
        return (
            <React.Fragment>
                <div className="mb-5 mt-5 mr-5 ml-5">
                    {this.renderPrintRepairments()}
                </div>
            </React.Fragment>
        );
    }
}
