import React from 'react';
import { Container } from 'reactstrap';

const LoginLayout = ({ children }) => (
    <div>
        {children}
    </div>
);

const LoginLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <LoginLayout>
            <Container>
                <Component {...rest} />
            </Container>
        </LoginLayout>
    )
};

export default LoginLayoutRoute;

