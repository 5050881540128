import React from 'react';
//Bootstrap
import Container from 'react-bootstrap/Container';

import { Route } from 'react-router-dom';
import { NavMenu } from './NavMenu';

import moment from 'moment';

const Layout = ({ children }) => (
    <div>
        <NavMenu />
        <div>{children}</div>
    </div>
);


const LayoutRoute = ({ component: Component }) => {
    return (
        <>
            <Route render={matchProps => (
                <Layout>
                    <Container fluid>
                        <Component />
                    </Container>
                    <footer className="footer bg-primary pt-2 noPrint">
                        <Container fluid className="pb-0 bg-primary just" align="center">
                            <label className="text-white">Inter Partner Assistance @{moment().format('YYYY')}</label>
                        </Container>
                    </footer>
                </Layout>
            )} />
        </>
    )
};

export default LayoutRoute;  
