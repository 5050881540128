import React, { Component } from 'react';

//Bootstrap
import InputGroup from 'react-bootstrap/InputGroup';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'

//React-Datetime
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';
import "moment/locale/tr";

function RenderFontAwesomeIcon(icon) {
    if (icon.icon) {
        return (
            <InputGroup.Prepend>
                <InputGroup.Text className="rounded-0">
                    <FontAwesomeIcon icon={icon.icon} />
                </InputGroup.Text>
            </InputGroup.Prepend>
        );
    }
    else {
        return (
            <InputGroup.Prepend>
                <InputGroup.Text className="rounded-0">
                    <FontAwesomeIcon icon={faCalendarDay} />
                </InputGroup.Text>
            </InputGroup.Prepend>
        );
    }
}

function RenderFontLabel(label) {
    if (label.label) {
        return (
            <label>{label.label}</label>
        );
    }
    else
        return null;
}

export class DateInput extends Component {
    static displayName = DateInput.name;

    constructor(props) {
        super(props);
        this.state = {

        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e, stateName) {
        this.props.handleChange(e, stateName);
    }

    render() {
        return (
            <>
                <RenderFontLabel label={this.props.label} />
                <InputGroup className="mb-2">
                    <RenderFontAwesomeIcon icon={this.props.faicon} />
                    <Datetime
                        timeFormat={this.props.timeFormat}
                        dateFormat={this.props.dateFormat}
                        className="form-control p-0 border-0"
                        locale="tr"
                        inputProps={{ placeholder: this.props.placeholder, disabled: this.props.disabled }}
                        onChange={moment => this.handleChange(moment, this.props.stateName)}
                        value={this.props.value}
                    />
                </InputGroup>
            </>
        );
    }
}
